import { RecurringRuleCategory } from '@/enterprise/data/useRecurringRules';
import { dateAsIsoString } from '@/utils/dateAsIsoString';
import { RecurringRuleFormCriteriaValue } from '../RecurringRulesForm';

const defaultRuleValues: Record<RecurringRuleCategory, RecurringRuleFormCriteriaValue> = {
  amount: { type: 'gteInvoiceAmount', value: '' },
  dpe: { type: 'gteDpe', value: '' },
  dueDate: { type: 'toDueDate', value: dateAsIsoString() },
  invoiceId: { type: 'excludedVoucherIds', value: '' },
};

export default defaultRuleValues;
