import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import {
  Button,
  ButtonLink,
  Chip,
  ChipProps,
  Modal,
  ModalActions,
  ModalContent,
  ModalTitleInverse,
} from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { ShapesIcon } from '@c2fo/liquidity/icons';
import { EligibleInvoiceFetchFilters } from '@/enterprise/data/useEligibleInvoices';
import { RecurringRule } from '@/enterprise/data/useRecurringRules';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import RulePreview from '@/enterprise/features/recurringRules/components/RulePreview';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useLocaleFormat from '@/utils/useLocaleFormat';

interface RecurringRuleAppliedChipProps {
  excludedAmount?: string;
  onClick?: () => void;
  size?: ChipProps['size'];
  isTextDisplay?: boolean;
}

const navParams = qs.stringify({
  limit: 100,
  order: 'amount',
  orderDirection: 'desc',
  page: 1,
  filter: { included: [false] } satisfies EligibleInvoiceFetchFilters,
});

export const RecurringRuleAppliedChip = ({
  excludedAmount,
  onClick,
  size = 'sm',
  isTextDisplay = false,
}: RecurringRuleAppliedChipProps) => {
  const { t } = useTranslation();
  const [enableOfferScheduling] = useFeature('enterprise-ui_enableOfferScheduling');

  const label = enableOfferScheduling ? (
    <div className="flex items-center gap-1">
      {isTextDisplay ? (
        t('recurringRules.recurringRuleApplied')
      ) : (
        <ShapesIcon className="h-4 w-4" fill={colors.lightBlue[900]} title="Recurring Rules Indicator" />
      )}
      {excludedAmount && <div>{t('invoiceSettings.excludedAmount', { amount: excludedAmount })}</div>}
    </div>
  ) : (
    <div className="text-left">
      <div>{t('recurringRules.recurringRuleApplied')}</div>
      {excludedAmount && <div>{t('invoiceSettings.excludedAmount', { amount: excludedAmount })}</div>}
    </div>
  );

  return (
    <Chip
      label={label}
      size={size}
      type="info"
      variant="outlined"
      isRound={enableOfferScheduling}
      {...(onClick && { onClick })}
    />
  );
};

const RecurringRuleExclusionChip = ({ rule, takerMarket }: { rule: RecurringRule; takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const { asCurrency } = useLocaleFormat();
  const { trackEnterpriseEvent } = useReporting();
  const [openModal, setOpenModal] = useState(false);

  const {
    takerExcludedInvoiceAmount: excludedAmount,
    takerExcludedInvoiceCount: excludedCount,
    currency,
  } = takerMarket;

  const handleOpenModal = () => {
    setOpenModal(true);
    trackEnterpriseEvent('recurring-rules::exclusion-modal::opened', {
      takerId: takerMarket.takerDivisionId,
      marketUuid: takerMarket.marketUuid,
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return rule ? (
    <>
      <Modal onClose={handleCloseModal} open={openModal} size="sm">
        <ModalTitleInverse>
          <span className="flex flex-col">
            <span>{t('recurringRules.title')}</span>
            <span className="text-base font-normal text-text-secondary">{getTakerMarketName(takerMarket)}</span>
          </span>
        </ModalTitleInverse>
        <ModalContent className="space-y-4">
          {excludedCount === 0 && (
            <div>
              {t('recurringRules.divisionExcludedInvoiceDescriptionZero', {
                amount: asCurrency(excludedAmount, currency),
              })}
            </div>
          )}
          {excludedCount === 1 && (
            <div>
              {t('recurringRules.divisionExcludedInvoiceDescription', {
                count: excludedCount,
                amount: asCurrency(excludedAmount, currency),
              })}
            </div>
          )}
          {excludedCount > 1 && (
            <div>
              {t('recurringRules.divisionExcludedInvoiceDescriptionPlural', {
                count: excludedCount,
                amount: asCurrency(excludedAmount, currency),
              })}
            </div>
          )}
          <RulePreview currency={rule.currency} rule={rule} />
        </ModalContent>
        <ModalActions>
          <Button onClick={handleCloseModal} variant="secondary">
            {t('core.close')}
          </Button>
          <ButtonLink
            onClick={() => trackEnterpriseEvent('recurring-rules::exclusion-modal::redirected')}
            to={asEnterpriseLink(
              `markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible?${navParams}`
            )}
            variant="cta"
          >
            {t('core.viewExcludedInvoices')}
          </ButtonLink>
        </ModalActions>
      </Modal>
      <RecurringRuleAppliedChip
        excludedAmount={asCurrency(excludedAmount, currency)}
        onClick={handleOpenModal}
        size="xs"
      />
    </>
  ) : null;
};

export default RecurringRuleExclusionChip;
