import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn, IconButton } from '@c2fo/liquidity';
import { PencilIcon, TrashCanIcon } from '@c2fo/liquidity/icons';
import { RecurringRuleCategory, TakerMarketWithRecurringRule } from '@/enterprise/data/useRecurringRules';
import RecurringRulesForm, {
  RecurringRulesFormReferrerType,
} from '@/enterprise/features/recurringRules/RecurringRulesForm';
import { mapRecurringRuleToFormCriteria } from '@/enterprise/features/recurringRules/utils/utils';
import { useReporting } from '@/reporting';
import Divider from '@/shared/components/Divider';
import useRestrictions from '@/utils/useRestrictions';
import DeleteRuleModal from './DeleteRuleModal';
import RuleChips from './RuleChips';

const MiniRuleManager = ({
  asChild = false,
  referrer,
  takerMarketWithRule,
}: {
  asChild?: boolean;
  referrer: RecurringRulesFormReferrerType;
  takerMarketWithRule: TakerMarketWithRecurringRule;
}) => {
  const { rule, takerMarket } = takerMarketWithRule;
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const { getRestrictions } = useRestrictions();
  const { canEditRecurringRules } = getRestrictions([takerMarket]);
  const [openRuleModal, setOpenRuleModal] = useState(false);
  const [openDeleteRuleModal, setOpenDeleteRuleModal] = useState(false);

  const referrerForTrackEvent = referrer === 'rulesManager' ? 'rules-manager' : 'division-details';

  const handleOpenRuleModal = () => {
    setOpenRuleModal(true);
    trackEnterpriseEvent('recurring-rules::opened', {
      referrer: referrerForTrackEvent,
      source: 'mini-rule-manager',
    });
  };

  const handleCloseRuleModal = () => {
    setOpenRuleModal(false);
    trackEnterpriseEvent('recurring-rules::closed');
  };

  const handleDeleteAllRulesModalOpen = () => {
    setOpenDeleteRuleModal(true);
  };

  const handleDeleteAllRulesModalClose = () => {
    setOpenDeleteRuleModal(false);
  };

  return (
    <>
      <RecurringRulesForm
        isForSingleDivision
        mode="edit"
        onClose={handleCloseRuleModal}
        open={openRuleModal}
        referrer={referrer}
        takerMarkets={[takerMarket]}
        defaultValues={{
          invoiceRules: Object.entries(mapRecurringRuleToFormCriteria(rule)).map(([key, value]) => ({
            category: key as RecurringRuleCategory,
            criteria: value,
          })),
          operatorType: rule.operatorType,
          takerMarkets: [takerMarket],
        }}
      />
      <DeleteRuleModal
        onClose={handleDeleteAllRulesModalClose}
        open={openDeleteRuleModal}
        referrer={referrerForTrackEvent}
        takerMarketWithRule={takerMarketWithRule}
      />
      <div
        className={cn('flex items-center justify-between gap-10 rounded bg-canvas p-4', {
          'flex-wrap gap-2 md:flex-nowrap md:gap-10': !asChild,
        })}
      >
        <RuleChips bold recurringRule={rule} withLabel />
        <div className="flex items-center gap-1">
          <IconButton
            disabled={!canEditRecurringRules}
            icon={PencilIcon}
            name={t('recurringRules.editRule')}
            onClick={handleOpenRuleModal}
            size="sm"
            variant="ancillary"
          />
          <Divider vertical />
          <IconButton
            className="bg-transparent"
            disabled={!canEditRecurringRules}
            icon={TrashCanIcon}
            inverse
            name={t('recurringRules.removeRule')}
            onClick={handleDeleteAllRulesModalOpen}
            size="sm"
            variant="destructive"
          />
        </div>
      </div>
    </>
  );
};

export default MiniRuleManager;
