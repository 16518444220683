import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  cn,
  Modal,
  ModalActions,
  ModalContent,
  ModalScreenReaderTitle,
  ModalTitleInfo,
  ModalTitleInverse,
} from '@c2fo/liquidity';
import circleCheck from '@/enterprise/assets/circlecheck.svg?url';
import useCreateRecurringRule, {
  CreateRecurringRuleArgument,
  InvoiceRulesOperatorType,
} from '@/enterprise/data/useCreateRecurringRule';
import { RecurringRuleCategory, RecurringRuleCriteria } from '@/enterprise/data/useRecurringRules';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { TakerMarketGroupType } from '@/enterprise/data/useTakerMarketsGroups';
import useUpdateRecurringRule, { UpdateRecurringRuleArgument } from '@/enterprise/data/useUpdateRecurringRule';
import {
  mapInvoiceRulesToRecurringRule,
  mapRuleRecordToCriteria,
} from '@/enterprise/features/recurringRules//utils/utils';
import Divider from '@/shared/components/Divider';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import useExchangeRates from '@/shared/data/useExchangeRates';
import getTakerMarketName from '@/utils/getTakerMarketName';
import localeCurrencyAmountToDomain from '@/utils/localeCurrencyAmountToDomain';
import useSelectedCurrency from '@/utils/useSelectedCurrency';
import CategorySelect from './components/CategorySelect';
import CriteriaSelect from './components/CriteriaSelect';
import DivisionSelectTable from './components/DivisionSelectTable';
import ReviewRule from './components/ReviewRule';
import { getInitialState, recurringRulesFormReducer, Steps } from './utils/recurringRulesFormReducer';
import useGetRecurringRulesForTakerMarkets from './utils/useGetRecurringRulesForTakerMarkets';
import useValidateRuleForm, { RecurringRuleFormError } from './utils/useValidateRuleForm';

export type RecurringRulesFormReferrerType = 'divisionDetails' | 'homepage' | 'rulesManager';

export interface RecurringRuleFormCriteriaValue {
  type: RecurringRuleCriteria;
  value: string;
}

export interface RecurringRuleFormValues {
  invoiceRules: {
    category: RecurringRuleCategory;
    criteria: RecurringRuleFormCriteriaValue[];
  }[];
  operatorType: InvoiceRulesOperatorType;
  takerMarkets: TakerMarket[];
}

export interface RecurringRulesFormProps {
  /* callback to close the modal */
  onClose: () => void;
  /* whether or not the modal is open */
  open: boolean;
  /* where the recurring rule form modal is opened from */
  referrer: RecurringRulesFormReferrerType;
  /* a list of taker markets to render within the division select table */
  takerMarkets: TakerMarket[];
  /* default values to pre-populate the form with */
  defaultValues?: Partial<RecurringRuleFormValues>;
  /*
   * whether or not the form should adapt config for a single division
   * will hide the division select table if for a single division
   * defaults to false
   */
  isForSingleDivision?: boolean;
  /*
   * the mode of the form
   * create will display as creating a rule
   * edit will display as editing a rule
   * defaults to create
   */
  mode?: 'create' | 'edit';
  /* the type of taker market group */
  type?: TakerMarketGroupType;
  /* Set while api is calling to prevent closing modal while loading */
  setDisableModalClose: (value: boolean) => void;
}

const RecurringRulesFormComponent = ({
  defaultValues,
  isForSingleDivision = false,
  mode = 'create',
  onClose,
  referrer,
  takerMarkets,
  type,
  setDisableModalClose,
}: RecurringRulesFormProps) => {
  const { t } = useTranslation();
  const selectedCurrency = useSelectedCurrency();
  const { data: exchangeRates = {} } = useExchangeRates();
  const [errors, setErrors] = useState<RecurringRuleFormError[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitErrors, setSubmitErrors] = useState<{
    showError: boolean;
    takerMarkets: { takerId: number; marketId: number }[];
  }>({ showError: false, takerMarkets: [] });
  const { mutateAsync: createRecurringRule } = useCreateRecurringRule();
  const { mutateAsync: updateRecurringRule } = useUpdateRecurringRule();
  const initialState = getInitialState({ isForSingleDivision, mode });
  const [state, dispatch] = useReducer(recurringRulesFormReducer, initialState);
  const defaultFormValues: RecurringRuleFormValues = {
    invoiceRules: [],
    operatorType: 'OR', // default to OR
    takerMarkets: [],
  };
  const methods = useForm<RecurringRuleFormValues>({
    defaultValues: {
      invoiceRules: defaultValues?.invoiceRules ?? defaultFormValues.invoiceRules,
      operatorType: defaultValues?.operatorType ?? defaultFormValues.operatorType,
      takerMarkets: defaultValues?.takerMarkets ?? defaultFormValues.takerMarkets,
    },
  });
  const selectedTakerMarkets = methods.watch('takerMarkets');
  const { validateRuleFormCategories, validateRuleFormCriteria, validateRuleFormTakerMarkets } = useValidateRuleForm();
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();
  const toCurrency = isForSingleDivision ? selectedTakerMarkets?.[0]?.currency ?? selectedCurrency : selectedCurrency;

  const onTakerMarketTableSelect = (takerMarkets: TakerMarket[]) => {
    methods.setValue('takerMarkets', takerMarkets);
    setErrors(errors.filter((error) => error.type !== 'takerMarkets'));
  };

  const handleClose = () => {
    setSubmitErrors({ showError: false, takerMarkets: [] });
    onClose();
  };

  const handleCreateAnother = () => {
    methods.reset(defaultFormValues);
    dispatch({ type: 'createAnother' });
  };

  const onSubmit: SubmitHandler<RecurringRuleFormValues> = async (data) => {
    setLoading(true);
    setDisableModalClose(true);

    const ruleRecord = mapInvoiceRulesToRecurringRule(data.invoiceRules);
    const criteria = mapRuleRecordToCriteria({ ruleRecord, fromCurrency: toCurrency });

    // for selected taker markets, determine if we should send as create or update
    // based on if there is an existing rule for the taker market
    const invoiceRuleArguments = data.takerMarkets.reduce(
      (args, tm) => {
        const [existingRule] = getRecurringRulesForTakerMarkets([tm]);

        if (existingRule) {
          const updateRuleParams = {
            makerOrganizationUuid: existingRule.makerOrganizationUuid,
            uuid: existingRule.id,
          };
          const updateTakerMarketParams = {
            makerOrganizationUuid: existingRule.makerOrganizationUuid,
            marketId: existingRule.marketId,
            marketUuid: existingRule.marketUuid,
            takerId: existingRule.takerId,
            takerUuid: existingRule.takerUuid,
          };

          if (args.update) {
            args.update.rules.push(updateRuleParams);
            args.update.takersMarkets.push(updateTakerMarketParams);
          } else {
            args.update = {
              criteria,
              operatorType: data.operatorType,
              rules: [updateRuleParams],
              takersMarkets: [updateTakerMarketParams],
            };
          }
        } else {
          const createTakerMarketParams = {
            makerOrganizationUuid: tm.makerOrganizationUuid,
            marketId: tm.legacyMarketId,
            marketUuid: tm.marketUuid,
            takerId: tm.takerDivisionId,
            takerUuid: tm.takerDivisionUuid,
          };

          if (args.create) {
            args.create.takersMarkets.push(createTakerMarketParams);
          } else {
            args.create = {
              criteria,
              operatorType: data.operatorType,
              takersMarkets: [createTakerMarketParams],
            };
          }
        }

        return args;
      },
      {
        create: undefined,
        update: undefined,
      } as { create?: CreateRecurringRuleArgument; update?: UpdateRecurringRuleArgument }
    );

    try {
      await Promise.all([
        ...(invoiceRuleArguments.create ? [createRecurringRule(invoiceRuleArguments.create)] : []),
        ...(invoiceRuleArguments.update ? [updateRecurringRule(invoiceRuleArguments.update)] : []),
      ]);

      setLoading(false);
      setDisableModalClose(false);
      setSubmitErrors({ showError: false, takerMarkets: [] });

      dispatch({ type: 'setDisplay', payload: { display: 'success' } });
    } catch {
      setLoading(false);
      setDisableModalClose(false);
      setSubmitErrors({ showError: true, takerMarkets: [] });
    }
  };

  const handleStepTo = (step: Steps) => {
    dispatch({ type: 'stepTo', payload: { step } });
    setSubmitErrors({ showError: false, takerMarkets: [] });
  };

  const handleStepBack = () => {
    dispatch({ type: 'stepBack' });
    setErrors([]);
    setSubmitErrors({ showError: false, takerMarkets: [] });
  };

  const handleStepForward = () => {
    // validate category select
    if (state.currentStep === 'category') {
      const [invoiceRules] = methods.getValues(['invoiceRules']);

      const categories = invoiceRules.flatMap(({ category }) => category);

      const errors = validateRuleFormCategories(categories);

      if (errors.length > 0) {
        return setErrors(errors);
      }

      dispatch({ type: 'stepForward' });
    }

    // validate criteria select
    if (state.currentStep === 'criteria') {
      const [invoiceRules] = methods.getValues(['invoiceRules']);

      invoiceRules.forEach((rule, ruleIndex) => {
        if (rule.category === 'amount') {
          rule.criteria.forEach((c, cIndex) => {
            if (c.type === 'gteInvoiceAmount' || c.type === 'lteInvoiceAmount') {
              methods.setValue(
                `invoiceRules.${ruleIndex}.criteria.${cIndex}.value`,
                localeCurrencyAmountToDomain(c.value) ?? c.value
              );
            }
          });
        }
      });

      const criteria = invoiceRules.flatMap(({ criteria }) => criteria);
      const errors = validateRuleFormCriteria(criteria);

      if (errors.length > 0) {
        return setErrors(errors);
      }

      // if the form is valid, advance to the next step
      dispatch({ type: 'stepForward' });
    }

    // validate division select
    if (state.currentStep === 'division') {
      const [takerMarkets] = methods.getValues(['takerMarkets']);

      const errors = validateRuleFormTakerMarkets(takerMarkets);

      if (errors.length > 0) {
        return setErrors(errors);
      }

      // if the form is valid, advance to the next step
      dispatch({ type: 'stepForward' });
    }
  };

  // set width of modal content based on current step and display
  const modalContentClassName = cn(
    'w-full min-w-full sm:w-[568px]',
    { 'w-full sm:w-full lg:w-[704px]': state.currentStep === 'summary' },
    { 'w-full sm:w-full lg:w-[880px]': state.currentStep === 'criteria' || state.currentStep === 'division' },
    {
      'w-full sm:w-[592px] lg:w-[592px]': state.currentStep === 'category' || state.display === 'success',
    }
  );

  return (
    <>
      {state.display === 'success' ? (
        <ModalTitleInfo>{t(`recurringRules.ruleApplied`)}</ModalTitleInfo>
      ) : (
        <>
          <ModalTitleInverse>
            <span className="flex flex-col">
              <span>
                {state.isEditMode ? t('recurringRules.editRecurringRule') : t('recurringRules.createRecurringRule')}
              </span>
              {isForSingleDivision && (
                <span className="text-sm font-normal text-text-secondary">{getTakerMarketName(takerMarkets[0])}</span>
              )}
            </span>
          </ModalTitleInverse>
          <div className="px-6">
            <Divider />
          </div>
        </>
      )}
      <ModalContent key={state.currentStep} className={modalContentClassName}>
        {state.display === 'success' ? (
          <div className="flex h-full w-full flex-col items-center justify-center space-y-6 pt-4 text-center">
            <img src={circleCheck} alt="circle check" className="h-16 w-16" />
            <div>{t('recurringRules.success')}</div>
          </div>
        ) : (
          <FormProvider {...methods}>
            <form>
              {/* step 1 - category select */}
              {state.currentStep === 'category' && <CategorySelect errors={errors} setErrors={setErrors} />}
              {/* step 2 - criteria select */}
              {state.currentStep === 'criteria' && (
                <CriteriaSelect errors={errors} setErrors={setErrors} toCurrency={toCurrency} />
              )}
              {/* step 2 - division select */}
              {state.currentStep === 'division' && (
                <DivisionSelectTable
                  handleStepTo={handleStepTo}
                  hasError={errors.find((error) => error.type === 'takerMarkets')}
                  onSelect={onTakerMarketTableSelect}
                  selectedTakerMarkets={selectedTakerMarkets ?? []}
                  takerMarkets={takerMarkets}
                  toCurrency={toCurrency}
                  type={type}
                />
              )}
              {/* step 4 - summary */}
              {state.currentStep === 'summary' && (
                <ReviewRule
                  handleStepTo={handleStepTo}
                  isForSingleDivision={isForSingleDivision}
                  errorTakerMarkets={submitErrors.takerMarkets}
                  exchangeRates={exchangeRates}
                  showError={submitErrors.showError}
                  toCurrency={toCurrency}
                />
              )}
            </form>
          </FormProvider>
        )}
      </ModalContent>
      <ModalActions {...(state.display === 'success' && { className: 'bg-secondary-500' })}>
        {state.display === 'success' ? (
          <div className="flex w-full flex-col items-center text-center text-white">
            {/* do not show create another if rule is created from division details or if in edit mode */}
            {referrer !== 'divisionDetails' && mode !== 'edit' && (
              <>
                <div className="mb-6">{t(`recurringRules.createAdditional.${referrer}`)}</div>
                <Button className="mb-4 w-full max-w-96" inverse onClick={handleCreateAnother} variant="cta">
                  {t('recurringRules.createAnotherRule')}
                </Button>
              </>
            )}
            <Button
              className="w-full max-w-96"
              inverse
              onClick={handleClose}
              variant={referrer === 'divisionDetails' || mode === 'edit' ? 'secondary' : 'ancillary'}
            >
              {t(`recurringRules.returnTo.${referrer}`)}
            </Button>
          </div>
        ) : (
          <>
            {state.availableSteps.indexOf(state.currentStep) !== 0 && (
              <Button onClick={handleStepBack} variant="secondary">
                {t('core.back')}
              </Button>
            )}
            {['category', 'criteria', 'division'].includes(state.currentStep) && (
              <Button onClick={handleStepForward}>{t('core.next')}</Button>
            )}
            {state.currentStep === 'summary' && (
              <Button onClick={methods.handleSubmit(onSubmit)} loading={loading} variant="cta">
                {t('recurringRules.applyRule')}
              </Button>
            )}
          </>
        )}
      </ModalActions>
    </>
  );
};

const RecurringRulesModalLoading = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* This is to prevent Modal title accessibility warnings while the content is suspended */}
      <ModalScreenReaderTitle>{t('spinner.loading')}</ModalScreenReaderTitle>
    </>
  );
};

const RecurringRulesForm = (props: Omit<RecurringRulesFormProps, 'setDisableModalClose'>) => {
  const [disableModalClose, setDisableModalClose] = useState(false);

  return (
    <Modal
      className="min-w-[unset]"
      onClose={props.onClose}
      open={props.open}
      disableOutsideClickClose={disableModalClose}
    >
      <QueryBoundaries LoadingComponent={RecurringRulesModalLoading}>
        <RecurringRulesFormComponent {...props} setDisableModalClose={setDisableModalClose} />
      </QueryBoundaries>
    </Modal>
  );
};

export default RecurringRulesForm;
