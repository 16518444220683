import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RecurringRuleCriteria } from '@/enterprise/data/useRecurringRules';
import { RecurringRuleFormValues } from '../RecurringRulesForm';

export interface RecurringRuleFormError {
  type: RecurringRuleCriteria | 'takerMarkets' | 'categories';
  message: string;
}

const useValidateRuleForm = () => {
  const { t } = useTranslation();

  const ruleErrorMap = useMemo(
    () =>
      ({
        gteInvoiceAmount: {
          empty: t('invoiceSettings.rules.amountErrorEmpty'),
          invalid: t('invoiceSettings.rules.amountErrorInvalid'),
        },
        lteInvoiceAmount: {
          empty: t('invoiceSettings.rules.amountErrorEmpty'),
          invalid: t('invoiceSettings.rules.amountErrorInvalid'),
        },
        gteDpe: {
          empty: t('invoiceSettings.rules.dpeErrorEmpty'),
          invalid: t('invoiceSettings.rules.dpeErrorInvalid'),
        },
        lteDpe: {
          empty: t('invoiceSettings.rules.dpeErrorEmpty'),
          invalid: t('invoiceSettings.rules.dpeErrorInvalid'),
        },
        toDueDate: {
          empty: t('invoiceSettings.rules.dueDateErrorEmpty'),
          invalid: t('invoiceSettings.rules.dueDateErrorInvalid'),
        },
        fromDueDate: {
          empty: t('invoiceSettings.rules.dueDateErrorEmpty'),
          invalid: t('invoiceSettings.rules.dueDateErrorInvalid'),
        },
        excludedVoucherIds: {
          empty: t('invoiceSettings.rules.invoiceIdErrorEmpty'),
          invalid: '',
        },
        takerMarkets: {
          empty: t('invoiceSettings.rules.takerMarketsEmpty'),
          invalid: t('invoiceSettings.rules.takerMarketsEmpty'),
        },
        categories: {
          empty: t('invoiceSettings.rules.divisionsEmpty'),
          invalid: '',
        },
      }) satisfies { [ruleType in RecurringRuleFormError['type']]: { [errorType in 'empty' | 'invalid']: string } },
    [t]
  );

  const validateRuleFormCategories = (categories: RecurringRuleFormValues['invoiceRules'][number]['category'][]) => {
    const errors: RecurringRuleFormError[] = [];

    if (categories.length === 0) {
      errors.push({ type: 'categories', message: ruleErrorMap.categories.empty });
    }

    return errors;
  };

  const validateRuleFormCriteria = (criteria: RecurringRuleFormValues['invoiceRules'][number]['criteria']) => {
    const errors: RecurringRuleFormError[] = [];

    criteria.forEach(({ type, value }) => {
      if (!value) {
        errors.push({ type, message: ruleErrorMap[type].empty });
      }

      if (['lteDpe', 'gteDpe', 'lteInvoiceAmount', 'gteInvoiceAmount'].includes(type)) {
        if (isNaN(Number(value)) || Number(value) <= 0) {
          errors.push({ type, message: ruleErrorMap[type].invalid });
        }
      }

      if (type === 'excludedVoucherIds' && value.length > 15) {
        errors.push({ type, message: ruleErrorMap[type].invalid });
      }
    });

    return errors;
  };

  const validateRuleFormTakerMarkets = (takerMarkets: RecurringRuleFormValues['takerMarkets']) => {
    const errors: RecurringRuleFormError[] = [];

    if (takerMarkets.length === 0) {
      errors.push({ type: 'takerMarkets', message: ruleErrorMap.takerMarkets.empty });
    }

    return errors;
  };

  return { validateRuleFormCategories, validateRuleFormCriteria, validateRuleFormTakerMarkets };
};

export default useValidateRuleForm;
