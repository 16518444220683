import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import App from '@/accountSettings/App';
import AccountSettings from '@/accountSettings/pages/AccountSettings';
import NotificationSettings from '@/accountSettings/pages/NotificationSettings';
import RightToBeForgotten from '@/accountSettings/pages/RightToBeForgotten';
import FullPageLoader from '@/shared/components/FullPageLoader';
import NavigateWithRedirect from '@/shared/components/NavigateWithRedirect';

export const accountSettingsBasename = 'account';

const accountSettingsRouteConfig: RouteObject = {
  path: accountSettingsBasename,
  element: (
    <Suspense fallback={<FullPageLoader />}>
      <App />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: <NavigateWithRedirect to="settings" />,
    },
    {
      path: 'settings',
      element: <AccountSettings />,
    },
    {
      path: 'notifications',
      element: <NotificationSettings />,
    },
    {
      path: 'right-to-be-forgotten',
      element: <RightToBeForgotten />,
    },
    { path: '*', element: <NavigateWithRedirect to="settings" /> },
  ],
};

export default accountSettingsRouteConfig;
