import { useMutation } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import useRefetchAndSubscribeForRules from '@/enterprise/features/recurringRules/utils/useRefetchAndSubscribeForRules';
import { mapRuleArgumentToRuleArray } from '@/enterprise/features/recurringRules/utils/utils';
import apiClient from '@/lib/apiClient';
import { useReporting } from '@/reporting';

export type InvoiceRulesPostBody = paths['/invoice-rules']['post']['requestBody']['content']['application/json'];
export type InvoiceRulesCriteriaType = InvoiceRulesPostBody['criteria'];
export type InvoiceRulesOperatorType = InvoiceRulesPostBody['operatorType'];

type InvoiceRulesPostResponse = paths['/invoice-rules']['post']['responses']['201']['content']['application/json'];

export interface CreateRecurringRuleArgument {
  criteria: InvoiceRulesCriteriaType;
  operatorType: InvoiceRulesOperatorType;
  takersMarkets: {
    marketId: number;
    marketUuid: string;
    takerId: number;
    takerUuid: string;
    makerOrganizationUuid: string;
  }[];
}

const useCreateRecurringRule = () => {
  const { trackEnterpriseEvent } = useReporting();
  const { refetchAndSubscribeRules } = useRefetchAndSubscribeForRules();

  return useMutation({
    mutationKey: ['createRecurringRule'],
    mutationFn: (rule: CreateRecurringRuleArgument) => {
      const { takersMarkets, ...rest } = rule;

      return apiClient
        .post(`api/supplier-experience/invoice-rules`, {
          json: {
            ...rest,
            groupingKey: 'SUPPLIER_MARKET',
            supplierMarkets: takersMarkets.map((takerMarket) => ({
              marketUuid: takerMarket.marketUuid,
              supplierDivisionUuid: takerMarket.takerUuid,
            })),
          } satisfies InvoiceRulesPostBody,
        })
        .json<InvoiceRulesPostResponse>();
    },
    onSuccess: (_data, variables) => {
      const { takersMarkets } = variables;

      const takerMarketsWithoutRulesByMakerOrganizationUuid = takersMarkets.reduce(
        (acc, takerMarket) => {
          if (!acc[takerMarket.makerOrganizationUuid]) {
            acc[takerMarket.makerOrganizationUuid] = [];
          }

          acc[takerMarket.makerOrganizationUuid].push(takerMarket);
          return acc;
        },
        {} as Record<string, typeof takersMarkets>
      );

      Object.entries(takerMarketsWithoutRulesByMakerOrganizationUuid).forEach(
        ([makerOrganizationUuid, takerMarkets]) => {
          trackEnterpriseEvent('recurring-rules::created', {
            makerOrganizationUuid,
            operatorType: variables.operatorType,
            rules: mapRuleArgumentToRuleArray(variables),
            takerMarkets: takerMarkets.map((takerMarket) => ({
              marketId: takerMarket.marketId,
              marketUuid: takerMarket.marketUuid,
              takerId: takerMarket.takerId,
              takerUuid: takerMarket.takerUuid,
            })),
          });
        }
      );

      refetchAndSubscribeRules({ takerMarkets: takersMarkets });
    },
  });
};

export default useCreateRecurringRule;
