import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInfo } from '@c2fo/liquidity';
import { useReporting } from '@/reporting';
import timeOutWarning from '@/shared/assets/timeoutwarning.svg?url';
import useImpersonationMonitor from '../../utils/useImpersonationMonitor';

const ImpersonationMonitor = () => {
  const loginUrl = import.meta.env.VITE_LOGIN_URL;
  const { trackMultiExperienceEvent } = useReporting();
  const differentUser = useImpersonationMonitor();
  const { t } = useTranslation();

  useEffect(() => {
    if (differentUser) {
      trackMultiExperienceEvent('impersonation-monitor::update-required::shown');
    }
  }, [differentUser, trackMultiExperienceEvent]);

  const handleRefresh = () => {
    trackMultiExperienceEvent('impersonation-monitor::update-required::clicked');
    // Navigate to login page to refresh the UI with the new token content
    // and to ensure the user is directed to the correct experience (NSE or Enterprise)
    window.location.replace(loginUrl);
  };

  return (
    <Modal open={differentUser} disableOutsideClickClose={true} size="sm">
      <ModalTitleInfo data-testid="impersonation-monitor-modal-header">
        {t('impersonationMonitor.modal.title')}
      </ModalTitleInfo>
      <ModalContent center>
        <img src={timeOutWarning} alt="Time out warning" className="mx-auto" />
        <p className="text-center">{t('impersonationMonitor.modal.description')}</p>
      </ModalContent>

      <ModalActions className="sm:justify-center">
        <Button variant="cta" onClick={handleRefresh} className="sm:min-w-96">
          {t('impersonationMonitor.modal.button')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default ImpersonationMonitor;
