import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { Paper, ButtonLink } from '@c2fo/liquidity';
import diversereviewingoffers from '@/enterprise/assets/diversereviewingoffers.svg?url';
import { useReporting } from '@/reporting';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';

const NoAvailableMarkets = () => {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();

  useEffectOnce(() => {
    trackEnterpriseEvent('no-available-markets::message::shown');
  });

  const onClick = () => {
    trackEnterpriseEvent('no-available-markets::add-your-customers-button::clicked');
  };

  return (
    <Paper elevation={2}>
      <div className="m-auto flex max-w-[90rem] flex-col items-center gap-10 p-8 lg:flex-row lg:gap-20 lg:p-12">
        <div className="flex">
          <img src={diversereviewingoffers} alt="diverse reviewing offers" className="max-w-64 lg:max-w-80" />
        </div>
        <div className="flex flex-col gap-4">
          <h3 className="m-0 font-serif text-2xl font-medium text-text-primary sm:text-3xl">
            {t('takerMarketTabs.noMarkets.welcome')}
          </h3>
          <p className="m-0 text-xl text-text-primary">{t('takerMarketTabs.noMarkets.noMarkets')}</p>
          <p className="m-0 text-text-primary">{t('takerMarketTabs.noMarkets.addCustomersMessage')}</p>
          <ButtonLink
            className="w-full sm:w-fit"
            onClick={onClick}
            to={asEnterpriseLink('markets/search')}
            variant="primary"
          >
            {t('takerMarketTabs.noMarkets.addCustomersButton')}
          </ButtonLink>
        </div>
      </div>
    </Paper>
  );
};

export default NoAvailableMarkets;
