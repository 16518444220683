import { ButtonHTMLAttributes, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { AngleLeftIcon, AngleRightIcon, CaretDownFilledIcon } from '@c2fo/liquidity/icons';

const dots = '...';

export const getStartEnd = ({ limit, page, totalCount }: { limit: number; page: number; totalCount: number }) => {
  const start = totalCount === 0 ? 0 : (page - 1) * limit + 1;
  let end = totalCount;

  if (limit < totalCount) {
    end = limit * page;
    if (end > totalCount) {
      end = totalCount;
    }
  }

  return { start, end };
};

const PaginationButton = ({
  activeButton,
  children,
  className,
  endButton,
  inactiveButton,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & {
  activeButton?: boolean;
  endButton?: boolean;
  inactiveButton?: boolean;
}) => {
  return (
    <button
      className={cn(
        'rounded bg-transparent px-2 py-1 text-secondary-500 transition-colors duration-200 hover:bg-secondary-100',
        { 'bg-secondary-500 text-white hover:bg-secondary-500': activeButton },
        { 'p-1 disabled:opacity-38 disabled:hover:bg-transparent': endButton },
        { 'bg-transparent text-secondary-500 hover:bg-transparent': inactiveButton },
        className
      )}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
};

const Pagination = ({
  limit,
  onPageChange,
  onPageSizeChange,
  page,
  totalCount,
}: {
  limit: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (limit: number) => void;
  page: number;
  totalCount: number;
}) => {
  const [visiblePages, setVisiblePages] = useState<(string | number)[]>([]);

  const totalPages = Math.ceil(totalCount / limit);
  const { start, end } = getStartEnd({ limit, page, totalCount });
  const paginationText = `${start}-${end} of ${totalCount}`;
  const range = (start: number, end: number) => Array.from({ length: end - start + 1 }, (_, i) => start + i);
  const { t } = useTranslation();

  const updateVisiblePages = useCallback(() => {
    const newVisiblePages = [];

    if (totalPages <= 5) {
      newVisiblePages.push(...range(1, totalPages));
    } else {
      if (page <= 3) {
        newVisiblePages.push(...range(1, 3));
        newVisiblePages.push(dots);
        newVisiblePages.push(totalPages);
      } else if (page >= totalPages - 2) {
        newVisiblePages.push(1);
        newVisiblePages.push(dots);
        newVisiblePages.push(...range(totalPages - 2, totalPages));
      } else {
        newVisiblePages.push(1);
        newVisiblePages.push(dots);
        newVisiblePages.push(...range(page, page + 2));
        newVisiblePages.push(dots);
        newVisiblePages.push(totalPages);
      }
    }

    setVisiblePages(newVisiblePages);
  }, [page, totalPages]);

  useEffect(() => {
    updateVisiblePages();
  }, [page, totalPages, updateVisiblePages]);

  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  return (
    <div className="flex items-center">
      <div>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <span className="flex gap-1 rounded p-2 text-sm text-secondary-500 transition-colors duration-200 hover:bg-secondary-100">
              <span>{t('recurringRules.divisionsPerPage')}</span>
              <span className="flex items-center gap-1 font-bold">
                {limit}
                <span className="-mt-0.5">
                  <CaretDownFilledIcon fill={colors.secondary[500]} />
                </span>
              </span>
            </span>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => onPageSizeChange(5)}>5</DropdownMenuItem>
            <DropdownMenuItem onClick={() => onPageSizeChange(10)}>10</DropdownMenuItem>
            <DropdownMenuItem onClick={() => onPageSizeChange(20)}>20</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="mx-4 text-center text-xs text-text-secondary sm:w-44">{paginationText}</div>
      <div className="flex gap-1">
        <PaginationButton
          aria-label="Previous Page"
          disabled={page === 1}
          endButton
          onClick={() => handlePageChange(page - 1)}
        >
          <AngleLeftIcon />
        </PaginationButton>
        {visiblePages.map((visiblePage, i) => (
          <PaginationButton
            key={i}
            aria-label={`Page ${visiblePage}`}
            activeButton={page === visiblePage}
            disabled={page === visiblePage || visiblePage === dots}
            inactiveButton={visiblePage === dots}
            onClick={typeof visiblePage === 'number' ? () => handlePageChange(visiblePage) : undefined}
          >
            {visiblePage}
          </PaginationButton>
        ))}
        <PaginationButton
          aria-label="Next Page"
          disabled={page === totalPages || totalCount === 0}
          endButton
          onClick={() => handlePageChange(page + 1)}
        >
          <AngleRightIcon />
        </PaginationButton>
      </div>
    </div>
  );
};

export default Pagination;
