import { useTranslation } from 'react-i18next';
import SrmCard from '@/shared/components/SrmCard';
import { useAgreementsUrl } from '@/utils/useAgreementsUrl';

const Footer = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  const { privacyLink, termsOfUseLink } = useAgreementsUrl();

  return (
    <>
      <SrmCard />
      <div className="container mx-auto mt-5 flex h-16 items-center">
        <div className="w-full space-x-2 text-center">
          <span className="inline">{t('core.legalCopyright', { year })}</span>
          <a className="inline-block" href={privacyLink}>
            {t('footer.privacyNoticeLinkText')}
          </a>
          <a className="inline-block" href={termsOfUseLink}>
            {t('footer.termsOfUseLinkText')}
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
