import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, IconButton } from '@c2fo/liquidity';
import { PencilIcon } from '@c2fo/liquidity/icons';
import { Steps } from '@/enterprise/features/recurringRules/utils/recurringRulesFormReducer';
import useGetRecurringRulesForTakerMarkets from '@/enterprise/features/recurringRules/utils/useGetRecurringRulesForTakerMarkets';
import useRecurringRuleFormContext from '@/enterprise/features/recurringRules/utils/useRecurringRuleFormContext';
import { mapInvoiceRulesToRecurringRule } from '@/enterprise/features/recurringRules/utils/utils';
import { useReporting } from '@/reporting';
import Divider from '@/shared/components/Divider';
import { ExchangeRates } from '@/shared/data/useExchangeRates';
import convertCurrency from '@/utils/convertCurrency';
import useLocaleFormat from '@/utils/useLocaleFormat';
import RulePreview from './RulePreview';

interface RuleReviewProps {
  errorTakerMarkets: { takerId: number; marketId: number }[];
  exchangeRates: ExchangeRates;
  handleStepTo: (step: Steps) => void;
  isForSingleDivision: boolean;
  toCurrency: string;
  showError: boolean;
}

const ReviewRule = ({
  errorTakerMarkets,
  exchangeRates,
  handleStepTo,
  isForSingleDivision,
  toCurrency,
  showError,
}: RuleReviewProps) => {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const { asCurrency, asNumber } = useLocaleFormat();
  const [contactSRM, setContactSRM] = useState(false);
  const { getValues } = useRecurringRuleFormContext();
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();
  const [invoiceRules, operatorType, takerMarkets] = getValues(['invoiceRules', 'operatorType', 'takerMarkets']);
  const categories = invoiceRules.map(({ category }) => category);
  const existingRules = getRecurringRulesForTakerMarkets(takerMarkets);

  const selectedDivisionCount = takerMarkets.length;
  const selectedDivisionText =
    selectedDivisionCount === 1
      ? t('core.divisionSelected', { selectedDivisionCount: asNumber({ value: selectedDivisionCount }) })
      : t('core.divisionsSelected', { selectedDivisionCount: asNumber({ value: selectedDivisionCount }) });

  const uniqueSelectedCurrencies = [...new Set(takerMarkets.map(({ currency }) => currency))].filter(
    (currency) => currency !== toCurrency
  );

  return (
    <>
      {showError && (
        <div className="mb-6">
          {contactSRM ? (
            <Alert type="info" title={t('core.contactSrm.title')} description={t('core.contactSrm.description')} full />
          ) : (
            <Alert
              type="error"
              title={
                selectedDivisionCount === 1
                  ? t('recurringRules.error.createRule')
                  : t('recurringRules.error.createRuleForDivisions')
              }
              description={t('recurringRules.error.createRuleTryAgain')}
              action={{
                text: t('core.contactSrm'),
                onClick: () => {
                  // fire track event for slack channel
                  trackEnterpriseEvent('recurring-rules::error', {
                    type: 'create',
                    rule: invoiceRules,
                    errorTakerMarkets,
                  });
                  setContactSRM(true);
                },
              }}
              full
            />
          )}
        </div>
      )}
      <div>
        <div className="mb-6 text-sm">{t('recurringRules.reviewYourRuleSummary')}</div>
        <div className="mb-6 rounded-md bg-canvas">
          <RulePreview
            currency={toCurrency}
            onEdit={() => handleStepTo('criteria')}
            rule={{ ...mapInvoiceRulesToRecurringRule(invoiceRules), operatorType }}
          />
          {!isForSingleDivision && (
            <>
              <Divider />
              <div className="flex items-center justify-between gap-6 p-4">
                <div>
                  <div className="text-sm font-bold">{selectedDivisionText}</div>
                  {existingRules.length > 0 && (
                    <div className="text-xs italic text-text-secondary">
                      {t('recurringRules.existingRecurringRulesOnSelectedDivisionsWillBeOverridden')}
                    </div>
                  )}
                </div>
                <div>
                  <IconButton
                    icon={PencilIcon}
                    name="Edit Rule Divisions"
                    onClick={() => handleStepTo('division')}
                    size="sm"
                    variant="ancillary"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {uniqueSelectedCurrencies.length > 0 && categories.includes('amount') && (
        <div className="mb-6">
          <Alert
            type="default"
            title={t('recurringRules.reviewRule.currencyConversionTitle')}
            description={
              <>
                <div>
                  {uniqueSelectedCurrencies.length === 1
                    ? t('recurringRules.reviewRule.currencyConversionDescriptionSingle', { currency: toCurrency })
                    : t('recurringRules.reviewRule.currencyConversionDescriptionMultiple', { currency: toCurrency })}
                </div>
                <ul className="ml-4 mt-4 list-disc space-y-1">
                  {uniqueSelectedCurrencies.map((currency) => (
                    <li key={currency} className="space-x-1">
                      <span className="font-bold">
                        {currency} to {toCurrency}
                      </span>
                      <span>
                        {`(${asCurrency(
                          convertCurrency({
                            amount: 1,
                            exchangeRates,
                            from: toCurrency,
                            to: currency,
                          }),
                          currency
                        )} to ${asCurrency(
                          convertCurrency({
                            amount: 1,
                            exchangeRates,
                            from: currency,
                            to: currency,
                          }),
                          toCurrency
                        )})`}
                      </span>
                    </li>
                  ))}
                </ul>
              </>
            }
          />
        </div>
      )}
      <div>
        <ul className="ml-6 list-disc">
          <li>{t('recurringRules.reviewRule.listItem1')}</li>
          <li>{t('recurringRules.reviewRule.listItem2')}</li>
          <li>{t('recurringRules.reviewRule.listItem3')}</li>
          <li>{t('recurringRules.reviewRule.listItem4')}</li>
          <li>{t('recurringRules.reviewRule.listItem5')}</li>
        </ul>
      </div>
    </>
  );
};

export default ReviewRule;
