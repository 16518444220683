import { ReactNode } from 'react';

export interface OfferConfigDetailsItem {
  label: string;
  value: ReactNode;
}

export const OfferConfigDetails = ({ items }: { items: OfferConfigDetailsItem[] }) => {
  if (!items.length) {
    return null;
  }

  return (
    <div className="w-full divide-y divide-black divide-opacity-30 rounded-md bg-canvas">
      {items.map((item) => (
        <div key={item.label} className="flex items-center justify-between gap-4 p-4">
          <div>{item.label}</div>
          <div className="text-right font-semibold">{item.value}</div>
        </div>
      ))}
    </div>
  );
};
