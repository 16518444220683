import { useCallback } from 'react';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import useTakerMarkets, { TakerMarket, fetchTakerMarkets } from '@/enterprise/data/useTakerMarkets';
import { useReporting } from '@/reporting';

interface MarketStat {
  marketUuid: string;
  takerId: number;
  time: number;
}

export interface ServerSentEventListeners {
  marketStats: MarketStat[];
}

const refreshInvoicesAndStats = async (queryClient: QueryClient, takerMarkets: TakerMarket[]) => {
  return Promise.all(
    takerMarkets.map(async (tm) => {
      await queryClient.refetchQueries({
        queryKey: ['eligible-invoices', tm.marketId, tm.takerDivisionId],
      });
      await queryClient.refetchQueries({
        queryKey: ['taker-market-stats', tm.marketId, tm.takerDivisionId],
      });
    })
  );
};

export const useRefetchStatsQueries = () => {
  const queryClient = useQueryClient();
  const { data: takerMarkets } = useTakerMarkets();

  const refetchAllStats = useCallback(async () => {
    await queryClient.refetchQueries({ queryKey: ['eligible-invoices'] });
    await queryClient.refetchQueries({ queryKey: ['taker-markets'] });
    await queryClient.refetchQueries({ queryKey: ['taker-market-stats'] });
  }, [queryClient]);

  const refetchOneTakerMarket = useCallback(
    async ({ marketUuid, takerId }: { marketUuid: string; takerId: number }) => {
      const takerMarket = takerMarkets?.find((tm) => tm.marketUuid === marketUuid && tm.takerDivisionId === takerId);

      if (!takerMarket) {
        return;
      }

      try {
        const updatedTakerMarkets = await fetchTakerMarkets({
          filter: {
            makerOrganizationUuids: [takerMarket.makerOrganizationUuid],
            supplierMarkets: [
              {
                marketUuid: takerMarket.marketUuid,
                supplierDivisionUuid: takerMarket.takerDivisionUuid,
              },
            ],
          },
        });
        const updatedTakerMarket = updatedTakerMarkets.find(
          (tm) => tm.marketUuid === marketUuid && tm.takerDivisionId === takerId
        );

        if (updatedTakerMarket) {
          queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets = []) => {
            const takerMarketIndex = prevTakerMarkets.findIndex(
              (ptm) =>
                ptm.marketUuid === updatedTakerMarket.marketUuid &&
                ptm.takerDivisionId === updatedTakerMarket?.takerDivisionId
            );

            if (takerMarketIndex !== -1) {
              const newTakerMarkets = [...prevTakerMarkets];
              newTakerMarkets[takerMarketIndex] = updatedTakerMarket;
              return newTakerMarkets;
            }

            return prevTakerMarkets;
          });
        }
      } catch (error) {
        console.log(error);
      }

      await refreshInvoicesAndStats(queryClient, [takerMarket]);
    },
    [queryClient, takerMarkets]
  );

  const refetchManyTakerMarkets = useCallback(
    async (takerMarketsToRefresh: { marketUuid: string; takerId: number }[]) => {
      const existingTakerMarkets = takerMarkets?.filter(
        (tm) =>
          takerMarketsToRefresh.find(
            (tmRefresh) => tmRefresh.marketUuid === tm.marketUuid && tmRefresh.takerId === tm.takerDivisionId
          ) !== undefined
      );

      if (!existingTakerMarkets || existingTakerMarkets.length !== takerMarketsToRefresh.length) {
        return;
      }

      // Due to URL length restrictions, we can only make a request with ~16 taker markets in the filter
      // so batch these up
      const BATCH_SIZE = 16;

      let batchStartIdx = 0;
      while (batchStartIdx < existingTakerMarkets.length) {
        const takerMarketBatch = existingTakerMarkets.slice(batchStartIdx, batchStartIdx + BATCH_SIZE);

        try {
          const updatedTakerMarkets = await fetchTakerMarkets({
            filter: {
              makerOrganizationUuids: takerMarketBatch.map((tm) => tm.makerOrganizationUuid),
              supplierMarkets: takerMarketBatch.map((tm) => ({
                marketUuid: tm.marketUuid,
                supplierDivisionUuid: tm.takerDivisionUuid,
              })),
            },
          });

          if (updatedTakerMarkets) {
            queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets = []) => {
              const newTakerMarkets = [...prevTakerMarkets];
              updatedTakerMarkets.forEach((updatedTakerMarket) => {
                const takerMarketIndex = prevTakerMarkets.findIndex(
                  (ptm) =>
                    ptm.marketUuid === updatedTakerMarket.marketUuid &&
                    ptm.takerDivisionId === updatedTakerMarket?.takerDivisionId
                );

                if (takerMarketIndex !== -1) {
                  newTakerMarkets[takerMarketIndex] = updatedTakerMarket;
                }
              });

              return newTakerMarkets;
            });
          }
        } catch (error) {
          console.log(error);
        }

        batchStartIdx += BATCH_SIZE;
      }

      await refreshInvoicesAndStats(queryClient, existingTakerMarkets);
    },
    [queryClient, takerMarkets]
  );

  return {
    refetchAllStats,
    refetchOneTakerMarket,
    refetchManyTakerMarkets,
  };
};

// store in react query for any taker market the FE is waiting to receive a stats update for
export const useMarketStatsStore = () => {
  const { trackEnterpriseEvent } = useReporting();
  const queryClient = useQueryClient();
  const { refetchOneTakerMarket } = useRefetchStatsQueries();

  const updateMarketStatsStore = ({ marketUuid, takerId }: { marketUuid: string; takerId: number | number[] }) => {
    const sseListeners = queryClient.getQueryData<ServerSentEventListeners>(['server-sent-event-listeners']);

    const foundMarketStat = sseListeners?.marketStats.find((marketStat) => {
      if (Array.isArray(takerId)) {
        return marketStat.marketUuid === marketUuid && takerId.includes(marketStat.takerId);
      }

      if (typeof takerId === 'number') {
        return marketStat.marketUuid === marketUuid && marketStat.takerId === takerId;
      }
    });

    if (foundMarketStat) {
      if (Array.isArray(takerId)) {
        trackEnterpriseEvent('stats-update::in-time', {
          marketUuid: foundMarketStat.marketUuid,
          takerId: foundMarketStat.takerId,
        });
      }

      if (typeof takerId === 'number') {
        trackEnterpriseEvent('stats-update::long', {
          marketUuid: foundMarketStat.marketUuid,
          takerId: foundMarketStat.takerId,
        });
      }

      // remove the found market stats listener
      queryClient.setQueryData<ServerSentEventListeners>(['server-sent-event-listeners'], (prevSseListeners) => {
        return {
          ...prevSseListeners,
          marketStats: (prevSseListeners?.marketStats ?? []).filter(
            (marketStat) =>
              marketStat.marketUuid !== foundMarketStat.marketUuid ||
              marketStat.takerId !== foundMarketStat.takerId ||
              marketStat.time !== foundMarketStat.time
          ),
        };
      });

      refetchOneTakerMarket({ marketUuid: foundMarketStat.marketUuid, takerId: foundMarketStat.takerId });
    }
  };

  return updateMarketStatsStore;
};

export const useServerSideEventListeners = () => {
  const queryClient = useQueryClient();
  const updateMarketStatsStore = useMarketStatsStore();

  const listenToMarketStats = (tmMeta: { marketUuid: string; takerId: number }[]) => {
    const sseListeners = queryClient.getQueryData<ServerSentEventListeners>(['server-sent-event-listeners']);
    return sseListeners?.marketStats.some((marketStat) =>
      tmMeta.find(({ marketUuid, takerId }) => marketStat.marketUuid === marketUuid && marketStat.takerId === takerId)
    );
  };

  const listenToAllMarketStats = () => {
    const sseListeners = queryClient.getQueryData<ServerSentEventListeners>(['server-sent-event-listeners']);
    return (sseListeners?.marketStats.length ?? 0) > 0;
  };

  const notifyMarketStatsSubscribers = ({ marketUuid, takerIds }: { marketUuid: string; takerIds: number[] }) => {
    updateMarketStatsStore({ marketUuid, takerId: takerIds });
  };

  const subscribeToMarketStats = ({ marketUuid, takerId }: { marketUuid: string; takerId: number }) => {
    queryClient.setQueryData<ServerSentEventListeners>(['server-sent-event-listeners'], (prevSseListeners) => ({
      ...prevSseListeners,
      marketStats: [
        ...(prevSseListeners?.marketStats ?? []),
        {
          marketUuid,
          takerId,
          time: Date.now(),
        },
      ],
    }));
  };

  return {
    listenToMarketStats,
    listenToAllMarketStats,
    notifyMarketStatsSubscribers,
    subscribeToMarketStats,
  };
};
