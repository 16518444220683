import { useMutation, useQueryClient } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import useTakerMarkets, { OfferConfig, TakerMarket } from '@/enterprise/data/useTakerMarkets';
import apiClient from '@/lib/apiClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import useDataDog from '@/reporting/DataDog';

type RelevantOfferConfig = Pick<
  OfferConfig,
  'uuid' | 'isDiscountBidding' | 'maxDiscount' | 'maxApr' | 'exclusionSettings'
> & { expireOn?: string | null };

const useSubmitPriceDiscoveryOffer = () => {
  const queryClient = useQueryClient();
  const { data: takerMarkets } = useTakerMarkets();
  const { subscribeToMarketStats } = useServerSideEventListeners();
  const { addError } = useDataDog();

  return useMutation({
    mutationKey: ['submitPriceDiscoveryOffer'],
    mutationFn: async ({
      marketUuid,
      offerConfig,
      supplierDivisionUuid,
    }: {
      marketUuid: string;
      offerConfig: RelevantOfferConfig;
      supplierDivisionUuid: string;
    }) => {
      const takerMarket = takerMarkets?.find(
        (meta) => meta.marketUuid === marketUuid && meta.takerDivisionUuid === supplierDivisionUuid
      );

      const rate = offerConfig.isDiscountBidding ? offerConfig.maxDiscount : offerConfig.maxApr;

      if (offerConfig && offerConfig.uuid) {
        return apiClient
          .patch('api/supplier-experience/offers/price-discovery', {
            json: {
              groupingKey: 'SUPPLIER_MARKET',
              status: 'active',
              offers: [
                {
                  makerOrganizationUuid: takerMarket?.makerOrganizationUuid,
                  uuid: offerConfig.uuid,
                },
              ],
              rateInformation: {
                rateType: offerConfig.isDiscountBidding ? 'DISC' : 'APR',
                rate: rate ?? 0,
              },
              expirationDate: offerConfig.expireOn,
            } satisfies paths['/offers/price-discovery']['patch']['requestBody']['content']['application/json'],
          })
          .json<paths['/offers/price-discovery']['patch']['responses']['204']['content']>();
      }

      return apiClient
        .post('api/supplier-experience/offers/price-discovery', {
          json: {
            groupingKey: 'SUPPLIER_MARKET',
            frequency: 'recurring',
            offerType: 'price-discovery',
            rateType: offerConfig.isDiscountBidding ? 'DISC' : 'APR',
            rate: rate ?? 0,
            expirationDate: offerConfig.expireOn ?? undefined,
            supplierMarkets: [
              {
                marketUuid,
                supplierDivisionUuid,
              },
            ],
          } satisfies paths['/offers/price-discovery']['post']['requestBody']['content']['application/json'],
        })
        .json<paths['/offers/price-discovery']['post']['responses']['201']['content']['application/json']>();
    },
    onSuccess: (_data, variables) => {
      const tmMeta = takerMarkets?.find(
        (meta) => meta.marketUuid === variables.marketUuid && meta.takerDivisionUuid === variables.supplierDivisionUuid
      );

      if (tmMeta?.marketUuid && tmMeta?.takerDivisionId) {
        // update the cache with the returned data
        queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) =>
          (prevTakerMarkets ?? []).map((tm) => ({
            ...(tm.marketUuid === variables.marketUuid && tm.takerDivisionUuid === variables.supplierDivisionUuid
              ? {
                  ...tm,
                  offerConfig: {
                    ...tm.offerConfig,
                    ...variables.offerConfig,
                    isEnabled: true,
                  },
                  disableAfterMarketClearsDate: variables.offerConfig.expireOn ?? null,
                }
              : tm),
          }))
        );

        // subscribe to market stats
        // this will refetch stats queries after we know stats have updated
        subscribeToMarketStats({
          marketUuid: tmMeta.marketUuid,
          takerId: tmMeta.takerDivisionId,
        });
      }
    },
    onError: (error, variables) => {
      addError('useSubmitPriceDiscoveryOffer', {
        error,
        variables,
        seaOfferUuid: variables.offerConfig.uuid,
      });
    },
  });
};

export default useSubmitPriceDiscoveryOffer;
