import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonLink, cn } from '@c2fo/liquidity';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSortArrow,
  TableSortDirection,
} from '@/enterprise/components/Table';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import useTakerMarketsGroups from '@/enterprise/data/useTakerMarketsGroups';
import NameYourRateParticipationToggle from '@/enterprise/features/nameYourRate/ParticipationToggle';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useIsNewDivision from '@/utils/useIsNewDivision';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useMarketPricingTypeDisplay from '@/utils/useMarketPricingTypeDisplay';
import FixedRateParticipationToggle from '../fixedRate/ParticipationToggle';
import useFixedRateDetails from '../fixedRate/useFixedRateDetails';
import VariableRateParticipationToggle from '../variableRate/ParticipationToggle';
import useVariableRateDetails from '../variableRate/useVariableRateDetails';
import ChipSection from './components/ChipSection';
import sortTakerMarketTable, { Sort, SortKey } from './utils/sortTakerMarketTable';

const InactiveMarketTable = () => {
  const { t } = useTranslation();
  const { isNewDivision } = useIsNewDivision();
  const { asCurrency, asPercent } = useLocaleFormat();
  const { getMarketPricingTypeDisplay } = useMarketPricingTypeDisplay();
  const { listenToMarketStats } = useServerSideEventListeners();
  const [sort, setSort] = useState<Sort>({ key: 'eligibleInvoiceAmount', direction: 'desc' });
  const takerMarketsGroups = useTakerMarketsGroups();
  const inactiveTakerMarkets = sortTakerMarketTable(takerMarketsGroups['INACTIVE'].takerMarkets, sort);
  const { getFixedRateDetails } = useFixedRateDetails();
  const { getVariableRateDetails } = useVariableRateDetails();

  const handleSort = (accessorKey: SortKey) => {
    let direction: TableSortDirection = accessorKey === 'makerOrganizationName' ? 'asc' : 'desc';

    if (sort?.key === accessorKey) {
      direction = sort.direction === 'desc' ? 'asc' : 'desc';
    }

    setSort({ key: accessorKey, direction });
  };

  const tableHeadColumns: { accessorKey: SortKey; translation: string }[] = [
    {
      accessorKey: 'participation',
      translation: t('core.participation'),
    },
    {
      accessorKey: 'makerOrganizationName',
      translation: t('core.division'),
    },
    {
      accessorKey: 'eligibleInvoiceAmount',
      translation: t('taker.dictionary.availableAR.label'),
    },
    {
      accessorKey: 'marketPricingType',
      translation: t('taker.dictionary.marketType'),
    },
    {
      accessorKey: 'rateInfo.estimatedRate',
      translation: t('taker.dictionary.marketRate.label'),
    },
    {
      accessorKey: 'offerConfig',
      translation: t('taker.dictionary.offer.label'),
    },
  ];

  const getFormattedMarketRate = (takerMarket: TakerMarket): ReactNode => {
    if (takerMarket.marketPricingType === 'STATIC') {
      const { formattedMarketRate } = getFixedRateDetails(takerMarket);
      return <>{formattedMarketRate}</>;
    }

    if (takerMarket.marketPricingType === 'BENCHMARK') {
      const { rateName, rateLabel } = getVariableRateDetails(takerMarket);
      return rateName && rateLabel ? <>{rateLabel}</> : <>{t('core.na')}</>;
    }

    return <>{t('core.na')}</>;
  };

  const getFormattedOfferRate = (takerMarket: TakerMarket): ReactNode => {
    if (takerMarket.marketPricingType === 'BENCHMARK') {
      const { formattedEstimatedRate, estimatedRate } = getVariableRateDetails(takerMarket);
      return estimatedRate && estimatedRate > 0 ? <>{formattedEstimatedRate}</> : <>{t('core.na')}</>;
    }

    const isDiscountBidding = takerMarket.offerConfig.isDiscountBidding;
    const rate = isDiscountBidding ? takerMarket.offerConfig.maxDiscount : takerMarket.offerConfig.maxApr;
    const displayRate =
      typeof rate === 'number'
        ? `${asPercent({ value: rate / 100, decimals: 2 })} ${
            isDiscountBidding ? t('core.discountAbbreviation') : t('core.apr')
          }`
        : t('core.na');

    return <span className="uppercase">{displayRate}</span>;
  };

  return inactiveTakerMarkets && inactiveTakerMarkets.length > 0 ? (
    <div className="w-full overflow-auto">
      <Table>
        <TableHeader>
          <TableRow>
            {tableHeadColumns.map(({ accessorKey, translation }) => (
              <TableHead
                key={accessorKey}
                onClick={() => handleSort(accessorKey)}
                {...(['makerOrganizationName'].includes(accessorKey) && { className: 'w-[224px]' })}
                {...(['participation'].includes(accessorKey) && { className: 'w-[170px]' })}
              >
                <TableSortArrow
                  accessorKey={accessorKey}
                  sort={sort}
                  {...(!['makerOrganizationName', 'participation'].includes(accessorKey) && { textRight: true })}
                >
                  {translation}
                </TableSortArrow>
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {inactiveTakerMarkets?.map((takerMarket) => {
            const listeningForMarketStat = listenToMarketStats([
              {
                marketUuid: takerMarket.marketUuid,
                takerId: takerMarket.takerDivisionId,
              },
            ]);
            const marketPricingTypeDisplay = getMarketPricingTypeDisplay(takerMarket.marketPricingType);

            return (
              <TableRow key={takerMarket.id} className={cn('transition-all duration-100')}>
                <TableCell>
                  <>
                    {/* participation can only be toggled for a taker market that has had a taker config set */}
                    {takerMarket.offerConfig.id && takerMarket.marketPricingType === 'PRICE_DISCOVERY' && (
                      <NameYourRateParticipationToggle takerMarket={takerMarket} referrer="market" />
                    )}
                    {takerMarket.offerConfig.id && takerMarket.marketPricingType === 'BENCHMARK' && (
                      <VariableRateParticipationToggle
                        // If a market is inactive, we only allow the user to toggle off, so no need for an edit offer modal
                        openEditOfferModal={() => null}
                        takerMarket={takerMarket}
                        referrer="market"
                      />
                    )}
                    {takerMarket.offerConfig.id && takerMarket.marketPricingType === 'STATIC' && (
                      <FixedRateParticipationToggle
                        // If a market is inactive, we only allow the user to toggle off, so no need for an edit offer modal
                        openEditOfferModal={() => null}
                        takerMarket={takerMarket}
                        referrer="market"
                      />
                    )}
                    {takerMarket.offerConfig.id && !takerMarket.marketIsEnabled && (
                      <div className="pt-1 text-xs text-gray-300">{t('closedMarketMessaging.futureOffer')}</div>
                    )}
                  </>
                </TableCell>
                <TableCell className="flex flex-row">
                  {isNewDivision(takerMarket.userAddedToDivision) && (
                    <div className="-ml-4 mr-2 flex h-6 items-center">
                      <div className="h-2 w-2 rounded-full bg-lightBlue-500">
                        <span className="sr-only">{t('core.newDivision')}</span>
                      </div>
                    </div>
                  )}
                  <div className="max-w-[224px] truncate">
                    <Link
                      className="font-bold"
                      to={`../../markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible`}
                    >
                      {getTakerMarketName(takerMarket)}
                    </Link>
                    <div className="truncate text-sm font-medium text-gray-600">
                      <span>{takerMarket.currency}</span>
                    </div>
                    <div
                      className="items-center truncate text-sm font-medium uppercase text-gray-600"
                      title={getTakerMarketDivisionTitle(takerMarket)?.title}
                    >
                      {getTakerMarketDivisionTitle(takerMarket)?.content}
                    </div>
                    <ChipSection takerMarket={takerMarket} />
                  </div>
                </TableCell>
                <TableCell className="text-right">
                  {asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency)}
                </TableCell>
                <TableCell className="text-right">{marketPricingTypeDisplay}</TableCell>
                <TableCell className="text-right">{getFormattedMarketRate(takerMarket)}</TableCell>
                <TableCell className="text-right">{getFormattedOfferRate(takerMarket)}</TableCell>
                <TableCell colSpan={3} className={cn('text-center', { 'blur-sm': listeningForMarketStat })}>
                  {takerMarket.offerConfig?.id && takerMarket.offerConfig?.isEnabled ? (
                    <span className="text-center text-sm font-medium">
                      {t('closedMarketMessaging.offerWillBeEnabled')}
                    </span>
                  ) : (
                    <div className="m-auto max-w-lg">
                      <div className="flex justify-center gap-3">
                        <ButtonLink
                          to={`../../markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible`}
                          size="sm"
                          variant="secondary"
                        >
                          {t('core.viewInvoices')}
                        </ButtonLink>
                      </div>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  ) : null;
};

export default InactiveMarketTable;
