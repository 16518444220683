import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn, IconButton } from '@c2fo/liquidity';
import { CheckCircleIcon, ChevronDownIcon, TimesCircleIcon } from '@c2fo/liquidity/icons';
import { InvoiceRulesOperatorType } from '@/enterprise/data/useCreateRecurringRule';

const exampleTableData = {
  headings: ['core.invoice', 'core.invoiceAmount', 'core.daysPaidEarly', 'recurringRules.exampleReason'],
  values: [
    {
      id: 'A',
      amount: '$80.00 USD',
      dpe: '14',
      reason: {
        AND: {
          type: 'excluded',
          label: 'core.excluded',
          text: 'recurringRules.exampleEachCriteria',
        },
        OR: {
          type: 'excluded',
          label: 'core.excluded',
          text: 'recurringRules.exampleEachCriteria',
        },
      },
    },
    {
      id: 'B',
      amount: '$100.00 USD',
      dpe: '5',
      reason: {
        AND: {
          type: 'included',
          label: 'core.included',
          text: 'recurringRules.exampleMeetsOnlyAmountCriteria',
        },
        OR: {
          type: 'excluded',
          label: 'core.excluded',
          text: 'recurringRules.exampleAmountCriteria',
        },
      },
    },
    {
      id: 'C',
      amount: '$140.00 USD',
      dpe: '10',
      reason: {
        AND: {
          type: 'included',
          label: 'core.included',
          text: 'recurringRules.exampleMeetsOnlyDpeCriteria',
        },
        OR: {
          type: 'excluded',
          label: 'core.excluded',
          text: 'recurringRules.exampleDpeCriteria',
        },
      },
    },
    {
      id: 'D',
      amount: '$120.00 USD',
      dpe: '8',
      reason: {
        AND: {
          type: 'included',
          label: 'core.included',
          text: 'recurringRules.exampleNeitherCriteria',
        },
        OR: {
          type: 'included',
          label: 'core.included',
          text: 'recurringRules.exampleNeitherCriteria',
        },
      },
    },
  ],
};

const CriteriaExampleTable = ({ operatorType }: { operatorType: InvoiceRulesOperatorType }) => {
  const { t } = useTranslation();
  const [expandRuleExample, setExpandRuleExample] = useState<boolean>(false);

  return (
    <div className="rounded-md bg-canvas">
      <div className="flex items-center justify-between p-4">
        <div className="font-medium capitalize">
          {operatorType === 'AND' ? t('recurringRules.exampleAllOf') : t('recurringRules.exampleAnyOf')}
        </div>
        <div>
          <IconButton
            className={cn({ 'rotate-180': expandRuleExample })}
            icon={ChevronDownIcon}
            name="Expand Example Table"
            onClick={() => setExpandRuleExample(!expandRuleExample)}
            size="sm"
            variant="ancillary"
          />
        </div>
      </div>
      {expandRuleExample && (
        <div className="px-4 pb-4">
          <div className="pb-4 text-sm">
            {operatorType === 'AND'
              ? t('recurringRules.exampleDescriptionAnd')
              : t('recurringRules.exampleDescriptionOr')}
          </div>
          <div className="m-auto w-full overflow-auto border border-stroke lg:w-3/4">
            <table className="w-full bg-white text-sm">
              <thead className="bg-gray-200">
                <tr>
                  {exampleTableData.headings.map((heading) => (
                    <th
                      key={heading}
                      className="whitespace-nowrap border-b border-r border-stroke p-2 font-normal capitalize text-black last-of-type:border-r-0"
                    >
                      {t(heading)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {exampleTableData.values.map((value, index) => (
                  <tr
                    key={value.id}
                    className={cn('[&_td]:p-2', {
                      '[&_td]:pb-0': index !== exampleTableData.values.length - 1,
                    })}
                  >
                    <td className="w-1 whitespace-nowrap border-r border-stroke">{value.id}</td>
                    <td className="w-1 whitespace-nowrap border-r border-stroke">{value.amount}</td>
                    <td className="w-1 whitespace-nowrap border-r border-stroke">{value.dpe}</td>
                    <td className="w-full whitespace-nowrap">
                      <div className="flex items-center gap-2">
                        {value.reason[operatorType].type === 'included' ? (
                          <CheckCircleIcon className="fill-blue-500" />
                        ) : (
                          <TimesCircleIcon className="fill-deepOrange-500" />
                        )}
                        <span>
                          {t(value.reason[operatorType].label)} - {t(value.reason[operatorType].text)}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="m-auto w-full pt-2 text-xs italic text-text-secondary lg:w-3/4">
            {t('recurringRules.exampleNotRealData')}
          </div>
        </div>
      )}
    </div>
  );
};

export default CriteriaExampleTable;
