import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@c2fo/liquidity';
import { CaretDownFilledIcon } from '@c2fo/liquidity/icons';
import { RecurringRuleCategory } from '@/enterprise/data/useRecurringRules';
import defaultRuleValues from '@/enterprise/features/recurringRules/utils/defaultRuleValues';
import useRecurringRuleFormContext from '@/enterprise/features/recurringRules/utils/useRecurringRuleFormContext';
import { RecurringRuleFormError } from '@/enterprise/features/recurringRules/utils/useValidateRuleForm';
import Divider from '@/shared/components/Divider';
import CriteriaBehaviorSelect from './CriteriaBehaviorSelect';
import CriteriaOptions from './CriteriaOptions';

export interface CriteriaSelectProps {
  errors: RecurringRuleFormError[];
  setErrors: Dispatch<SetStateAction<RecurringRuleFormError[]>>;
  toCurrency: string;
}

const addCriteriaOptions: {
  label: string;
  value: RecurringRuleCategory;
}[] = [
  {
    label: 'core.daysPaidEarly',
    value: 'dpe',
  },
  {
    label: 'core.invoiceAmount',
    value: 'amount',
  },
  {
    label: 'recurringRules.dueDate.category.label',
    value: 'dueDate',
  },
  {
    label: 'recurringRules.invoiceId.category.label',
    value: 'invoiceId',
  },
];

const CriteriaSelect = ({ errors, setErrors, toCurrency }: CriteriaSelectProps) => {
  const { t } = useTranslation();
  const { control } = useRecurringRuleFormContext();

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'invoiceRules',
  });

  const categoryLength = fields.length;
  const availableAddCriteriaOptions = addCriteriaOptions.filter(
    (option) => !fields.some((field) => field.category === option.value)
  );

  return (
    <div className="space-y-6">
      <div className="text-sm">{t('recurringRules.setTheParametersForEachCriteria')}</div>
      <div className="space-y-3">
        {fields.map((item, index) => (
          <CriteriaOptions
            key={item.id}
            category={item.category}
            categoryIndex={index}
            categoryLength={categoryLength}
            categoryRemove={() => remove(index)}
            errors={errors}
            setErrors={setErrors}
            toCurrency={toCurrency}
          />
        ))}
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button disabled={availableAddCriteriaOptions.length < 1} size="sm" variant="tertiary">
            <span className="flex items-center gap-2">
              <span>{t('recurringRules.addCriteria')}</span>
              <span>
                <CaretDownFilledIcon className="h-5 w-5 fill-secondary-500" />
              </span>
            </span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent collisionPadding={24} avoidCollisions={false} className="min-w-64">
          {availableAddCriteriaOptions.map((option) => (
            <DropdownMenuItem
              key={option.label}
              onClick={() => append({ category: option.value, criteria: [defaultRuleValues[option.value]] })}
            >
              {t(option.label)}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      {categoryLength > 1 && (
        <>
          <Divider />
          <CriteriaBehaviorSelect />
        </>
      )}
    </div>
  );
};

export default CriteriaSelect;
