import { Outlet } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { useFeatureForUser } from '@/lib/features';
import { useReporting } from '@/reporting';
import PageTracker from '@/reporting/PageTracker';
import AppLayout from '@/shared/components/AppLayout';

const SmbApp = () => {
  const [smbFeature] = useFeatureForUser('earlyPay_offerBuilderRevamp');
  const { trackSmbEvent } = useReporting();

  console.log(smbFeature);

  useEffectOnce(() => {
    trackSmbEvent('smb::specific::event');
  });

  return (
    <>
      <PageTracker />
      <AppLayout experience="smb">
        <Outlet />
      </AppLayout>
    </>
  );
};

export default SmbApp;
