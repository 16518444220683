import { useSuspenseQuery } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';

export interface DraftOfferRateInformation {
  rateType: 'APR' | 'DISC' | 'REF';
  rate: number | undefined;
  spread: number | undefined;
}

async function fetchDraftOfferSEA(uuid: string) {
  const res = await apiClient
    .get(`api/supplier-experience/offers/draft/${uuid}`)
    .json<paths['/offers/draft/{uuid}']['get']['responses']['200']['content']['application/json']>();

  const spread =
    res.data.rateInformation !== null && 'spread' in res.data.rateInformation
      ? res.data.rateInformation.spread ?? null
      : null;
  const type =
    res.data.offerType === 'preferred-term'
      ? 'PREFERRED'
      : res.data.offerType === 'price-discovery'
      ? 'PRICE_DISCOVERY'
      : undefined;

  return {
    draftType: res.data.draftType,
    expirationDate: res.data.expirationDate,
    rate: res.data.rateInformation?.rate,
    rateType: res.data.rateInformation?.rateType,
    spread,
    status: res.data.status,
    submitterAuthServiceUuid: res.data.submitterUuid,
    submitterSystemName: res.data.submitterSystemName,
    supplierMarkets: res.data.supplierMarketGroups.map((group) => {
      return group.supplierMarkets[0];
    }),
    termExpirationDate: res.data.offerExpirationDate,
    type,
    uuid,
    authServiceUuid: res.data.authServiceUuid,
    rateInformation: {
      rateType: res.data.rateInformation!.rateType,
      rate: res.data.rateInformation!.rate,
      spread: 'spread' in res.data.rateInformation! ? res.data.rateInformation.spread : undefined,
    },
  };
}

export default function useDraftOffer(uuid: string | undefined) {
  return useSuspenseQuery({
    queryKey: ['draft-offer', uuid],
    queryFn: async () => {
      if (!uuid) {
        return null;
      }

      return fetchDraftOfferSEA(uuid);
    },
  });
}
