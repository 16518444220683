import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonLink, Chip, Modal, ModalActions, ModalContent, ModalTitleInverse } from '@c2fo/liquidity';
import { CalendarLinesIcon } from '@c2fo/liquidity/icons';
import { useGetRecurringOfferScheduleForTakerMarket } from '@/enterprise/data/useOfferSchedules';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { useReporting } from '@/reporting';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';
import getTakerMarketName from '@/utils/getTakerMarketName';
import { buildCadenceFromRecurringSchedule, buildCustomScheduleSentence } from '../../offerModal/utils';

const OfferScheduleChip = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const offerSchedule = useGetRecurringOfferScheduleForTakerMarket(takerMarket);

  if (!offerSchedule) {
    return null;
  }

  const { startDate, endDate, schedule } = offerSchedule;
  const cadence = buildCadenceFromRecurringSchedule(schedule);

  if (!cadence) {
    return null;
  }

  const handleOpenModal = () => {
    setOpenModal(true);
    trackEnterpriseEvent('offer-schedule-chip-modal::opened', {
      takerId: takerMarket.takerDivisionId,
      marketUuid: takerMarket.marketUuid,
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Modal onClose={handleCloseModal} open={openModal} size="sm">
        <ModalTitleInverse>
          <span className="flex flex-col">
            <span>{t('scheduling.scheduledOffer')}</span>
            <span className="text-base font-normal text-text-secondary">{getTakerMarketName(takerMarket)}</span>
          </span>
        </ModalTitleInverse>
        <ModalContent className="mt-2 flex flex-col gap-8">
          <div>
            This offer is set to automatically participate according to a schedule. Modify the offer to change the
            schedule.
          </div>
          <div className="bg-canvas p-4">
            {buildCustomScheduleSentence({
              startDate,
              endDate,
              cadence,
              t,
            })}
          </div>
          <div className="text-sm italic">{t('scheduling.schedulesOperateUsCentral')}</div>
        </ModalContent>
        <ModalActions>
          <Button onClick={handleCloseModal} variant="secondary">
            {t('core.close')}
          </Button>
          <ButtonLink
            onClick={() => trackEnterpriseEvent('offer-schedule-chip-modal::redirected')}
            to={asEnterpriseLink(`markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/`)}
            variant="cta"
          >
            {t('draftOffer.viewDetails')}
          </ButtonLink>
        </ModalActions>
      </Modal>
      <Chip
        label={
          <div className="flex items-center gap-1">
            <CalendarLinesIcon className="h-4 w-4 fill-gray-900" title="Scheduled Offer Indicator" />
            {t('scheduling.scheduled')}
          </div>
        }
        size="xs"
        variant="outlined"
        isRound
        onClick={handleOpenModal}
      />
    </>
  );
};

export default OfferScheduleChip;
