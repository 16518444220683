import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { smbBasename } from '@/constants';
import useEnableRecurringRules from '@/enterprise/features/recurringRules/utils/useEnableRecurringRules';
import useFeature from '@/lib/features';
import { IntercomDataTargetNames } from '@/reporting/intercomDataTargetNames';
import { asEnterpriseLink, asSmbLink } from '@/utils/experienceLinkHelper';
import useNewExperienceEnabled from '@/utils/useNewExperienceEnabled';

interface NavigationLinkProps {
  key: string;
  text: string;
  to: string;
  isNew: boolean;
  matchExact: boolean;
  intercomDataTarget?: IntercomDataTargetNames;
}

const useLinks = () => {
  const { t } = useTranslation();
  const newExperienceEnabled = useNewExperienceEnabled();
  const { pathname } = useLocation();
  const enableRecurringRules = useEnableRecurringRules();
  const [enableFindYourCustomers] = useFeature('enterprise-ui_enableSeaFindYourCustomer');
  const [enableOfferScheduling] = useFeature('enterprise-ui_enableOfferScheduling');

  return useMemo(() => {
    let linksArray: NavigationLinkProps[] = [];

    // enterprise links
    if (pathname.includes('enterprise')) {
      linksArray = [
        {
          key: 'markets',
          text: t('core.markets'),
          to: asEnterpriseLink('markets'),
          isNew: false,
          matchExact: false,
        },
        {
          key: 'history',
          text: t('taker.consolidatedHistory.general'),
          to: asEnterpriseLink('history'),
          isNew: false,
          matchExact: false,
        },
        {
          key: 'find-your-customers',
          text: t('registration.anonymousCallToAction'),
          to: enableFindYourCustomers ? asEnterpriseLink('find-your-customers') : asEnterpriseLink('markets/search'),
          isNew: false,
          matchExact: false,
        },
      ];

      if (enableRecurringRules && !enableOfferScheduling) {
        linksArray.push({
          key: 'recurring-rules',
          text: t('recurringRules.title'),
          to: asEnterpriseLink('recurring-rules'),
          isNew: true,
          matchExact: false,
          intercomDataTarget: 'rulesNavigation',
        });
      }

      if (enableRecurringRules && enableOfferScheduling) {
        linksArray.push({
          key: 'automation',
          text: t('core.earlyPayAutomation'),
          to: asEnterpriseLink('automation'),
          isNew: true,
          matchExact: false,
          intercomDataTarget: 'rulesNavigation',
        });
      }

      // For NSE users, only show consolidated history link
      if (newExperienceEnabled) {
        linksArray = [
          {
            key: 'history',
            text: t('taker.consolidatedHistory.general'),
            to: asEnterpriseLink('history'),
            isNew: false,
            matchExact: false,
          },
        ];
      }
    }

    // smb links
    if (pathname.includes('smb')) {
      linksArray = [
        {
          key: 'smb',
          text: 'Early Pay',
          to: `/${smbBasename}`,
          isNew: false,
          matchExact: true,
        },
        {
          key: 'find-your-customers',
          text: t('registration.anonymousCallToAction'),
          to: asSmbLink('find-your-customers'),
          isNew: false,
          matchExact: false,
        },
      ];
    }

    return linksArray;
  }, [pathname, t, enableFindYourCustomers, enableRecurringRules, enableOfferScheduling, newExperienceEnabled]);
};

export default useLinks;
