import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, TextInput } from '@c2fo/liquidity';
import { PercentIcon } from '@c2fo/liquidity/icons';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import useOfferModalFormContext from '../useOfferModalFormContext';
import { validateRateInputForTakerMarket } from '../utils';

export const PriceDiscoveryRateInput = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const { control, register, formState, watch } = useOfferModalFormContext();

  const rateOptions = [
    { value: 'disc', label: t('core.discount'), desc: t('offer.flatRateMessage') },
    { value: 'apr', label: t('core.apr'), desc: t('offer.recurringMessage') },
  ];

  return (
    <div className="grid grid-cols-2 gap-4 p-4">
      <div className="space-y-2">
        <Label htmlFor="rateStr">{t('core.offerRate')}</Label>
        <TextInput
          type="number"
          className="w-full"
          hasError={!!formState.errors.rateStr}
          id="rateStr"
          endIcon={PercentIcon}
          {...register('rateStr', {
            validate: (rateInput) => {
              const { errorMessage } = validateRateInputForTakerMarket({
                rateInput,
                rateType: watch('rateTypeStr'),
                takerMarket,
                t,
              });

              return errorMessage;
            },
          })}
        />
        {formState.errors.rateStr?.message && (
          <p className="text-xs text-red-500">{t(formState.errors.rateStr.message)}</p>
        )}
      </div>
      <div className="space-y-2">
        <Label htmlFor="rateStr">{t('core.rateType')}</Label>
        <Controller
          control={control}
          name="rateTypeStr"
          render={({ field }) => (
            <Select onValueChange={field.onChange} value={field.value}>
              <SelectTrigger aria-label={t('core.rateType')} hasError={!!formState.errors.rateTypeStr}>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {rateOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    <p>{option.label}</p>
                    <p className="block text-wrap text-sm [button_&]:hidden">{option.desc}</p>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          rules={{ required: true }}
        />
      </div>
    </div>
  );
};
