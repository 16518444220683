import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import useFeature from '@/lib/features';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import useEnableRecurringRules from '../../recurringRules/utils/useEnableRecurringRules';
import useGetRecurringRulesForTakerMarkets from '../../recurringRules/utils/useGetRecurringRulesForTakerMarkets';
import ManualExclusionChip from './ManualExclusionChip';
import OfferScheduleChip from './OfferScheduleChip';
import RecurringRuleExclusionChip from './RecurringRuleExclusionChip';

const ChipSectionContent = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const enableRecurringRules = useEnableRecurringRules();
  const [enableOfferScheduling] = useFeature('enterprise-ui_enableOfferScheduling');
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();
  const [recurringRule] = getRecurringRulesForTakerMarkets([takerMarket]);

  return (
    <div className="mt-1 flex flex-wrap items-center gap-2">
      {enableOfferScheduling && <OfferScheduleChip takerMarket={takerMarket} />}
      {enableRecurringRules && <RecurringRuleExclusionChip rule={recurringRule} takerMarket={takerMarket} />}
      {!recurringRule && <ManualExclusionChip takerMarket={takerMarket} />}
    </div>
  );
};

const ChipSection = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  return (
    <QueryBoundaries>
      <ChipSectionContent takerMarket={takerMarket} />
    </QueryBoundaries>
  );
};

export default ChipSection;
