import QueryBoundaries from '@/shared/components/QueryBoundaries';
import { useServerSideEvents } from './useServerSideEvents';

const ServerSentEventsCaller = () => {
  useServerSideEvents();
  return null;
};

export function ServerSentEvents() {
  return (
    <QueryBoundaries ErrorComponent={() => <></>}>
      <ServerSentEventsCaller />
    </QueryBoundaries>
  );
}

export default ServerSentEvents;
