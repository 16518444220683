import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Chip, cn, Logo } from '@c2fo/liquidity';
import { BarsIcon } from '@c2fo/liquidity/icons';
import { enterpriseBasename } from '@/constants';
import CurrencySelect from '@/enterprise/components/CurrencySelect';
import { ExperienceType } from '@/shared/components/AppLayout';
import LanguageChangeMenu from '@/shared/components/Navigation/LanguageChangeMenu';
import MobileNavigation from '@/shared/components/Navigation/MobileNavigation';
import UserMenu from '@/shared/components/Navigation/UserMenu';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import useNewExperienceEnabled from '@/utils/useNewExperienceEnabled';
import RecentAwardsDropdown from './RecentAwardsDropdown';
import useLinks from './utils';

const prefixUrl = window.location.origin;

const isCurrent = ({
  key,
  link,
  matchExact,
  pathname,
}: {
  key: string;
  link: string;
  matchExact: boolean;
  pathname: string;
}) => {
  if (matchExact || (key === 'markets' && pathname.includes('search'))) {
    return pathname === link;
  }

  return pathname.includes(link);
};

const Navigation = ({ experience }: { experience: ExperienceType }) => {
  const links = useLinks();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [navIsOpen, setNavIsOpen] = useState<boolean>(false);
  const newExperienceEnabled = useNewExperienceEnabled();
  const logoLink = newExperienceEnabled ? `${prefixUrl}/early-pay` : `/${enterpriseBasename}`;

  const desktopLogo = <Logo className="hidden h-9 w-auto lg:block" color="primary" />;
  const desktopLogoWithLink = newExperienceEnabled ? (
    <a aria-label={t('ariaLabel.linkToHomepage')} href={logoLink} className="hidden lg:block">
      {desktopLogo}
    </a>
  ) : (
    <Link aria-label={t('ariaLabel.linkToHomepage')} className="hidden lg:block" to={logoLink}>
      {desktopLogo}
    </Link>
  );

  const mobileLogo = <Logo className="h-8 w-auto lg:hidden" color="primary" brandMark />;
  const mobileLogoWithLink = newExperienceEnabled ? (
    <a
      aria-label={t('ariaLabel.linkToHomepage')}
      href={logoLink}
      className="flex h-10 w-10 items-center justify-center lg:hidden"
    >
      {mobileLogo}
    </a>
  ) : (
    <Link
      aria-label={t('ariaLabel.linkToHomepage')}
      className="flex h-10 w-10 items-center justify-center lg:hidden"
      to={logoLink}
    >
      {mobileLogo}
    </Link>
  );

  return (
    <div>
      <div>
        <nav className="fixed z-10 h-16 w-full border-b bg-white text-secondary-500">
          <div className="mx-auto flex h-16 max-w-screen-2xl items-center space-x-6 px-4 lg:px-6">
            {/* logo */}
            <div className="flex gap-2">
              <div className="flex flex-1 lg:hidden">
                <button
                  className="-ml-2 flex h-10 w-10 items-center justify-center"
                  onClick={() => setNavIsOpen(true)}
                  type="button"
                >
                  <BarsIcon className="h-6 w-6 fill-secondary-500" />
                </button>
              </div>
              {/* desktop logo */}
              {desktopLogoWithLink}
              {/* mobile logo */}
              {mobileLogoWithLink}
            </div>
            {/* desktop nav */}
            <div className="hidden flex-1 items-center justify-between gap-6 font-bold lg:flex">
              {/* left nav section */}
              <div className="flex">
                {links.map((link) => {
                  const current = isCurrent({ key: link.key, link: link.to, matchExact: link.matchExact, pathname });

                  return (
                    <Link
                      key={link.key}
                      to={link.to}
                      aria-current={current ? 'page' : undefined}
                      className={cn(
                        'flex h-16 items-center whitespace-nowrap border-b-2 border-transparent px-4 transition-all duration-200',
                        { 'border-primary-500 bg-deepGray-50': current }
                      )}
                      {...(link.intercomDataTarget && { 'data-intercom-target': link.intercomDataTarget })}
                    >
                      {t(`${link.text}`)}
                      {link.isNew && (
                        <Chip
                          className="ml-1.5"
                          isRound
                          label={t('core.beta')}
                          size="xs"
                          type="info"
                          variant="filled"
                        />
                      )}
                    </Link>
                  );
                })}
                {experience === 'enterprise' && <RecentAwardsDropdown />}
              </div>
              {/* right nav section */}
              <div className="flex items-center space-x-4">
                <QueryBoundaries>
                  <LanguageChangeMenu />
                  <CurrencySelect />
                </QueryBoundaries>
                <QueryBoundaries>
                  <UserMenu />
                </QueryBoundaries>
              </div>
            </div>
          </div>
        </nav>
        {/* mobile nav */}
        <MobileNavigation experience={experience} navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
      </div>
    </div>
  );
};

export default Navigation;
