import { useLayoutEffect, useState } from 'react';

interface CustomerIconProps {
  buyerLogo?: string | null;
  buyerName: string;
}

const colors = ['#06a76c', '#07c57f', '#369DD9', '#B778DD', '#DB5DB1', '#EB6D00', '#EE9709', '#9BD045'];
/**
 * Returns a consistent color for a given string from a list of colors.
 */
function getColor(str: string): string {
  const hash = Array.from(str).reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return colors[hash % colors.length];
}

export const CustomerIcon = ({ buyerName, buyerLogo }: CustomerIconProps) => {
  const initials = buyerName
    .split(' ')
    .map((word) => word[0])
    .join('')
    .slice(0, 3);

  const backgroundColor = getColor(buyerName);

  const [verifiedBuyerLogoUrl, setVerifiedBuyerLogoUrl] = useState(buyerLogo);

  // Reset the verified buyer logo url if the buyer logo prop changes
  useLayoutEffect(() => {
    setVerifiedBuyerLogoUrl(buyerLogo);
  }, [buyerLogo]);

  if (verifiedBuyerLogoUrl) {
    return (
      <img
        src={verifiedBuyerLogoUrl}
        alt={buyerName}
        className="h-28 w-28 object-contain"
        onError={(e) => {
          // Don't show a broken image
          e.currentTarget.style.display = 'none';
          // No need to show the error again
          e.currentTarget.onerror = null;
          // Set the state to null so we can show the initials
          setVerifiedBuyerLogoUrl(null);
        }}
      />
    );
  } else {
    return (
      <div
        className="flex h-[70px] w-[70px] items-center justify-center rounded-full text-2xl"
        style={{ backgroundColor }}
      >
        {initials}
      </div>
    );
  }
};
