import { useCallback } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';
import useFeature from '@/lib/features';
import { TakerMarket } from './useTakerMarkets';

export interface FutureSchedule {
  frequency: 'one-time' | 'recurring' | 'unknown';
  rate: number;
  rateType: 'APR' | 'DISC' | 'REF';
}

export interface RecurringSchedule {
  rate: number;
  rateType: 'APR' | 'DISC' | 'REF';
  daysOfWeek?: ('Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday')[];
  daysInMonth?: number[];
  ordinalWeekdays?: {
    day: 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';
    position: 'first' | 'second' | 'third' | 'fourth' | 'last';
  }[];
}

export interface RenewalSchedule {
  renewalIntervalDays: number;
}

export interface OfferSchedule {
  status: 'pending' | 'active' | 'paused' | 'cancelled' | 'expired';
  type: 'future' | 'recurring' | 'renewal';
  schedule: FutureSchedule | RecurringSchedule | RenewalSchedule;
  marketUuid: string;
  supplierDivisionUuid: string;
  makerOrganizationUuid: string;
  timezone: string;
  startDate: string;
  endDate: string;
}

export const QUERY_KEY_OFFER_SCHEDULES = 'offer-schedules';

export const fetchOfferSchedules = async () => {
  const response = await apiClient
    .get(`api/supplier-experience/supplier-markets/offer-schedules`)
    .json<paths['/supplier-markets/offer-schedules']['get']['responses']['200']['content']['application/json']>();

  const marketClearSchedules = response.data.offerScheduleGroups
    .map((g) => g.offerSchedules)
    .flatMap((os) => os satisfies OfferSchedule[]);

  return marketClearSchedules;
};

export const useGetRecurringOfferScheduleForTakerMarket = (takerMarket: TakerMarket) => {
  const { data: offerSchedule } = useOfferSchedules(
    useCallback(
      (offerSchedules: OfferSchedule[]) => {
        return offerSchedules.find(
          (os) =>
            // Comment out tm matching to fake match a recurring schedule to a tm
            os.supplierDivisionUuid === takerMarket.takerDivisionUuid &&
            os.marketUuid === takerMarket.marketUuid &&
            os.makerOrganizationUuid === takerMarket.makerOrganizationUuid &&
            (os.status === 'active' || os.status === 'pending') &&
            os.type === 'recurring'
        );
      },
      [takerMarket]
    )
  );

  return offerSchedule;
};

const useOfferSchedules = <TData = OfferSchedule[]>(select?: (data: OfferSchedule[]) => TData) => {
  const [enableOfferScheduling] = useFeature('enterprise-ui_enableOfferScheduling');

  return useSuspenseQuery({
    queryKey: [QUERY_KEY_OFFER_SCHEDULES],
    queryFn: () => (enableOfferScheduling ? fetchOfferSchedules() : []),
    select,
  });
};

export default useOfferSchedules;
