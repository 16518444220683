import { InvoiceRulesCriteriaType, CreateRecurringRuleArgument } from '@/enterprise/data/useCreateRecurringRule';
import { RecurringRule, RecurringRuleCategory, RecurringRuleCriteria } from '@/enterprise/data/useRecurringRules';
import { RecurringRuleFormCriteriaValue, RecurringRuleFormValues } from '../RecurringRulesForm';

export type RuleRecord = Record<RecurringRuleCriteria, string>;

export const mapInvoiceRulesToRecurringRule = (
  invoiceRules: RecurringRuleFormValues['invoiceRules']
): Partial<RecurringRule> => {
  return invoiceRules.reduce((acc, curr) => {
    if (!curr.category) {
      return acc;
    }

    if (curr.category === 'invoiceId') {
      acc.excludedVoucherIds = [curr.criteria[0].value];
    } else {
      acc[curr.category] = { [curr.criteria[0].type]: curr.criteria[0].value };
    }

    return acc;
  }, {} as Partial<RecurringRule>);
};

/**
 * maps a formatted recurring rule object to an object containing arrays of form criteria by rule category
 * input = { gteDpe: 8, lteDpe: 24, lteInvoiceAmount: 1000 }
 * output = {
 *   dpe: [{ type: 'gteDpe', value: '8' }, { type: 'lteDpe', value: '24' }],
 *   amount: [{ type: 'lteInvoiceAmount', value: '1000' }]
 * }
 */
export const mapRecurringRuleToFormCriteria = (
  recurringRule: RecurringRule
): Record<RecurringRuleCategory, RecurringRuleFormCriteriaValue[]> => {
  const formCritera = {} as Record<RecurringRuleCategory, RecurringRuleFormCriteriaValue[]>;

  if (recurringRule.dpe) {
    formCritera.dpe = [
      ...(recurringRule.dpe.gteDpe ? [{ type: 'gteDpe', value: recurringRule.dpe.gteDpe.toString() }] : []),
      ...(recurringRule.dpe.lteDpe ? [{ type: 'lteDpe', value: recurringRule.dpe.lteDpe.toString() }] : []),
    ] as RecurringRuleFormCriteriaValue[];
  }

  if (recurringRule.dueDate) {
    formCritera.dueDate = [
      ...(recurringRule.dueDate.fromDueDate
        ? [{ type: 'fromDueDate', value: recurringRule.dueDate.fromDueDate.toString() }]
        : []),
      ...(recurringRule.dueDate.toDueDate
        ? [{ type: 'toDueDate', value: recurringRule.dueDate.toDueDate.toString() }]
        : []),
    ] as RecurringRuleFormCriteriaValue[];
  }

  if (recurringRule.amount) {
    formCritera.amount = [
      ...(recurringRule.amount.gteInvoiceAmount
        ? [{ type: 'gteInvoiceAmount', value: recurringRule.amount.gteInvoiceAmount.toString() }]
        : []),
      ...(recurringRule.amount.lteInvoiceAmount
        ? [{ type: 'lteInvoiceAmount', value: recurringRule.amount.lteInvoiceAmount.toString() }]
        : []),
    ] as RecurringRuleFormCriteriaValue[];
  }

  if (recurringRule.excludedVoucherIds) {
    formCritera.invoiceId = [
      ...(recurringRule.excludedVoucherIds
        ? [{ type: 'excludedVoucherIds', value: recurringRule.excludedVoucherIds.join(', ') }]
        : []),
    ] as RecurringRuleFormCriteriaValue[];
  }

  return formCritera;
};

/**
 * maps a rule record object to the format and data types expected by the SEA API
 * input = { gteDpe: '8', lteDpe: '24' }
 * output = {dpe: { gte: 8, lte: 24 }}
 */
export const mapRuleRecordToCriteria = ({
  fromCurrency,
  ruleRecord,
}: {
  fromCurrency: string;
  ruleRecord: Partial<RecurringRule>;
}): InvoiceRulesCriteriaType => {
  const criteria: InvoiceRulesCriteriaType = {};

  if (ruleRecord.dpe) {
    criteria.dpe = {
      ...(ruleRecord.dpe.lteDpe && { lte: Number(ruleRecord.dpe.lteDpe) }),
      ...(ruleRecord.dpe.gteDpe && { gte: Number(ruleRecord.dpe.gteDpe) }),
    };
  }

  if (ruleRecord.amount) {
    criteria.invoiceAmount = {
      ...(ruleRecord.amount.lteInvoiceAmount && { lte: Number(ruleRecord.amount.lteInvoiceAmount) }),
      ...(ruleRecord.amount.gteInvoiceAmount && { gte: Number(ruleRecord.amount.gteInvoiceAmount) }),
      fromCurrency: fromCurrency,
    };
  }

  if (ruleRecord.dueDate) {
    criteria.dueDate = {
      ...(ruleRecord.dueDate.fromDueDate && { from: ruleRecord.dueDate.fromDueDate }),
      ...(ruleRecord.dueDate.toDueDate && { to: ruleRecord.dueDate.toDueDate }),
    };
  }

  if (ruleRecord.excludedVoucherIds) {
    criteria.voucherIds = {
      ...(ruleRecord.excludedVoucherIds && {
        exclude: ruleRecord.excludedVoucherIds.map((id) => id.trim()),
      }),
    };
  }

  return criteria;
};

type RuleKey =
  | 'lteDpe'
  | 'gteDpe'
  | 'lteInvoiceAmount'
  | 'gteInvoiceAmount'
  | 'fromDueDate'
  | 'toDueDate'
  | 'excludedVoucherIds';

export const mapRuleArgumentToRuleArray = (rule: CreateRecurringRuleArgument): { type: RuleKey }[] => {
  const formattedRecurringRule: {
    gteDpe?: number;
    lteDpe?: number;
    fromDueDate?: string;
    toDueDate?: string;
    gteInvoiceAmount?: number;
    lteInvoiceAmount?: number;
    excludedVoucherIds?: string[];
  } = {};

  if (rule.criteria?.dpe?.gte) {
    formattedRecurringRule.gteDpe = rule.criteria.dpe.gte;
  }

  if (rule.criteria?.dpe?.lte) {
    formattedRecurringRule.lteDpe = rule.criteria.dpe.lte;
  }

  if (rule.criteria?.dueDate?.from) {
    formattedRecurringRule.fromDueDate = rule.criteria.dueDate.from;
  }

  if (rule.criteria?.dueDate?.to) {
    formattedRecurringRule.toDueDate = rule.criteria.dueDate.to;
  }

  if (rule.criteria?.invoiceAmount?.gte) {
    formattedRecurringRule.gteInvoiceAmount = rule.criteria.invoiceAmount.gte;
  }

  if (rule.criteria?.invoiceAmount?.lte) {
    formattedRecurringRule.lteInvoiceAmount = rule.criteria.invoiceAmount.lte;
  }

  if (rule.criteria?.voucherIds?.exclude) {
    formattedRecurringRule.excludedVoucherIds = rule.criteria.voucherIds.exclude;
  }

  return Object.keys(formattedRecurringRule)
    .filter((key) =>
      [
        'lteDpe',
        'gteDpe',
        'lteInvoiceAmount',
        'gteInvoiceAmount',
        'fromDueDate',
        'toDueDate',
        'excludedVoucherIds',
      ].includes(key)
    )
    .map((key) => ({ type: key as RuleKey }));
};
