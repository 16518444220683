import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { Button, ButtonLink, Chip, Modal, ModalActions, ModalContent, ModalTitleInverse } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { ArrowLeftFromLineIcon } from '@c2fo/liquidity/icons';
import { EligibleInvoiceFetchFilters } from '@/enterprise/data/useEligibleInvoices';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useLocaleFormat from '@/utils/useLocaleFormat';

const navParams = qs.stringify({
  limit: 100,
  order: 'amount',
  orderDirection: 'desc',
  page: 1,
  filter: { included: [false] } satisfies EligibleInvoiceFetchFilters,
});

const ManualExclusionChip = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const { asCurrency } = useLocaleFormat();
  const { trackEnterpriseEvent } = useReporting();
  const [enableOfferScheduling] = useFeature('enterprise-ui_enableOfferScheduling');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const {
    takerExcludedInvoiceAmount: excludedAmount,
    takerExcludedInvoiceCount: excludedCount,
    currency,
  } = takerMarket;

  if (excludedAmount <= 0) {
    return null;
  }

  const label = enableOfferScheduling ? (
    <div className="flex items-center gap-1">
      <ArrowLeftFromLineIcon className="h-4 w-4" fill={colors.warning[900]} title="Manual Exclusion Indicator" />
      <div>
        {t('invoiceSettings.excludedAmount', {
          amount: asCurrency(excludedAmount, currency),
        })}
      </div>
    </div>
  ) : (
    <div className="text-left">
      <div>{t('core.manualExclusion')}</div>
      <div>
        {t('invoiceSettings.excludedAmount', {
          amount: asCurrency(excludedAmount, currency),
        })}
      </div>
    </div>
  );

  const handleOpenModal = () => {
    setOpenModal(true);
    trackEnterpriseEvent('manual-exclusion-modal::opened', {
      takerId: takerMarket.takerDivisionId,
      marketUuid: takerMarket.marketUuid,
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Modal onClose={handleCloseModal} open={openModal} size="sm">
        <ModalTitleInverse>
          <span className="flex flex-col">
            <span>{t('core.manualExclusion')}</span>
            <span className="text-base font-normal text-text-secondary">{getTakerMarketName(takerMarket)}</span>
          </span>
        </ModalTitleInverse>
        <ModalContent>
          {excludedCount === 1 && (
            <div>
              {t('taker.divisionExcludedInvoiceDescription', {
                count: excludedCount,
                amount: asCurrency(excludedAmount, currency),
              })}
            </div>
          )}
          {excludedCount > 1 && (
            <div>
              {t('taker.divisionExcludedInvoiceDescriptionPlural', {
                count: excludedCount,
                amount: asCurrency(excludedAmount, currency),
              })}
            </div>
          )}
        </ModalContent>
        <ModalActions>
          <Button onClick={handleCloseModal} variant="secondary">
            {t('core.close')}
          </Button>
          <ButtonLink
            onClick={() => trackEnterpriseEvent('manual-exclusion-modal::redirected')}
            to={asEnterpriseLink(
              `markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible?${navParams}`
            )}
            variant="cta"
          >
            {t('core.viewExcludedInvoices')}
          </ButtonLink>
        </ModalActions>
      </Modal>
      <Chip
        label={label}
        size="xs"
        type="warning"
        variant="outlined"
        isRound={enableOfferScheduling}
        onClick={handleOpenModal}
      />
    </>
  );
};

export default ManualExclusionChip;
