import { useMutation, useQueryClient } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import useTakerMarkets, { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import apiClient from '@/lib/apiClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import useDataDog from '@/reporting/DataDog';

interface SubmitBenchmarkOfferParams {
  marketUuid: string;
  supplierDivisionUuid: string;
  expireOn?: string | null;
}

const useSubmitBenchmarkOffer = () => {
  const queryClient = useQueryClient();
  const { data: takerMarkets } = useTakerMarkets();
  const { subscribeToMarketStats } = useServerSideEventListeners();
  const { addError } = useDataDog();

  return useMutation({
    mutationKey: ['submitBenchmarkOffer'],
    mutationFn: async ({ marketUuid, supplierDivisionUuid, expireOn }: SubmitBenchmarkOfferParams) => {
      const takerMarket = takerMarkets?.find(
        (tm) => tm.marketUuid === marketUuid && tm.takerDivisionUuid === supplierDivisionUuid
      );
      const uuid = takerMarket?.offerConfig.uuid;

      if (uuid) {
        return apiClient
          .patch('api/supplier-experience/offers/benchmark', {
            json: {
              groupingKey: 'SUPPLIER_MARKET',
              status: 'active',
              offers: [
                {
                  makerOrganizationUuid: takerMarket?.makerOrganizationUuid,
                  uuid,
                },
              ],
              expirationDate: expireOn,
            } satisfies paths['/offers/benchmark']['patch']['requestBody']['content']['application/json'],
          })
          .json<paths['/offers/benchmark']['patch']['responses']['204']['content']>();
      }

      return apiClient
        .post('api/supplier-experience/offers/benchmark', {
          json: {
            groupingKey: 'SUPPLIER_MARKET',
            offerType: 'benchmark',
            frequency: 'recurring',
            supplierMarkets: [
              {
                marketUuid,
                supplierDivisionUuid,
              },
            ],
            expirationDate: expireOn ?? undefined,
          } satisfies paths['/offers/benchmark']['post']['requestBody']['content']['application/json'],
        })
        .json<paths['/offers/benchmark']['post']['responses']['201']['content']['application/json']>();
    },
    onSuccess: (_data, variables) => {
      const tmMeta = takerMarkets?.find(
        (meta) => meta.marketUuid === variables.marketUuid && meta.takerDivisionUuid === variables.supplierDivisionUuid
      );

      if (tmMeta?.marketUuid && tmMeta?.takerDivisionId) {
        // update the cache with the returned data
        queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) =>
          (prevTakerMarkets ?? []).map((tm) => ({
            ...(tm.marketUuid === variables.marketUuid && tm.takerDivisionUuid === variables.supplierDivisionUuid
              ? {
                  ...tm,
                  offerConfig: {
                    ...tm.offerConfig,
                    isEnabled: true,
                  },
                }
              : tm),
          }))
        );

        // subscribe to market stats
        // this will refetch stats queries after we know stats have updated
        subscribeToMarketStats({
          marketUuid: tmMeta.marketUuid,
          takerId: tmMeta.takerDivisionId,
        });
      }
    },
    onError: (error, variables) => {
      const takerMarket = takerMarkets?.find(
        (meta) => meta.marketUuid === variables.marketUuid && meta.takerDivisionUuid === variables.supplierDivisionUuid
      );

      addError('useSubmitBenchmarkOffer', {
        error,
        variables,
        seaOfferUuid: takerMarket?.offerConfig.uuid,
      });
    },
  });
};

export default useSubmitBenchmarkOffer;
