import { useTranslation } from 'react-i18next';
import { Button } from '@c2fo/liquidity';
import FourOFour from '@/shared/assets/404.svg?url';

const loginUrl = import.meta.env.VITE_LOGIN_URL;

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="flex min-h-screen flex-col items-center bg-canvas p-0 antialiased lg:px-8">
      <div className="flex flex-1 flex-col gap-0 space-y-12 lg:flex-row lg:items-center lg:gap-12 lg:space-y-0">
        <img src={FourOFour} alt={t('pageNotFound.heading')} className="max-w-sm px-8 pt-8 lg:p-0" />
        <div className="max-w-2xl px-8 pb-8 lg:p-0">
          <h1 className="mb-6 mt-0 text-5xl font-bold">{t('pageNotFound.heading')}</h1>
          <h5 className="mb-12 mt-0 text-xl font-normal text-text-secondary">{t('pageNotFound.message')}</h5>
          <div>
            <Button onClick={() => window.location.replace(loginUrl)}>{t('pageNotFound.cta')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
