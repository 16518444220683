import { Suspense, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@c2fo/liquidity';
import FullPageLoader from '@/shared/components/FullPageLoader';
import LinkTabs from '@/shared/components/LinkTabs';
import { CustomersModal } from './components/CustomersModal';
import { useMyRequests } from './data/useMyRequests';

const FindYourCustomer = () => {
  const { t } = useTranslation();
  const { data: requests = [] } = useMyRequests({ include: ['BUYERS_EXPANSION', 'BUYER_LINKING'] });
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [showExistingModal, setShowExistingModal] = useState(false);

  return (
    <div>
      <div className="mb-8">
        <div className="flex flex-col items-center justify-between gap-4 border-b pb-4 lg:flex-row">
          <h1 className="m-0 text-4xl">{t('findYourCustomers.title')}</h1>
          <div className="flex w-full flex-col items-center gap-2 sm:w-auto sm:flex-row">
            {requests.length > 0 && (
              <Button variant="secondary" onClick={() => setShowPendingModal(true)} className="w-full sm:w-auto">
                {t('findYourCustomers.requestedCustomers', { count: requests.length })}
              </Button>
            )}
            <Button variant="primary" onClick={() => setShowExistingModal(true)} className="w-full sm:w-auto">
              {t('findYourCustomers.existingCustomers')}
            </Button>
          </div>
        </div>
        <div className=" w-full border-b">
          <div className="mx-auto max-w-screen-2xl">
            <LinkTabs
              items={[
                {
                  key: 'expandYourNetwork',
                  label: t('findYourCustomers.expandYourNetwork'),
                  link: 'expand-your-network',
                  matcher: 'find-your-customers/expand-your-network',
                },
                {
                  key: 'requestNewCustomers',
                  label: t('findYourCustomers.requestNewCustomers'),
                  link: 'request-new-customers',
                  matcher: 'find-your-customers/request-new-customers',
                },
              ]}
              variant="secondary"
            />
          </div>
        </div>
        <div>
          <Suspense fallback={<FullPageLoader />}>
            <Outlet />
          </Suspense>
          <CustomersModal type="PENDING" show={showPendingModal} setShow={setShowPendingModal} />
          <CustomersModal type="EXISTING" show={showExistingModal} setShow={setShowExistingModal} />
        </div>
      </div>
    </div>
  );
};

export default FindYourCustomer;
