import { Trans, useTranslation } from 'react-i18next';
import { Label } from '@c2fo/liquidity';
import { InvoiceRulesOperatorType } from '@/enterprise/data/useCreateRecurringRule';
import useRecurringRuleFormContext from '@/enterprise/features/recurringRules/utils/useRecurringRuleFormContext';
import CriteriaExampleTable from './CriteriaExampleTable';

const operatorTypeOptions: {
  translationKey: string;
  value: InvoiceRulesOperatorType;
}[] = [
  {
    translationKey: 'recurringRules.anyOfOptionLabel',
    value: 'OR',
  },
  {
    translationKey: 'recurringRules.allOfOptionLabel',
    value: 'AND',
  },
];

const CriteriaBehaviorSelect = () => {
  const { t } = useTranslation();
  const { register, watch } = useRecurringRuleFormContext();

  const operatorType = watch('operatorType');

  return (
    <>
      <div className="space-y-4">
        <div className="text-sm">{t('recurringRules.selectHowYouWouldLikeYourRuleToBehave')}</div>
        <div className="ml-2 flex flex-col items-start space-y-1">
          {operatorTypeOptions.map((option) => (
            <div key={option.value} className="flex items-center gap-2">
              <input
                {...register('operatorType')}
                className="h-4 w-4 cursor-pointer accent-primary-500 focus:ring-primary-500"
                id={option.value}
                type="radio"
                value={option.value}
              />
              <Label id={option.value} htmlFor={option.value} className="font-normal">
                <Trans i18nKey={option.translationKey} components={{ style: <span className="font-bold" /> }} />
              </Label>
            </div>
          ))}
        </div>
      </div>
      <CriteriaExampleTable operatorType={operatorType} />
    </>
  );
};

export default CriteriaBehaviorSelect;
