import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import {
  Button,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  IconButton,
  Label,
  Select,
  SelectContent,
  SelectFilterTrigger,
  SelectItem,
  SelectValue,
  TextInput,
  ButtonLink,
} from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { FilterIcon, FilterVariantIcon, SearchIcon, TagIcon, TimesIcon } from '@c2fo/liquidity/icons';
import { useReporting } from '@/reporting';
import beeLineSvg from '../assets/bee-line.svg';
import conversationSvg from '../assets/conversation.svg?url';
import networkSvg from '../assets/network.svg?url';
import useBuyers, { Buyer } from '../data/useBuyers';
import useBuyersLinkingRequests from '../data/useBuyersLinkingRequests';
import { CardLayoutGrid } from './CardLayoutGrid';
import { CustomerCard } from './CustomerCard';
import { LinkToYourCustomersModal } from './LinkToYourCustomersModal';
import { SuccessModal } from './SuccessModal';

type Filter = 'ALL' | 'ASSOCIATED' | 'REQUESTED' | 'NO TAG';

interface SelectableCustomer extends Buyer {
  isRequested: boolean;
}

const sortFnAsc = (a: { buyerName: string }, b: { buyerName: string }) => a.buyerName.localeCompare(b.buyerName);
const sortFnDesc = (a: { buyerName: string }, b: { buyerName: string }) => b.buyerName.localeCompare(a.buyerName);

export const ExpandYourNetwork = () => {
  const { trackMultiExperienceEvent } = useReporting();
  const { data: allLinkingRequests } = useBuyersLinkingRequests();
  const { data: allCustomers } = useBuyers((data) => {
    return data.map((buyer) => {
      const matchingLinkingRequest = allLinkingRequests?.find((request) => request.buyerUuid === buyer.uuid);
      return {
        ...buyer,
        isRequested: !!matchingLinkingRequest,
      };
    });
  });
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState<'ASC' | 'DESC'>('ASC');
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [filter, setFilter] = useState<Filter>('ALL');
  const { t } = useTranslation();

  const sortedAndFilteredCustomers: SelectableCustomer[] = useMemo(() => {
    if (!allCustomers) return [];

    const associatedCustomers = allCustomers.filter((customer) => customer.isAssociated);
    const requestedCustomers = allCustomers.filter((customer) => customer.isRequested && !customer.isAssociated);
    const allOtherCustomers = allCustomers.filter((customer) => !customer.isAssociated && !customer.isRequested);

    const selectedSortFn = sort === 'ASC' ? sortFnAsc : sortFnDesc;

    let filteredCustomers: SelectableCustomer[] = [];

    switch (filter) {
      case 'ASSOCIATED':
        filteredCustomers = associatedCustomers.sort(selectedSortFn);
        break;
      case 'REQUESTED':
        filteredCustomers = requestedCustomers.sort(selectedSortFn);
        break;
      case 'NO TAG':
        filteredCustomers = allOtherCustomers.sort(selectedSortFn);
        break;
      case 'ALL':
        const existingAndRequested = [...associatedCustomers, ...requestedCustomers].sort(selectedSortFn);
        filteredCustomers = [...existingAndRequested, ...allOtherCustomers.sort(selectedSortFn)];
        break;
    }

    // Filter again by search term
    return filteredCustomers.filter((customer) => customer.buyerName.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [allCustomers, filter, searchTerm, sort]);

  const isNoCustomersFound = sortedAndFilteredCustomers.length === 0;

  const onCustomerSelect = (uuid: string) => {
    const customer = sortedAndFilteredCustomers?.find((customer) => customer.uuid === uuid);
    const isAlreadyAssociated = customer?.isAssociated ?? false;
    if (selectedCustomers.includes(uuid)) {
      setSelectedCustomers((prevSelected) => prevSelected.filter((id) => id !== uuid));
      return;
    } else if (selectedCustomers.length < 5 && !isAlreadyAssociated) {
      setSelectedCustomers((prevSelected) => [...prevSelected, uuid]);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useDebounce(
    () => {
      if (searchTerm) {
        trackMultiExperienceEvent('find-your-customers::link-your-customers::searched', { searchTerm });
      }
    },
    2000,
    [searchTerm]
  );

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleChangeFilter = (value: Filter) => {
    setFilter(value);
    trackMultiExperienceEvent('find-your-customers::link-your-customers::filtered', { filter: value });
  };

  const handleChangeSort = (value: 'ASC' | 'DESC') => {
    setSort(value);
    trackMultiExperienceEvent('find-your-customers::link-your-customers::sorted', { sort: value });
  };

  const isSelected = (uuid: string) => selectedCustomers.includes(uuid);

  const onLinkCustomers = () => {
    setShowModal(true);
  };

  const onLinkCustomersSuccess = () => {
    setShowSuccessModal(true);

    // Reset any selection, sorting, or filtering state
    setSelectedCustomers([]);
    setSearchTerm('');
    setSort('ASC');
    setFilter('ALL');
  };

  const filters: { key: Filter; label: string }[] = [
    { key: 'ALL', label: t('findYourCustomers.filter.all') },
    { key: 'ASSOCIATED', label: t('findYourCustomers.filter.associated') },
    { key: 'REQUESTED', label: t('findYourCustomers.filter.requested') },
    { key: 'NO TAG', label: t('findYourCustomers.filter.noTag') },
  ];

  return (
    <>
      <div className="py-6">
        <div className="flex flex-col gap-6">
          <div className="relative flex w-full overflow-hidden rounded-lg bg-primary-500 p-8 text-white">
            <div className="hidden items-center justify-center md:w-[35%] lg:flex">
              <img src={networkSvg} alt="Lines connecting, forming a network" className="h-64" />
            </div>
            <div className="z-[1] flex flex-col items-start justify-center gap-6 lg:max-w-[45%]">
              <div className="flex flex-col items-start justify-center gap-2">
                <h3 className="m-0 p-0 font-serif text-4xl font-normal">{t('findYourCustomers.expandYourNetwork')}</h3>
                <Label className="m-0 p-0 text-base font-medium" htmlFor="search-customers">
                  {t('findYourCustomers.search.label')}
                </Label>
              </div>
              <div className="relative w-full">
                <TextInput
                  id="search-customers"
                  startIcon={SearchIcon}
                  placeholder="Costco"
                  className="w-full text-text-primary [&>input]:rounded-full [&>input]:pl-12"
                  onChange={handleSearch}
                  value={searchTerm}
                />
                <div className={cn('absolute right-2 top-[50%] z-10 translate-y-[-50%]', { hidden: !searchTerm })}>
                  <IconButton
                    icon={TimesIcon}
                    size="sm"
                    name={t('findYourCustomers.clearSearch')}
                    variant="ancillary"
                    onClick={handleClearSearch}
                  />
                </div>
              </div>
            </div>
            <img src={beeLineSvg} alt="" className="absolute bottom-[-2px] right-0 z-0 hidden h-full lg:block" />
          </div>

          <div className="flex flex-col gap-1">
            <h2 className="m-0 p-0 font-serif text-2xl font-normal">{t('findYourCustomers.customers.title')}</h2>
            <p className="m-0 p-0 text-sm text-text-secondary">{t('findYourCustomers.customers.description')}</p>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <FilterIcon className="h-5 w-5 shrink-0" fill={colors.text.secondary} />

              <Select onValueChange={(value) => handleChangeFilter(value as Filter)} value={filter}>
                <SelectFilterTrigger
                  icon={TagIcon}
                  iconFill="fill-primary-500"
                  label={t('findYourCustomers.filter.label')}
                  size="sm"
                  className="w-fit min-w-48"
                >
                  <SelectValue />
                </SelectFilterTrigger>
                <SelectContent>
                  {filters.map((item) => (
                    <SelectItem value={item.key} key={item.key}>
                      {item.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <IconButton
                  icon={FilterVariantIcon}
                  size="sm"
                  name={t('findYourCustomers.sort.label')}
                  variant="ancillary"
                  className="[&>svg]:w-5"
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" avoidCollisions={false} className="min-w-64" collisionPadding={24}>
                <DropdownMenuLabel>{t('findYourCustomers.sort.label')}</DropdownMenuLabel>
                <DropdownMenuItem selected={sort === 'ASC'} onClick={() => handleChangeSort('ASC')}>
                  {t('findYourCustomers.sort.ascending')}
                </DropdownMenuItem>
                <DropdownMenuItem selected={sort === 'DESC'} onClick={() => handleChangeSort('DESC')}>
                  {t('findYourCustomers.sort.descending')}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          <p className="m-0 p-0 text-sm font-medium italic">
            {t('findYourCustomers.filter.returningResults', { count: sortedAndFilteredCustomers.length })}
          </p>

          <CardLayoutGrid>
            {sortedAndFilteredCustomers?.map((customer) => (
              <CustomerCard
                key={customer.uuid}
                customerName={customer.buyerName}
                customerLogoUrl={customer.buyerLogo}
                onClick={
                  customer.isAssociated || customer.isRequested ? undefined : () => onCustomerSelect(customer.uuid)
                }
                isSelected={isSelected(customer.uuid)}
                chipLabelText={
                  customer.isAssociated ? t('core.associated') : customer.isRequested ? t('core.requested') : undefined
                }
              />
            ))}
          </CardLayoutGrid>

          {isNoCustomersFound && (
            <div className="flex w-full flex-col items-center justify-center gap-4">
              <img src={conversationSvg} alt="" className="h-52" />
              <div className="text-center">
                <h4 className="m-0 p-0 text-base">{t('findYourCustomers.search.notFound.title')}</h4>
                <p className="m-0 p-0">{t('findYourCustomers.search.notFound.description')}</p>
              </div>
              <ButtonLink
                variant="cta"
                size="md"
                to="../request-new-customers"
                state={{ isNewCustomerModalOpen: true }}
              >
                {t('findYourCustomers.requestNewCustomers')}
              </ButtonLink>
            </div>
          )}
        </div>
      </div>

      <Suspense fallback={null}>
        <LinkToYourCustomersModal
          onSuccess={onLinkCustomersSuccess}
          open={showModal}
          setOpen={setShowModal}
          selectedCustomerUuids={selectedCustomers}
        />
      </Suspense>

      <SuccessModal
        isOpen={showSuccessModal}
        setIsOpen={setShowSuccessModal}
        title={t('findYourCustomers.linking.success.title')}
        content={t('findYourCustomers.linking.success.description')}
      />

      <div
        className={cn('fixed bottom-0 right-0 z-10 w-full', {
          hidden: selectedCustomers.length === 0,
        })}
      >
        <div className="container mx-auto flex items-center justify-center p-6 px-8 md:justify-end">
          <Button variant="cta" size="lg" onClick={onLinkCustomers}>
            {t('findYourCustomers.linking.button', { count: selectedCustomers.length })}
          </Button>
        </div>
      </div>
    </>
  );
};
