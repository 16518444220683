import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Toggle, useSnackbar } from '@c2fo/liquidity';
import OfferScheduleToggle from '@/enterprise/components/OfferScheduleToggle';
import { useIsAgreementRequired } from '@/enterprise/data/useAgreements';
import { useGetRecurringOfferScheduleForTakerMarket } from '@/enterprise/data/useOfferSchedules';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import useToggleOffer, { ToggleOfferParams } from '@/enterprise/data/useToggleOffer';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import useRestrictions from '@/utils/useRestrictions';
import OfferModal from '../offerModal/OfferModal';
import NameYourRateSetOffer from './SetOffer';

const NameYourRateParticipationToggle = ({
  disabled = false,
  takerMarket,
  referrer,
}: {
  disabled?: boolean;
  takerMarket: TakerMarket;
  referrer: 'market' | 'division';
}) => {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const showSnackbar = useSnackbar();
  const { getRestrictions } = useRestrictions();
  const { canEditOffers } = getRestrictions([takerMarket]);
  const { mutateAsync: toggleOffer, isPending: toggleOfferIsPending } = useToggleOffer();
  const isAgreementRequired = useIsAgreementRequired([takerMarket.marketUuid]);
  const [isReviewOfferModalOpen, setIsReviewOfferModalOpen] = useState(false);
  const [enableOfferScheduling] = useFeature('enterprise-ui_enableOfferScheduling');
  const hasOfferSchedule = !!useGetRecurringOfferScheduleForTakerMarket(takerMarket);

  const isDisabledDueToClosedMarket = !takerMarket.offerConfig.isEnabled && takerMarket.marketIsEnabled === false;
  const toggleDisabled = disabled || !canEditOffers || toggleOfferIsPending || isDisabledDueToClosedMarket;
  const variant = referrer === 'division' ? 'light' : 'dark';

  const onClick = async () => {
    // When a market has custom terms, the user must always go through the submit flow and accept them.
    if (isAgreementRequired && !takerMarket.offerConfig.isEnabled) {
      return setIsReviewOfferModalOpen(true);
    }

    const submitValues = {
      marketUuid: takerMarket.offerConfig.marketUuid,
      supplierDivisionUuid: takerMarket.takerDivisionUuid,
      seaUuid: takerMarket.offerConfig.uuid ?? undefined,
      frequency: takerMarket.offerConfig.frequency,
    } satisfies ToggleOfferParams;

    return await toggleOffer(submitValues, {
      onSuccess: () => {
        showSnackbar({ message: t('offerSubmitDialog.participationUpdated') });
        trackEnterpriseEvent('participation::submitted', {
          ...submitValues,
          offerConfig: {
            ...takerMarket.offerConfig,
            isEnabled: !takerMarket.offerConfig.isEnabled,
          },
        });
        setIsReviewOfferModalOpen(false);
      },
      onError: () => showSnackbar({ message: t('offerSubmitDialog.participationError') }),
    });
  };

  return (
    <>
      {enableOfferScheduling ? (
        <OfferModal
          open={isReviewOfferModalOpen}
          onClose={() => setIsReviewOfferModalOpen(false)}
          takerMarket={takerMarket}
          initialStep="review"
        />
      ) : (
        <NameYourRateSetOffer
          open={isReviewOfferModalOpen}
          onClose={() => setIsReviewOfferModalOpen(false)}
          takerMarket={takerMarket}
          showReviewStep
        />
      )}
      {hasOfferSchedule ? (
        <OfferScheduleToggle isEnabled={takerMarket.offerConfig.isEnabled ?? false} variant={variant} />
      ) : (
        <Toggle
          accessibilityLabel={t('core.toggleParticipation')}
          checked={takerMarket.offerConfig.isEnabled ?? false}
          disabled={toggleDisabled}
          loading={toggleOfferIsPending}
          onCheckedChange={onClick}
          size="lg"
        />
      )}
    </>
  );
};

export default NameYourRateParticipationToggle;
