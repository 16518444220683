import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, cn } from '@c2fo/liquidity';
import { TakerMarketWithAutomation } from '@/enterprise/data/useAutomations';
import { buildCadenceFromRecurringSchedule, buildCustomScheduleSentence } from '../../offerModal/utils';

const MiniScheduleManager = ({
  asChild = false,
  takerMarketWithAutomation,
}: {
  asChild?: boolean;
  takerMarketWithAutomation: TakerMarketWithAutomation;
}) => {
  const { automation } = takerMarketWithAutomation;
  const { t } = useTranslation();

  const chipLabel = useMemo(() => {
    if (!automation.offerSchedule) {
      return t('core.na');
    }

    const schedule = automation.offerSchedule.schedule;
    const cadence = buildCadenceFromRecurringSchedule(schedule) ?? { type: 'daily' };
    return buildCustomScheduleSentence({
      startDate: automation.offerSchedule.startDate,
      endDate: automation.offerSchedule.endDate,
      cadence,
      t,
    });
  }, [automation.offerSchedule, t]);

  return (
    <>
      <div
        className={cn('flex items-center justify-between gap-10 rounded bg-canvas p-4', {
          'flex-wrap gap-2 md:flex-nowrap md:gap-10': !asChild,
        })}
      >
        <div className="space-y-2">
          <div className={cn('text-sm font-medium text-text-secondary')}>{t('scheduling.participateOnSchedule')}</div>
          {!!automation.offerSchedule && <Chip size="sm" type="secondary" variant="outlined" label={chipLabel} />}
        </div>
        <div className="flex items-center gap-1">
          {/* TODO: SUPP-16048 - Hook up action buttons */}
          {/* <IconButton
            disabled={!canEditRecurringRules}
            icon={PencilIcon}
            name={t('recurringRules.editRule')}
            onClick={() => null}
            size="sm"
            variant="ancillary"
          />
          <Divider vertical />
          <IconButton
            className="bg-transparent"
            disabled={!canEditRecurringRules}
            icon={TrashCanIcon}
            inverse
            name={t('recurringRules.removeRule')}
            onClick={handleDeleteModalOpen}
            size="sm"
            variant="destructive"
          /> */}
        </div>
      </div>
    </>
  );
};

export default MiniScheduleManager;
