import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalActions, ModalContent, ModalTitleInfo } from '@c2fo/liquidity';
import { useIntercom } from '@/reporting/Intercom';
import useBuyers from '../data/useBuyers';
import { useMyRequests } from '../data/useMyRequests';
import { CustomerCard } from './CustomerCard';

interface CustomersModalProps {
  type: 'PENDING' | 'EXISTING';
  show: boolean;
  setShow: (show: boolean) => void;
}

export const CustomersModal = ({ type, show, setShow }: CustomersModalProps) => {
  const { t } = useTranslation();
  const { data: requests = [] } = useMyRequests({ include: ['BUYERS_EXPANSION', 'BUYER_LINKING'] });
  const { data: allBuyers = [] } = useBuyers();
  const { openChat } = useIntercom();

  const existingCustomers = useMemo(() => {
    return allBuyers
      .filter((buyer) => buyer.isAssociated)
      .map((buyer) => ({
        uuid: buyer.uuid,
        name: buyer.buyerName,
        logoUrl: buyer.buyerLogo,
      }));
  }, [allBuyers]);

  return (
    <Modal open={show} onClose={() => setShow(false)} className="sm:w-[800px]">
      <ModalTitleInfo>
        {type === 'PENDING'
          ? t('findYourCustomers.customersModal.requested.title')
          : t('findYourCustomers.customersModal.existing.title')}
      </ModalTitleInfo>
      <ModalContent>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          {type === 'PENDING'
            ? requests.map(({ uuid, ...props }) => <CustomerCard key={uuid} {...props} />)
            : existingCustomers.map((item) => (
                <CustomerCard key={item.uuid} customerName={item.name} customerLogoUrl={item.logoUrl} />
              ))}
        </div>
      </ModalContent>
      <ModalActions className="sm:justify-end">
        <Button className="shrink hover:bg-transparent" variant="ancillary" onClick={() => openChat()}>
          {t('core.contactUs')}
        </Button>
        <Button className="shrink" variant="secondary" onClick={() => setShow(false)}>
          {t('core.close')}
        </Button>
      </ModalActions>
    </Modal>
  );
};
