import { useSuspenseQuery } from '@tanstack/react-query';
import qs from 'qs';
import { EarlyPaySupplierStatsDeepFilter, OfferRateUnion, Pricing } from '@/generated/gql/graphql';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';
import queryClient from '@/lib/queryClient';
import { ExchangeRate } from '@/shared/data/useExchangeRates';

type OfferFrequency = 'ONE_TIME' | 'RECURRING' | undefined;

export interface OfferConfig {
  id?: number | null;
  uuid?: string | null;
  divisionId: number;
  exclusionSettings: {
    excludeNewInvoices?: boolean | null;
  };
  isReferenceRateBidding?: boolean | null;
  isDiscountBidding?: boolean | null;
  isEnabled?: boolean;
  marketId: string;
  marketUuid: string;
  maxApr?: number | null;
  maxDiscount?: number | null;
  offerType?: string | null;
  type?: string | null;
  frequency?: OfferFrequency;
  isPreferredRenewalRequested?: boolean | null;
}

type RateInfo = OfferRateUnion | null | undefined;

type SEAMarketResponse = paths['/supplier-markets']['get']['responses']['200']['content']['application/json'];
type SEAMarketOfferType =
  SEAMarketResponse['data']['supplierMarketGroups'][0]['supplierMarkets'][0]['offer']['offerType'];
type SEAMarketPricingType = SEAMarketResponse['data']['supplierMarketGroups'][0]['meta']['pricingType'];
type SEAMarketParams = paths['/supplier-markets']['get']['parameters']['query'];

type SEAOfferResponse = paths['/offers']['get']['responses']['200']['content']['application/json'];
type SEAOfferParams = paths['/offers']['get']['parameters']['query'];

type SEASupplierParams = paths['/user/suppliers']['get']['parameters']['query'];

export type SEAMarketGroup = SEAMarketResponse['data']['supplierMarketGroups'];
export type SEATakerMarket = SEAMarketGroup[number];

type SEAOfferGroup = SEAOfferResponse['data'];
export type SEAOffer = SEAOfferGroup[0];

export type SEAUserSupplierResponse =
  paths['/user/suppliers']['get']['responses']['200']['content']['application/json'];
type SEAUserSupplier = SEAUserSupplierResponse['data']['suppliers'][0];

type SEAPricingSuggestionResponse =
  paths['/pricing-suggestions']['post']['responses']['200']['content']['application/json'];

type SEAPricingSuggestionRequestBody =
  paths['/pricing-suggestions']['post']['requestBody']['content']['application/json'];

export type SEAPricingSuggestionSupplierMarket = SEAPricingSuggestionResponse['data']['supplierMarketGroups'][number];

export interface TakerMarket {
  id: string; // not based on c2fo db, just a unique id generated client side for taker market rows
  acceptedDpeWeightedSum: number;
  acceptedEarn: number;
  acceptedInvoiceAmount: number;
  acceptedInvoiceCount: number;
  configExclusionSettings?: {
    excludeNewInvoices?: boolean;
  };
  configId?: number | null;
  configIsDiscountBidding?: boolean;
  configIsEnabled?: boolean;
  configMaxApr?: number | null;
  configMaxDiscount?: number | null;
  currency: string;
  disableAfterMarketClearsDate?: string | null;
  eligibleDpeWeightedAvg: number;
  eligibleDpeWeightedSum: number;
  eligibleInvoiceAmount: number;
  eligibleInvoiceAmountUsd: number;
  eligibleInvoiceCount: number;
  isDiscountMarket: boolean;
  isTpf?: boolean;
  lastClearDate?: string;
  legacyMarketId: number;
  makerDivisionName: string;
  makerDivisionId: number;
  makerOrganizationName: string;
  makerOrganizationUuid: string;
  marketId: string;
  marketIsEnabled: boolean;
  marketMaxApr?: number | null;
  marketMaxDiscount?: number | null;
  marketNextClearTime: string | null;
  marketPayDate: string;
  marketPricingType?: Pricing | null;
  marketFeatures: string[];
  staticMarketRate?: number | null;
  marketType?: string;
  marketUuid: string;
  notAcceptedDpeWeightedAvg: number;
  notAcceptedDpeWeightedSum: number;
  notAcceptedInvoiceAmount: number;
  notAcceptedInvoiceCount: number;
  offerConfig: OfferConfig;
  rateInfo?: RateInfo;
  takerDivisionId: number;
  takerDivisionUuid: string;
  takerDivisionName: string;
  takerExcludedInvoiceAmount: number;
  takerExcludedInvoiceCount: number;
  takerMakerId: string;
  userAddedToDivision?: string;
}

const getSEASupplierMarketRateInfo = (
  pricingSuggestion?: SEAPricingSuggestionSupplierMarket,
  offer?: SEAOffer
): RateInfo => {
  const rateInfo: RateInfo = null;

  if (offer?.rateInformation) {
    if (offer.rateInformation.type === 'benchmark') {
      return {
        __typename: 'BenchmarkRateInfo',
        estimatedRate: offer.rateInformation.rate,
        rateName: offer.rateInformation.rateName,
        referenceRate: offer.rateInformation.referenceRate ?? 0,
        totalSpread: offer.rateInformation.spread,
      };
    } else if (
      offer.rateInformation.type === 'preferred' &&
      offer.rateInformation.rateType === 'REF' &&
      'rateName' in offer.rateInformation
    ) {
      return {
        __typename: 'PreferredRateInfo',
        rateSourceName: offer.rateInformation.rateName,
        referenceRate: offer.rateInformation.referenceRate ?? 0,
        totalRate: offer.rateInformation.rate,
        spread: offer.rateInformation.spread,
      };
    } else if (offer.rateInformation.type === 'preferred' && 'rateName' in offer.rateInformation) {
      return {
        __typename: 'PreferredRateInfo',
        rateSourceName: offer.rateInformation.rateName,
        referenceRate: 0,
        totalRate: offer.rateInformation.rate,
      };
    }
  }

  if (pricingSuggestion && pricingSuggestion.rates.length > 0) {
    const benchmarkRateInfo = pricingSuggestion.rates
      .find((rate) => {
        return rate.rateInformationType === 'benchmark';
      })
      ?.rates?.find((rate) => {
        return rate.type === 'benchmark';
      });

    const preferredRateInfo = pricingSuggestion.rates
      .find((rate) => {
        return rate.rateInformationType === 'preferred';
      })
      ?.rates?.find((rate) => {
        return rate.type === 'preferred';
      });

    if (preferredRateInfo && 'rateName' in preferredRateInfo) {
      return {
        __typename: 'PreferredRateInfo',
        rateSourceName: preferredRateInfo.rateName,
        referenceRate: preferredRateInfo.referenceRate ?? 0,
        totalRate: preferredRateInfo.rate,
        spread: preferredRateInfo.spread,
      };
    }

    if (benchmarkRateInfo) {
      return {
        __typename: 'BenchmarkRateInfo',
        estimatedRate: benchmarkRateInfo.rate,
        rateName: benchmarkRateInfo.rateName,
        referenceRate: benchmarkRateInfo.referenceRate ?? 0,
        totalSpread: benchmarkRateInfo.spread,
      };
    }
  }

  return rateInfo;
};

const convertSEAPricing = (pricing: SEAMarketPricingType): Pricing | null => {
  switch (pricing) {
    case 'price-discovery':
      return 'PRICE_DISCOVERY';
    case 'static':
      return 'STATIC';
    case 'benchmark':
      return 'BENCHMARK';
    default:
      return null;
  }
};

const convertSEAOfferType = (offerType: SEAMarketOfferType): string | undefined => {
  switch (offerType) {
    case 'preferred-term':
      return 'PREFERRED_TERM';
    default:
      return undefined;
  }
};

const convertSEAOfferFrequency = (frequency?: SEAOfferResponse['data'][0]['frequency']): OfferFrequency => {
  switch (frequency) {
    case 'one-time':
      return 'ONE_TIME';
    case 'recurring':
      return 'RECURRING';
    default:
      return undefined;
  }
};

const formatSEASupplierMarkets = (
  supplierMarkets: SEAMarketGroup,
  offers: SEAOfferGroup,
  userSuppliers: SEAUserSupplier[],
  pricingSuggestions: SEAPricingSuggestionSupplierMarket[],
  usdExchangeRates?: ExchangeRate
): TakerMarket[] => {
  // Grouping defaults to SUPPLIER_MARKET, assume one TM per group
  return supplierMarkets.map((supplierMarket) => {
    const sm = supplierMarket.supplierMarkets[0];

    let exchangeRate = usdExchangeRates?.[sm.market.currency] ?? 1;

    const pricingSuggestion = pricingSuggestions.find((suggestion) => {
      return (
        suggestion.supplierMarkets[0].marketUuid === sm.market.uuid &&
        suggestion.supplierMarkets[0].supplierDivisionUuid === sm.supplier.uuid
      );
    });

    const staticMarketRate = pricingSuggestion?.rates
      .find((rate) => {
        return rate.rateInformationType === 'static';
      })
      ?.rates?.find((rate) => {
        return rate.type === 'static';
      });

    if (exchangeRate === 0) {
      exchangeRate = 1;
    }

    const seaOffer = offers.find((offer) => {
      return (
        offer.supplierMarkets[0].marketUuid === sm.market.uuid &&
        offer.supplierMarkets[0].supplierDivisionUuid === sm.supplier.uuid
      );
    });

    const userSupplier = userSuppliers.find((supplier) => {
      return supplier.uuid === sm.supplier.uuid;
    });

    return {
      id: `${sm.market.uuid},${sm.supplier.uuid}`,
      acceptedDpeWeightedSum: seaOffer?.stats?.invoice.accepted.dpeWeightedSum ?? 0, //takerMarket.accepted.dpeWeightedSum,
      acceptedEarn: seaOffer?.stats?.invoice.accepted.earn ?? 0, //takerMarket.accepted.earn,
      acceptedInvoiceAmount: seaOffer?.stats?.invoice.accepted.amount ?? 0, //takerMarket.accepted.invoiceAmount,
      acceptedInvoiceCount: seaOffer?.stats?.invoice.accepted.count ?? 0, //takerMarket.accepted.invoiceCount,
      currency: sm.market.currency, //takerMarket.market!.currency,
      disableAfterMarketClearsDate: seaOffer?.expirationDate, //takerMarket.offer?.disableAfterMarketClearsDate,
      eligibleDpeWeightedAvg: sm.stats?.invoice?.eligible.dpeWeightedAverage ?? 0, //takerMarket.eligible.dpeWeightedAvg,
      eligibleDpeWeightedSum: sm.stats?.invoice?.eligible.dpeWeightedSum ?? 0, //takerMarket.eligible.dpeWeightedSum,
      eligibleInvoiceAmount: sm.stats?.invoice?.eligible.amount ?? 0, //takerMarket.eligible.invoiceAmount,
      eligibleInvoiceAmountUsd: (sm.stats?.invoice?.eligible.amount ?? 0) / exchangeRate, //takerMarket.eligible.invoiceAmount / exchangeRate,
      eligibleInvoiceCount: sm.stats?.invoice?.eligible.count ?? 0, //takerMarket.eligible.invoiceCount,
      isDiscountMarket: sm.market.isDiscountOnly, //takerMarket.market!.isDiscountOnly,
      legacyMarketId: sm.market.id, //takerMarket.market!.id,
      makerDivisionId: sm.market.makerDivisionId!, //takerMarket.market!.buyerDivision!.id,
      makerDivisionName: sm.market.makerDivisionName!, //takerMarket.market!.buyerDivision!.name,
      makerOrganizationName: supplierMarket.meta.details.makerDisplayName!, //takerMarket.market!.buyerDivision!.organization.name,
      makerOrganizationUuid: sm.market.makerOrganizationUuid, //takerMarket.market!.buyerDivision!.organization.uuid,
      marketFeatures: sm.market.isPreferredEligible ? ['PREFERRED_ELIGIBLE'] : [], //takerMarket.market?.features.map((feature) => feature.feature) ?? [],
      marketMaxApr: sm.market.maxApr, //takerMarket.market!.maxApr,
      marketMaxDiscount: sm.market.maxDiscount, //takerMarket.market!.maxDiscount,
      marketId: sm.market.uuid, //takerMarket.market!.uuid,
      marketIsEnabled: sm.market.enabled, //takerMarket.market!.isEnabled,
      marketNextClearTime: sm.market.nextClearTime, //takerMarket.market!.nextClearTime,
      marketPayDate: sm.market.payDate!, //takerMarket.market!.payDate,
      marketPricingType: convertSEAPricing(supplierMarket.meta.pricingType), //takerMarket.market?.pricing?.type,
      staticMarketRate: staticMarketRate?.rate, //takerMarket.market?.pricing && getMarketPricingInformation(takerMarket.market?.pricing),
      marketType: sm.market.type ?? undefined, //takerMarket.market?.marketType,
      marketUuid: sm.market.uuid, //takerMarket.market!.uuid,
      notAcceptedDpeWeightedAvg: seaOffer?.stats?.invoice.notAccepted.dpeWeightedAverage ?? 0, //takerMarket.notAccepted.dpeWeightedAvg,
      notAcceptedDpeWeightedSum: seaOffer?.stats?.invoice.notAccepted.dpeWeightedSum ?? 0, //takerMarket.notAccepted.dpeWeightedSum,
      notAcceptedInvoiceAmount: seaOffer?.stats?.invoice.notAccepted.amount ?? 0, //takerMarket.notAccepted.invoiceAmount,
      notAcceptedInvoiceCount: seaOffer?.stats?.invoice.notAccepted.count ?? 0, //takerMarket.notAccepted.invoiceCount,
      offerConfig: {
        id: seaOffer?.supplierMarkets[0].takerConfigurationId, //takerMarket.offer?.id,
        uuid: seaOffer?.uuid,
        isEnabled: seaOffer?.status === 'active', //takerMarket.offer?.isEnabled,
        maxApr: seaOffer?.rateInformation?.rateType === 'APR' ? seaOffer?.rateInformation?.rate : null, //takerMarket.offer?.maxApr,
        maxDiscount: seaOffer?.rateInformation?.rateType === 'DISC' ? seaOffer?.rateInformation?.rate : null, //takerMarket.offer?.maxDiscount,
        divisionId: Number(sm.supplier.id), //takerMarket.supplierDivision!.id,
        isReferenceRateBidding: seaOffer?.rateInformation?.rateType === 'REF', //takerMarket.offer?.isReferenceRateBidding,
        isDiscountBidding: seaOffer?.rateInformation?.rateType === 'DISC', //takerMarket.offer?.isDiscountBidding,
        exclusionSettings: {
          excludeNewInvoices: seaOffer?.excludeNewInvoices,
        }, //takerMarket.offer?.exclusionSettings ? takerMarket.offer.exclusionSettings : {},
        marketId: sm.market.uuid, //takerMarket.market!.uuid,
        marketUuid: sm.market.uuid, //takerMarket.market!.uuid,
        type: convertSEAPricing(supplierMarket.meta.pricingType), //takerMarket.market?.pricing?.type,
        offerType: convertSEAOfferType(sm.offer.offerType), //takerMarket.offer?.offerType,
        frequency: convertSEAOfferFrequency(seaOffer?.frequency),
        isPreferredRenewalRequested:
          seaOffer?.supplierMarkets &&
          seaOffer?.supplierMarkets.length > 0 &&
          seaOffer?.supplierMarkets.every((sm) => sm.actions.isPreferredRenewalRequested),
      },
      rateInfo: getSEASupplierMarketRateInfo(pricingSuggestion, seaOffer), //getTakerMarketRateInfo(takerMarket),
      takerDivisionId: Number(sm.supplier.id), //takerMarket.supplierDivision!.id,
      takerDivisionUuid: sm.supplier.uuid, //takerMarket.supplierDivision!.uuid,
      takerDivisionName: sm.supplier.name, //takerMarket.supplierDivision!.name,
      takerExcludedInvoiceAmount: sm.stats?.invoice?.excluded.amount ?? 0, //takerMarket.supplierExcluded.invoiceAmount,
      takerExcludedInvoiceCount: sm.stats?.invoice?.excluded.count ?? 0, //takerMarket.supplierExcluded.invoiceCount,
      takerMakerId: sm.supplier.buyerId!, //takerMarket.supplierBuyerId,
      userAddedToDivision: userSupplier?.associationDate, //takerMarket.divisionUsers?.[0]?.created,
      lastFetchedAt: Date.now(),
    };
  });
};

interface SupplierMarketQueryOptions {
  makerOrganizationUuids?: string[];
  supplierMarkets?: {
    marketUuid: string;
    supplierDivisionUuid: string;
  }[];
}

interface FetchTakerMarketsOptions {
  filter?: EarlyPaySupplierStatsDeepFilter | SupplierMarketQueryOptions;
}

export const fetchTakerMarkets = async (options?: FetchTakerMarketsOptions): Promise<TakerMarket[]> => {
  const usdExchangeRates: ExchangeRate | undefined = queryClient.getQueryData(['exchange-rates', 'USD']);

  const makerOrganizationUuidsFilter =
    (options?.filter && 'makerOrganizationUuids' in options.filter && options.filter.makerOrganizationUuids) ||
    undefined;

  const supplierMarketsFilter =
    (options?.filter && 'supplierMarkets' in options.filter && options.filter.supplierMarkets) || undefined;

  // Fetch this data in parallel
  const [seaMarkets, seaOffers, seaPricingSuggestions, seaSupplierUserData] = await Promise.all([
    apiClient
      .get(`api/supplier-experience/supplier-markets`, {
        searchParams: qs.stringify(
          {
            makerOrganizationUuids: makerOrganizationUuidsFilter,
            supplierMarkets: supplierMarketsFilter,
          } satisfies SEAMarketParams,
          { arrayFormat: 'indices' }
        ),
      })
      .json<SEAMarketResponse>(),
    apiClient
      .get(`api/supplier-experience/offers`, {
        searchParams: qs.stringify(
          {
            includeStats: 'true',
            makerOrganizationUuids: makerOrganizationUuidsFilter,
            supplierMarkets: supplierMarketsFilter,
          } satisfies SEAOfferParams,
          { arrayFormat: 'indices' }
        ),
      })
      .json<SEAOfferResponse>(),
    apiClient
      .post(`api/supplier-experience/pricing-suggestions`, {
        json: {
          rateInformationTypes: ['benchmark', 'static', 'preferred'],
          frequency: 'recurring',
          isActionableOffer: false,
          ...(supplierMarketsFilter
            ? {
                // One group per supplier market, to match typical enterprise style grouping
                supplierMarketGroups: supplierMarketsFilter.map((sm) => ({
                  supplierMarkets: [{ marketUuid: sm.marketUuid, supplierDivisionUuid: sm.supplierDivisionUuid }],
                })),
              }
            : {}),
        } satisfies SEAPricingSuggestionRequestBody,
      })
      .json<SEAPricingSuggestionResponse>(),
    apiClient
      .get('api/supplier-experience/user/suppliers', {
        searchParams: qs.stringify(
          {
            supplierMarkets: supplierMarketsFilter,
          } satisfies SEASupplierParams,
          { arrayFormat: 'indices' }
        ),
      })
      .json<SEAUserSupplierResponse>(),
  ]);

  return seaMarkets.data && seaOffers.data
    ? formatSEASupplierMarkets(
        seaMarkets.data.supplierMarketGroups,
        seaOffers.data,
        seaSupplierUserData.data.suppliers,
        seaPricingSuggestions.data.supplierMarketGroups,
        usdExchangeRates
      )
    : [];
};

const useTakerMarkets = <TData = TakerMarket[]>(select?: (data: TakerMarket[]) => TData) => {
  return useSuspenseQuery({
    queryKey: ['taker-markets'],
    queryFn: () => fetchTakerMarkets(),
    select,
  });
};

export default useTakerMarkets;
