import { Button } from '@c2fo/liquidity';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { DaysOfTheWeek } from '../types';
import useOfferModalFormContext from '../useOfferModalFormContext';
import { checkIfDurationIsATypicalOngoingOffer } from '../utils';
import CadenceInput from './CadenceInput';
import StartOrEndDateInput from './StartOrEndDateInput';

export const SchedulingInput = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { watch, setValue } = useOfferModalFormContext();
  const isDefaultDuration = checkIfDurationIsATypicalOngoingOffer({
    startDate: watch('startDate'),
    endDate: watch('endDate'),
    cadence: watch('cadence'),
  });

  const handleRestoreOngoingSchedule = () => {
    setValue('startDate', undefined);
    setValue('endDate', undefined);
    setValue('cadence', { type: 'daily', value: DaysOfTheWeek });
  };

  return (
    <div className="space-y-4 p-4">
      <div className="flex items-center justify-between gap-6">
        {/* TODO: Translate */}
        <p>
          Review or adjust your offer participation in the market. Schedules operate according to the Market Processing
          Hours in US-Central Time.{' '}
        </p>
        {/* TODO: Translate */}
        <Button variant="tertiary" size="sm" disabled={isDefaultDuration} onClick={handleRestoreOngoingSchedule}>
          Restore Ongoing Schedule
        </Button>
      </div>
      <CadenceInput takerMarket={takerMarket} />
      <StartOrEndDateInput type="start" />
      <StartOrEndDateInput type="end" />
    </div>
  );
};
