import { useMutation } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import { CreateRecurringRuleArgument } from '@/enterprise/data/useCreateRecurringRule';
import useRefetchAndSubscribeForRules from '@/enterprise/features/recurringRules/utils/useRefetchAndSubscribeForRules';
import { mapRuleArgumentToRuleArray } from '@/enterprise/features/recurringRules/utils/utils';
import apiClient from '@/lib/apiClient';
import { useReporting } from '@/reporting';

export type InvoiceRulesPatchBody = paths['/invoice-rules']['patch']['requestBody']['content']['application/json'];

type InvoiceRulesPatchResponse = paths['/invoice-rules']['patch']['responses']['204']['content'];

export interface UpdateRecurringRuleArgument extends CreateRecurringRuleArgument {
  rules: InvoiceRulesPatchBody['rules'];
}

const useUpdateRecurringRule = () => {
  const { trackEnterpriseEvent } = useReporting();
  const { refetchAndSubscribeRules } = useRefetchAndSubscribeForRules();

  return useMutation({
    mutationKey: ['updateRecurringRule'],
    mutationFn: (rule: UpdateRecurringRuleArgument) => {
      const { takersMarkets, ...rest } = rule;

      return apiClient
        .patch(`api/supplier-experience/invoice-rules`, {
          json: {
            ...rest,
            groupingKey: 'SUPPLIER_MARKET',
            supplierMarkets: takersMarkets.map((takerMarket) => ({
              marketUuid: takerMarket.marketUuid,
              supplierDivisionUuid: takerMarket.takerUuid,
            })),
          } satisfies InvoiceRulesPatchBody,
        })
        .json<InvoiceRulesPatchResponse>();
    },
    onSuccess: (_data, variables) => {
      const { takersMarkets } = variables;

      const takerMarketsWithoutRulesByMakerOrganizationUuid = takersMarkets.reduce(
        (acc, takerMarket) => {
          if (!acc[takerMarket.makerOrganizationUuid]) {
            acc[takerMarket.makerOrganizationUuid] = [];
          }

          acc[takerMarket.makerOrganizationUuid].push(takerMarket);
          return acc;
        },
        {} as Record<string, typeof takersMarkets>
      );

      Object.entries(takerMarketsWithoutRulesByMakerOrganizationUuid).forEach(
        ([makerOrganizationUuid, takerMarkets]) => {
          trackEnterpriseEvent('recurring-rules::updated', {
            makerOrganizationUuid,
            operatorType: variables.operatorType,
            rules: mapRuleArgumentToRuleArray(variables),
            takerMarkets: takerMarkets.map((takerMarket) => ({
              marketId: takerMarket.marketId,
              marketUuid: takerMarket.marketUuid,
              takerId: takerMarket.takerId,
              takerUuid: takerMarket.takerUuid,
            })),
          });
        }
      );

      refetchAndSubscribeRules({ takerMarkets: takersMarkets });
    },
  });
};

export default useUpdateRecurringRule;
