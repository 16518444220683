import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  Paper,
  Popover,
  SearchInput,
  Select,
  SelectContent,
  SelectFilterTrigger,
  SelectItem,
  SelectValue,
} from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import {
  ChevronDownIcon,
  EllipsisVerticalIcon,
  FilterIcon,
  InfoCircleIcon,
  MoneyBillIcon,
  ShapesIcon,
} from '@c2fo/liquidity/icons';
import emptystatements from '@/enterprise/assets/emptystatementsgrey.svg?url';
import invoices from '@/enterprise/assets/invoices.svg?url';
import EllipsisTooltip from '@/enterprise/components/EllipsisTooltip';
import NoDataState from '@/enterprise/components/NoDataState';
import {
  Table,
  TableBody,
  TableCell,
  TableDisplay,
  TableHead,
  TableHeader,
  TableRow,
  TableSortArrow,
  TableSortDirection,
} from '@/enterprise/components/Table';
import useRecurringRules, {
  RecurringRule,
  RecurringRuleCategory,
  TakerMarketWithRecurringRule,
} from '@/enterprise/data/useRecurringRules';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { useReporting } from '@/reporting';
import IntercomDataTarget from '@/reporting/IntercomDataTarget';
import getMarketType from '@/utils/getMarketType';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useIsAccountQualifiedToNotifySrm from '@/utils/useIsAccountQualifiedToNotifySrm';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';
import DeleteAllRulesModal from './components/DeleteAllRulesModal';
import MiniRuleManager from './components/MiniRuleManager';
import Pagination, { getStartEnd } from './components/Pagination';
import getIsBeta from './utils/getIsBeta';
import getRecurringRuleForCategory from './utils/getRecurringRuleForCategory';
import sortRulesTable, { Sort, SortKey } from './utils/sortRulesTable';

interface RulesTableFilters {
  currency?: string;
  ruleCategory?: RecurringRuleCategory;
}

const ruleCategoryFilters: { key: RecurringRuleCategory; translationKey: string }[] = [
  {
    key: 'dpe',
    translationKey: 'core.daysPaidEarly',
  },
  {
    key: 'dueDate',
    translationKey: 'recurringRules.dueDate.category.label',
  },
  {
    key: 'amount',
    translationKey: 'core.invoiceAmount',
  },
  {
    key: 'invoiceId',
    translationKey: 'core.invoiceId',
  },
];

/**
 * sort and filter rules based on search, sort, and filters
 */
const sortAndFilterRules = ({
  filters,
  recurringRules,
  search,
  sort,
}: {
  recurringRules: TakerMarketWithRecurringRule[];
  search: string;
  sort: Sort;
  filters?: RulesTableFilters;
}) => {
  return sortRulesTable(recurringRules, sort).filter(({ takerMarket, rule }) => {
    if (
      !getTakerMarketName(takerMarket).toLowerCase().includes(search.toLowerCase()) &&
      !getTakerMarketDivisionTitle(takerMarket)?.title.toLowerCase().includes(search.toLowerCase())
    ) {
      return false;
    }

    if (
      filters?.ruleCategory &&
      !getRecurringRuleForCategory(rule, filters.ruleCategory)?.[
        filters.ruleCategory === 'invoiceId' ? 'excludedVoucherIds' : filters.ruleCategory
      ]
    ) {
      return false;
    }

    if (filters?.currency && takerMarket.currency !== filters.currency) {
      return false;
    }

    return true;
  });
};

const DivisionTitle = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const takerMarketName = getTakerMarketName(takerMarket);
  const buyerDivisionName = getTakerMarketDivisionTitle(takerMarket)?.content;
  const isPreferred = getMarketType(takerMarket) === 'PREFERRED';

  return (
    <div className="flex flex-col">
      <EllipsisTooltip
        content={takerMarketName}
        trigger={
          <Link
            className="font-bold"
            to={`../markets/${takerMarket.marketId}/division/${takerMarket.takerDivisionId}/invoices/eligible`}
          >
            {takerMarketName}
          </Link>
        }
      />
      <div className="text-sm text-text-primary">{takerMarket.currency}</div>
      <EllipsisTooltip content={buyerDivisionName} trigger={<span className="text-sm">{buyerDivisionName}</span>} />
      {isPreferred && (
        <div className="mt-1 flex gap-1">
          <Popover>
            <Popover.Trigger>
              <InfoCircleIcon className="h-4 w-4" fill={colors.gray[600]} />
            </Popover.Trigger>
            <Popover.Content arrow side="bottom">
              {t('recurringRules.preferredOfferLocked')}
            </Popover.Content>
          </Popover>
          <span className="text-wrap text-xs italic text-text-secondary">{t('core.preferredOffer')}</span>
        </div>
      )}
    </div>
  );
};

const RulesTable = ({
  showInformationRequest,
  loadingInformationRequest,
  disableInformationRequest,
}: {
  showInformationRequest: () => void;
  loadingInformationRequest: boolean;
  disableInformationRequest: boolean;
}) => {
  const isBeta = getIsBeta();
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const recurringRules = useRecurringRules();
  const { asCurrency, asNumber } = useLocaleFormat();
  const isAccountQualifiedToNotifySrm = useIsAccountQualifiedToNotifySrm();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [sort, setSort] = useState<Sort>({ key: 'totalAr', direction: 'desc' });
  const [search, setSearch] = useState<string>('');
  const [filters, setFilters] = useState<RulesTableFilters>({
    currency: undefined,
    ruleCategory: undefined,
  });
  const filteredRules = sortAndFilterRules({ filters, recurringRules, search, sort });
  const filteredCount = filteredRules.length;
  const totalCount = recurringRules.length;
  const { start, end } = getStartEnd({ limit, page, totalCount });
  const { start: filteredStart, end: filteredEnd } = getStartEnd({ limit, page, totalCount: filteredCount });
  const { getRestrictions } = useRestrictions();
  const { canEditRecurringRules } = getRestrictions([]);
  // delete all rules
  const [openDeleteAllRulesModal, setOpenDeleteAllRulesModal] = useState(false);
  const [expandedRule, setExpandedRule] = useState<RecurringRule | null>(null);
  const hasFilterOrSearch = !!filters.currency || !!filters.ruleCategory || search !== '';

  const selectedRulesText =
    filteredCount === 1 ? (
      <div className="text-sm font-medium">
        {search !== '' || !Object.values(filters).every((filter) => filter === undefined)
          ? t('divisions.filterMatchingCount', { filteredCount, start: filteredStart, end: filteredEnd })
          : t('divisions.filterCount', { filteredCount, start: filteredStart, end: filteredEnd })}
      </div>
    ) : (
      <div className="text-sm font-medium">
        {search !== '' || !Object.values(filters).every((filter) => filter === undefined)
          ? t('divisions.filterMatchingCountPlural', { filteredCount, start: filteredStart, end: filteredEnd })
          : t('divisions.filterCountPlural', { filteredCount, start: filteredStart, end: filteredEnd })}
      </div>
    );

  const onPageSizeChange = (limit: number) => {
    setLimit(limit);
    setPage(1);
  };

  const onSort = (accessorKey: SortKey) => {
    let direction: TableSortDirection = accessorKey === 'takerMarketName' ? 'asc' : 'desc';

    if (sort?.key === accessorKey) {
      direction = sort.direction === 'desc' ? 'asc' : 'desc';
    }

    setSort({ key: accessorKey, direction });
  };

  const onSearch = (value: string) => {
    setSearch(value);
    setPage(1);
  };

  const tableHeadColumns: { accessorKey?: SortKey; translation: string; className: string }[] = [
    {
      accessorKey: 'takerMarketName',
      translation: t('core.division'),
      className: 'w-72',
    },
    {
      accessorKey: 'totalAr',
      translation: t('core.totalAR'),
      className: 'w-36',
    },
    {
      accessorKey: 'ruleCount',
      translation: t('recurringRules.criteria'),
      className: 'w-24',
    },
    {
      accessorKey: 'eligibleInvoiceAmount',
      translation: t('core.amountIncluded'),
      className: 'w-40',
    },
    {
      accessorKey: 'eligibleInvoiceCount',
      translation: t('draftOffer.labels.invoicesIncluded'),
      className: 'w-40',
    },
    {
      accessorKey: 'takerExcludedInvoiceAmount',
      translation: t('core.amountExcluded'),
      className: 'w-40',
    },
    {
      accessorKey: 'takerExcludedInvoiceCount',
      translation: t('core.invoicesExcluded'),
      className: 'w-40',
    },
    {
      translation: '',
      className: 'w-36',
    },
  ];

  const handleDeleteAllRulesModalClose = () => {
    setOpenDeleteAllRulesModal(false);
  };

  const handleDeleteAllRulesModalSuccess = async () => {
    setFilters({
      currency: undefined,
      ruleCategory: undefined,
    });
    setSearch('');
    trackEnterpriseEvent('recurring-rules::bulk-delete::all', {
      referrer: 'rules-manager',
    });
  };

  const handleRuleExpand = (rule: RecurringRule) => {
    if (expandedRule?.id === rule.id) {
      setExpandedRule(null);
    } else {
      setExpandedRule(rule);
    }
  };

  return (
    <div>
      <DeleteAllRulesModal
        hasFilterOrSearch={hasFilterOrSearch}
        onClose={handleDeleteAllRulesModalClose}
        onSuccess={handleDeleteAllRulesModalSuccess}
        open={openDeleteAllRulesModal}
        recurringRules={filteredRules}
      />
      {isBeta && (
        <Paper className="mb-11 px-8 py-6" elevation={2}>
          <div className="flex flex-col items-center gap-7 md:flex-row">
            <div className="shrink-0">
              <img src={invoices} alt={t('recurringRules.createRecurringRule')} className="mx-auto h-20 w-20" />
            </div>
            <div>
              <div className="mb-2 text-xl font-medium">{t('recurringRules.noActiveRulesTitleBeta')}</div>
              <div>{t('recurringRules.noActiveRulesMessageBeta')}</div>
            </div>
            {isAccountQualifiedToNotifySrm && (
              <div className="w-full shrink-0 md:w-fit">
                <Button
                  onClick={showInformationRequest}
                  variant="secondary"
                  loading={loadingInformationRequest}
                  disabled={disableInformationRequest}
                >
                  {t('recurringRules.noActiveRulesCtaBeta')}
                </Button>
              </div>
            )}
          </div>
        </Paper>
      )}
      <h2 className="m-0 pb-8 text-2xl">{t('recurringRules.activeRules')}</h2>
      <Paper className="px-8 pt-8" elevation={2}>
        <div className="rounded-t border border-stroke bg-canvas px-6 py-4">
          <div className="flex flex-col justify-between gap-4 lg:flex-row">
            {/* Filters */}
            <div className="flex flex-col items-center justify-between gap-4 lg:flex-row">
              <div className="flex w-full items-center gap-4">
                <FilterIcon className="h-5 w-5 shrink-0" fill={colors.text.secondary} />
                {/* Rule Category Filter */}
                <Select
                  onValueChange={(value) => {
                    setFilters({
                      ...filters,
                      ruleCategory: value === 'all' ? undefined : (value as RecurringRuleCategory),
                    });
                    setPage(1);
                  }}
                  value={filters.ruleCategory ?? 'all'}
                >
                  <SelectFilterTrigger
                    icon={ShapesIcon}
                    iconFill="fill-primary-500"
                    label={t('recurringRules.ruleCriteria')}
                    size="sm"
                  >
                    <SelectValue />
                  </SelectFilterTrigger>
                  <SelectContent>
                    {[{ key: 'all', label: t('maker.all'), value: 'all' }]
                      .concat(
                        ruleCategoryFilters.map((categories) => ({
                          key: categories.key,
                          value: categories.key,
                          label: t(categories.translationKey),
                        }))
                      )
                      .map((item) => (
                        <SelectItem value={item.value} key={item.key}>
                          {item.label}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </div>
              {/* Currency Filter */}
              <Select
                onValueChange={(value) => {
                  setFilters({ ...filters, currency: value === 'all' ? undefined : value });
                  setPage(1);
                }}
                value={filters.currency ?? 'all'}
              >
                <SelectFilterTrigger
                  icon={MoneyBillIcon}
                  iconFill="fill-primary-500"
                  label={t('core.currency')}
                  size="sm"
                >
                  <SelectValue />
                </SelectFilterTrigger>
                <SelectContent>
                  {[{ key: 'all', label: t('maker.all'), value: 'all' }]
                    .concat(
                      recurringRules
                        .map(({ takerMarket }) => takerMarket.currency)
                        .filter((val, index, curVal) => curVal.indexOf(val) === index)
                        .map((curr) => ({
                          key: curr,
                          label: curr,
                          value: curr,
                        }))
                    )
                    .map((item) => (
                      <SelectItem value={item.value} key={item.key}>
                        {item.label}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
            {/* Search and Menu */}
            <div className="inline-flex items-center gap-2">
              <SearchInput
                aria-label={t('core.search')}
                name="Search"
                onChange={(e) => onSearch(e.target.value)}
                onClear={() => onSearch('')}
                placeholder={t('core.search')}
                size="sm"
                value={search}
              />
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <IconButton
                    icon={EllipsisVerticalIcon}
                    name={t('recurringRules.menu')}
                    size="sm"
                    variant="ancillary"
                  />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" avoidCollisions={false} collisionPadding={24}>
                  <DropdownMenuItem
                    disabled={!canEditRecurringRules || filteredCount === 0}
                    {...(canEditRecurringRules && { onClick: () => setOpenDeleteAllRulesModal(true) })}
                  >
                    {hasFilterOrSearch
                      ? t('recurringRules.deleteAllFilteredRules')
                      : t('recurringRules.deleteAllRules')}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          <div className="pt-4">{selectedRulesText}</div>
        </div>
        <div className="w-full overflow-auto">
          <IntercomDataTarget target="rulesTable" className="overflow-auto rounded-b border border-t-0 border-stroke">
            <Table>
              <TableHeader className="border-b-0 bg-canvas">
                <TableRow className="border-stroke">
                  {tableHeadColumns.map(({ accessorKey, translation, className }) => (
                    <TableHead
                      key={translation}
                      className={cn('text-right', className)}
                      {...(!!accessorKey && { onClick: () => onSort(accessorKey) })}
                    >
                      {!!accessorKey ? (
                        <TableSortArrow
                          accessorKey={accessorKey}
                          sort={sort}
                          {...(!['takerMarketName'].includes(accessorKey) && { textRight: true })}
                        >
                          {translation}
                        </TableSortArrow>
                      ) : (
                        translation
                      )}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredRules.length === 0 ? (
                  <TableDisplay colSpan={8}>
                    <NoDataState
                      className="py-8"
                      icon={<img src={emptystatements} alt="emptystatements" className="h-28 w-28" />}
                      title={t('taker.noMatchingDivisions')}
                      message={t('taker.noMatchingDivisionsDescription')}
                    />
                  </TableDisplay>
                ) : (
                  filteredRules.slice(start - 1, end).map((takerMarketWithRule) => {
                    const { rule, takerMarket } = takerMarketWithRule;

                    return (
                      <Fragment key={rule.id}>
                        <TableRow className={cn('border-stroke', { 'border-b-0': expandedRule?.id === rule.id })}>
                          <TableCell>
                            <DivisionTitle takerMarket={takerMarket} />
                          </TableCell>
                          <TableCell className="text-right">
                            {asCurrency(
                              takerMarket.eligibleInvoiceAmount + takerMarket.takerExcludedInvoiceAmount,
                              takerMarket.currency
                            )}
                          </TableCell>
                          <TableCell className="text-right">{rule.count}</TableCell>
                          <TableCell className="text-right">
                            {asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency)}
                          </TableCell>
                          <TableCell className="text-right">
                            {asNumber({ value: takerMarket.eligibleInvoiceCount })}
                          </TableCell>
                          <TableCell className="text-right">
                            {asCurrency(takerMarket.takerExcludedInvoiceAmount, takerMarket.currency)}
                          </TableCell>
                          <TableCell className="text-right">
                            {asNumber({ value: takerMarket.takerExcludedInvoiceCount })}
                          </TableCell>
                          <TableCell>
                            <div className="flex items-center justify-end">
                              <IconButton
                                className={cn({ 'rotate-180': expandedRule?.id === rule.id })}
                                icon={ChevronDownIcon}
                                name={t('recurringRules.expandRules')}
                                onClick={() => handleRuleExpand(rule)}
                                size="md"
                                variant="ancillary"
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        {expandedRule?.id === rule.id && (
                          <TableRow className="border-b border-stroke">
                            <TableCell className="pt-0" colSpan={8}>
                              <MiniRuleManager
                                asChild
                                referrer="rulesManager"
                                takerMarketWithRule={takerMarketWithRule}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </IntercomDataTarget>
        </div>
        <div className="flex justify-center p-4">
          <Pagination
            limit={limit}
            onPageChange={setPage}
            onPageSizeChange={onPageSizeChange}
            page={page}
            totalCount={filteredCount}
          />
        </div>
      </Paper>
    </div>
  );
};

export default RulesTable;
