import { useMutation, useQueryClient } from '@tanstack/react-query';
import useTakerMarkets, { OfferConfig, TakerMarket } from '@/enterprise/data/useTakerMarkets';
import apiClient from '@/lib/apiClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import { DraftOfferRateInformation } from './useDraftOffer';

interface ExtendedPreferredOfferInput {
  draftId: string;
  setOfferConfigQueryData?: Partial<OfferConfig> & { disableAfterMarketClearsDate: string | null };
  rateInformation: DraftOfferRateInformation;
  supplierMarkets: { supplierDivisionUuid: string; marketUuid: string }[];
}

function submitPreferredOfferSEA(draftId: string, rateInformation: DraftOfferRateInformation) {
  return apiClient.post(`api/supplier-experience/offers/draft/${draftId}`, {
    json: {
      offerType: 'preferred-term',
      rateInformation: {
        rateType: rateInformation.rateType,
        rate:
          rateInformation.rateType === 'APR' || rateInformation.rateType === 'DISC' ? rateInformation.rate : undefined,
        spread: rateInformation.rateType === 'REF' && 'spread' in rateInformation ? rateInformation.spread : undefined,
      },
    },
  });
}

const useSubmitPreferredOffer = () => {
  const queryClient = useQueryClient();
  const { data: takerMarkets } = useTakerMarkets();
  const { subscribeToMarketStats } = useServerSideEventListeners();

  return useMutation({
    mutationKey: ['submitPreferredOffer'],
    mutationFn: async ({ draftId, rateInformation }: ExtendedPreferredOfferInput) => {
      return submitPreferredOfferSEA(draftId, rateInformation);
    },
    onSuccess: (_data, variables) => {
      const updatedSupplierMarkets = variables.supplierMarkets;
      const effectedTakersMarkets = takerMarkets?.filter((tm) => {
        return (
          updatedSupplierMarkets.find((draftSupplierMarket) => {
            return (
              tm.marketUuid === draftSupplierMarket.marketUuid &&
              tm.takerDivisionUuid === draftSupplierMarket.supplierDivisionUuid
            );
          }) !== undefined
        );
      });

      if (effectedTakersMarkets && effectedTakersMarkets.length) {
        effectedTakersMarkets.forEach((effectedTm) => {
          // update the cache with the returned data
          queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) => {
            return (prevTakerMarkets ?? []).map((prevTm) => {
              const isMatch =
                prevTm.marketUuid === effectedTm.marketUuid &&
                prevTm.takerDivisionUuid === effectedTm.takerDivisionUuid;

              if (!isMatch) {
                return prevTm;
              }

              let offerConfig: OfferConfig = {
                ...prevTm.offerConfig,
                maxApr: null,
                maxDiscount: null,
                isEnabled: true,
                offerType: 'PREFERRED_TERM',
              };

              if (variables.setOfferConfigQueryData) {
                offerConfig = {
                  ...offerConfig,
                  ...variables.setOfferConfigQueryData,
                };
              }

              return {
                ...prevTm,
                offerConfig,
                // Set the "Term End Date" before stats are updated
                ...(variables.setOfferConfigQueryData?.disableAfterMarketClearsDate && {
                  disableAfterMarketClearsDate: variables.setOfferConfigQueryData?.disableAfterMarketClearsDate,
                }),
              };
            });
          });

          // subscribe to market stats
          // this will refetch stats queries after we know stats have updated
          subscribeToMarketStats({
            marketUuid: effectedTm.marketUuid,
            takerId: effectedTm.takerDivisionId,
          });
        });
      }
    },
  });
};

export default useSubmitPreferredOffer;
