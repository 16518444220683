import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { VariableRateDetails } from '../../variableRate/DetailModal';
import useVariableRateDetails from '../../variableRate/useVariableRateDetails';

export const BenchmarkRateInput = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { getVariableRateDetails } = useVariableRateDetails();
  const { formattedDiscountAmount, formattedEstimatedRate, formattedReferenceRate, formattedSpread, rateName } =
    getVariableRateDetails(takerMarket);

  return (
    <div className="p-4">
      <VariableRateDetails
        discountAmount={formattedDiscountAmount}
        estimatedRate={formattedEstimatedRate}
        referenceRate={formattedReferenceRate}
        spread={formattedSpread}
        rateName={rateName}
      />
    </div>
  );
};
