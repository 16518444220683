import { useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { enterpriseBasename } from '@/constants';
import FullPageLoader from '@/shared/components/FullPageLoader';
import useMagicLogin from '@/shared/data/useMagicLogin';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';
import { useToken } from '@/utils/token';

/**
 * Magic Login URL
 * https://app.c2fo.com/enterprise/auth/magic-login?magicLoginToken=${MagicLoginToken}&redirect=draftOffer&id=${DraftRequestId}&referrer=${PutAnyWordHere}
 */
const AuthMagicLogin = () => {
  const { setToken } = useToken();
  const [searchParams] = useSearchParams();
  const [readyToNavigate, setReadyToNavigate] = useState(false);
  const { mutateAsync: fetchAuthToken } = useMagicLogin();

  const magicLoginToken = searchParams.get('magicLoginToken');
  const redirect = searchParams.get('redirect');
  const draftOfferId = searchParams.get('id');

  useEffectOnce(() => {
    const exchangeMagicLoginTokenForAuthToken = async () => {
      try {
        if (!magicLoginToken) {
          throw new Error('No valid magic login token to exchange');
        }

        const { jwt } = await fetchAuthToken({ magicLoginToken });

        setToken(jwt);

        setReadyToNavigate(true);
      } catch (error) {
        console.error(error);
        setReadyToNavigate(true);
      }
    };

    exchangeMagicLoginTokenForAuthToken();
  });

  if (readyToNavigate && redirect === 'draftOffer') {
    return <Navigate to={asEnterpriseLink(`offer/${draftOfferId}`)} />;
  }

  if (readyToNavigate && !redirect) {
    return <Navigate to={`/${enterpriseBasename}`} />;
  }

  return <FullPageLoader />;
};

export default AuthMagicLogin;
