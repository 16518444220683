import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LinkTabs from '@/shared/components/LinkTabs';

const App = () => {
  const { t } = useTranslation();

  return (
    <div className="-mt-10">
      <div className="fixed left-0 z-[9] w-full border-b bg-white">
        <div className="mx-auto max-w-screen-2xl px-4 lg:px-6">
          <LinkTabs
            items={[
              {
                key: 'settings',
                label: t('core.accountSettings'),
                link: 'settings',
                matcher: 'settings',
              },
              {
                key: 'notifications',
                label: t('accountSettings.notificationSettings'),
                link: 'notifications',
                matcher: 'notifications',
              },
              {
                key: 'right-to-be-forgotten',
                label: t('accountSettings.rightToBeForgotten'),
                link: 'right-to-be-forgotten',
                matcher: 'right-to-be-forgotten',
              },
            ]}
            variant="secondary"
          />
        </div>
      </div>
      <div className="pt-24">
        <Outlet />
      </div>
    </div>
  );
};

export default App;
