import { useMutation, useQueryClient } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import useTakerMarkets, { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import apiClient from '@/lib/apiClient';
import { useServerSideEventListeners } from '@/lib/serverSentEvents';
import useDataDog from '@/reporting/DataDog';

const useSubmitStaticOffer = () => {
  const queryClient = useQueryClient();
  const { data: takerMarkets } = useTakerMarkets();
  const { subscribeToMarketStats } = useServerSideEventListeners();
  const { addError } = useDataDog();

  return useMutation({
    mutationKey: ['submitStaticOffer'],
    mutationFn: async ({
      rate,
      marketUuid,
      supplierDivisionUuid,
      expireOn,
    }: {
      rate: number;
      marketUuid: string;
      supplierDivisionUuid: string;
      expireOn?: string | null;
    }) => {
      const takerMarket = takerMarkets?.find(
        (tm) => tm.marketUuid === marketUuid && tm.takerDivisionUuid === supplierDivisionUuid
      );
      const uuid = takerMarket?.offerConfig.uuid;

      if (uuid) {
        return apiClient
          .patch('api/supplier-experience/offers/static', {
            json: {
              groupingKey: 'SUPPLIER_MARKET',
              status: 'active',
              rateInformation: {
                rateType: 'APR',
                rate: rate,
              },
              expirationDate: expireOn,
              offers: [
                {
                  makerOrganizationUuid: takerMarket?.makerOrganizationUuid,
                  uuid,
                },
              ],
            } satisfies paths['/offers/static']['patch']['requestBody']['content']['application/json'],
          })
          .json<paths['/offers/static']['patch']['responses']['204']['content']>();
      }

      return apiClient
        .post('api/supplier-experience/offers/static', {
          json: {
            groupingKey: 'SUPPLIER_MARKET',
            offerType: 'static',
            frequency: 'recurring',
            rateType: 'APR',
            rate: rate,
            expirationDate: expireOn ?? undefined,
            supplierMarkets: [
              {
                marketUuid,
                supplierDivisionUuid,
              },
            ],
          } satisfies paths['/offers/static']['post']['requestBody']['content']['application/json'],
        })
        .json<paths['/offers/static']['post']['responses']['201']['content']['application/json']>();
    },
    onSuccess: (_data, variables) => {
      const tmMeta = takerMarkets?.find(
        (meta) => meta.marketUuid === variables.marketUuid && meta.takerDivisionUuid === variables.supplierDivisionUuid
      );

      if (tmMeta?.marketUuid && tmMeta?.takerDivisionId) {
        // update the cache with the returned data
        queryClient.setQueryData<TakerMarket[]>(['taker-markets'], (prevTakerMarkets) =>
          (prevTakerMarkets ?? []).map((tm) => ({
            ...(tm.marketUuid === variables.marketUuid && tm.takerDivisionUuid === variables.supplierDivisionUuid
              ? {
                  ...tm,
                  offerConfig: {
                    ...tm.offerConfig,
                    maxApr: variables.rate,
                    isEnabled: true,
                  },
                }
              : tm),
          }))
        );

        // subscribe to market stats
        // this will refetch stats queries after we know stats have updated
        subscribeToMarketStats({
          marketUuid: tmMeta.marketUuid,
          takerId: tmMeta.takerDivisionId,
        });
      }
    },

    onError: (error, variables) => {
      const takerMarket = takerMarkets?.find(
        (meta) => meta.marketUuid === variables.marketUuid && meta.takerDivisionUuid === variables.supplierDivisionUuid
      );

      addError('useSubmitStaticOffer', {
        error,
        variables,
        seaOfferUuid: takerMarket?.offerConfig.uuid,
      });
    },
  });
};

export default useSubmitStaticOffer;
