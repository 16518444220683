import { useTranslation } from 'react-i18next';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import useSrm from '@/shared/data/useSrm';

const SrmCardComponent = () => {
  const srm = useSrm();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center gap-2">
      <div>{t('srm.contactMessage')}</div>
      <div className="flex w-full max-w-[420px] items-center justify-start gap-5 rounded-full bg-white px-5 py-4 shadow-md">
        {srm?.image ? (
          <div className="flex h-20 w-20 items-center justify-center overflow-hidden rounded-full bg-blue-500 sm:h-24 sm:w-24">
            <img alt={srm?.emailAddress ?? srm.image} src={srm.image} className="ml-2.5" />
          </div>
        ) : null}
        <div className="font-medium">
          {srm?.name ? <div>{srm.name}</div> : null}
          {srm?.emailAddress ? (
            <div>
              <a href={`mailto:${srm.emailAddress}`} className="text-blue-500">
                {srm.emailAddress}
              </a>
            </div>
          ) : null}
          {/* Some srm have two phone numbers split with a / */}
          {srm?.phoneNumber
            ? srm.phoneNumber.split('/').map((phoneNumber) => (
                <div key={phoneNumber}>
                  <a href={`tel:${phoneNumber}`} className="text-blue-500">
                    {phoneNumber}
                  </a>
                </div>
              ))
            : null}
          {srm?.calendarLink ? (
            <div>
              <a href={srm.calendarLink} rel="noreferrer" target="_blank" className="text-blue-500">
                {t('srm.scheduleACall')}
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const SrmCard = () => (
  <QueryBoundaries>
    <SrmCardComponent />
  </QueryBoundaries>
);

export default SrmCard;
