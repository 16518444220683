import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  cn,
  HelperText,
  IconButton,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  TextInput,
} from '@c2fo/liquidity';
import { AlarmClockIcon, CalendarIcon, MoneyBillWaveIcon, SquareQuoteIcon, TrashCanIcon } from '@c2fo/liquidity/icons';
import DatePicker from '@/enterprise/components/DatePicker';
import { RecurringRuleCategory, RecurringRuleCriteria } from '@/enterprise/data/useRecurringRules';
import useRecurringRuleFormContext from '@/enterprise/features/recurringRules/utils/useRecurringRuleFormContext';
import useRuleFormContent from '@/enterprise/features/recurringRules/utils/useRuleFormContent';
import { dateAsIsoString } from '@/utils/dateAsIsoString';
import { CriteriaSelectProps } from './CriteriaSelect';

const criteriaIconMap = {
  amount: <MoneyBillWaveIcon className="h-6 w-6 fill-gray-600" />,
  dpe: <CalendarIcon className="h-6 w-6 fill-gray-600" />,
  dueDate: <AlarmClockIcon className="h-6 w-6 fill-gray-600" />,
  invoiceId: <SquareQuoteIcon className="h-6 w-6 fill-gray-600" />,
};

const CriteriaOptions = ({
  category,
  categoryIndex,
  categoryLength,
  categoryRemove,
  errors,
  setErrors,
  toCurrency,
}: CriteriaSelectProps & {
  category: RecurringRuleCategory;
  categoryIndex: number;
  categoryLength: number;
  categoryRemove: () => void;
}) => {
  const { t } = useTranslation();
  const { control, getValues, watch } = useRecurringRuleFormContext();
  const invoiceRules = getValues('invoiceRules');
  const { getRuleFormContent } = useRuleFormContent();
  const { options, optionDescription } = getRuleFormContent(category);
  const [dueBeforeDatePickerOpen, setDueBeforeDatePickerOpen] = useState<boolean>(false);
  const [dueAfterDatePickerOpen, setDueAfterDatePickerOpen] = useState<boolean>(false);

  const toggleDatePicker = (ruleType: RecurringRuleCriteria) => {
    if (ruleType === 'toDueDate') {
      return setDueBeforeDatePickerOpen(!dueBeforeDatePickerOpen);
    }

    return setDueAfterDatePickerOpen(!dueAfterDatePickerOpen);
  };

  const { fields, update } = useFieldArray({
    control,
    name: `invoiceRules.${categoryIndex}.criteria` as const,
  });

  return (
    <div>
      {fields.map((item, index) => {
        const hasError = errors.find((error) => error.type === invoiceRules[categoryIndex].criteria[index].type);

        return (
          <Fragment key={item.id}>
            <div
              className={cn(
                'flex w-full items-center justify-between gap-4 rounded-md border border-stroke bg-canvas p-4',
                {
                  'border-red-600': !!hasError,
                }
              )}
            >
              <div className="flex gap-4">
                <div>{criteriaIconMap[category]}</div>
                <div>
                  <div className="mb-2 font-medium">{optionDescription}</div>
                  {category === 'invoiceId' ? (
                    <Controller
                      control={control}
                      name={`invoiceRules.${categoryIndex}.criteria.${index}.value`}
                      render={({ field }) => (
                        <div className="flex w-56 flex-col lg:w-[396px]">
                          <TextInput
                            {...field}
                            aria-label={invoiceRules[categoryIndex].criteria[index].type}
                            className="w-full"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              if (e.target.value.length > 15) {
                                setErrors([
                                  ...errors,
                                  {
                                    type: 'excludedVoucherIds',
                                    message: t('invoiceSettings.rules.invoiceIdErrorInvalid'),
                                  },
                                ]);
                              } else {
                                setErrors(errors.filter((error) => error.type !== 'excludedVoucherIds'));
                              }
                            }}
                          />
                          <div className="mt-1 text-right">
                            <HelperText>
                              {watch('invoiceRules')[categoryIndex].criteria[index].value.length}/15
                            </HelperText>
                          </div>
                        </div>
                      )}
                    />
                  ) : (
                    <div className="flex flex-col items-start gap-3 lg:flex-row lg:items-center">
                      <Controller
                        control={control}
                        name={`invoiceRules.${categoryIndex}.criteria.${index}.type` as const}
                        render={({ field }) => (
                          <Select
                            onValueChange={(value) => {
                              const criteriaValue = value as RecurringRuleCriteria;
                              update(index, {
                                type: criteriaValue,
                                value: invoiceRules[categoryIndex].criteria[index].value,
                              });
                            }}
                            value={field.value}
                          >
                            <SelectTrigger
                              aria-label={t('invoiceSettings.modal.criteriaSelect')}
                              className="w-56 lg:w-64 lg:shrink-0"
                            >
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              {options.map(({ label, value }) => (
                                <SelectItem key={value} value={value}>
                                  {label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                      <div className="flex items-center gap-3">
                        {/* rule value input */}
                        <Controller
                          control={control}
                          name={`invoiceRules.${categoryIndex}.criteria.${index}.value`}
                          render={({ field }) => {
                            // return date picker for due date
                            if (category === 'dueDate') {
                              return (
                                <DatePicker
                                  className="z-50"
                                  onOpenChange={() =>
                                    toggleDatePicker(invoiceRules[categoryIndex].criteria[index].type)
                                  }
                                  onSelect={(date) => {
                                    const dateString = dateAsIsoString(date);
                                    field.onChange(dateString);
                                    toggleDatePicker(invoiceRules[categoryIndex].criteria[index].type);
                                  }}
                                  open={
                                    invoiceRules[categoryIndex].criteria[index].type === 'toDueDate'
                                      ? dueBeforeDatePickerOpen
                                      : dueAfterDatePickerOpen
                                  }
                                  selected={field.value}
                                  trigger={
                                    <div
                                      aria-label={invoiceRules[categoryIndex].criteria[index].type}
                                      className="flex h-12 w-32 items-center rounded border border-stroke bg-white px-4 py-3 text-text-primary"
                                      role="button"
                                    >
                                      {field.value}
                                    </div>
                                  }
                                  disabledOptions={{ before: new Date() }}
                                />
                              );
                            }

                            return (
                              <TextInput
                                {...field}
                                aria-label={invoiceRules[categoryIndex].criteria[index].type}
                                className="w-32 shrink-0"
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  if (hasError) {
                                    setErrors(
                                      errors.filter(
                                        (error) => error.type !== invoiceRules[categoryIndex].criteria[index].type
                                      )
                                    );
                                  }
                                }}
                              />
                            );
                          }}
                        />
                        {/* amount input addon */}
                        {category === 'amount' && <div>{toCurrency}</div>}
                        {/* dpe input addon */}
                        {category === 'dpe' && <div>{t('core.days')}</div>}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <IconButton
                  className="bg-transparent"
                  disabled={categoryLength <= 1}
                  icon={TrashCanIcon}
                  inverse
                  name={t('recurringRules.removeRuleCriteria')}
                  onClick={categoryRemove}
                  size="sm"
                  variant="destructive"
                />
              </div>
            </div>
            {hasError && hasError.message && <div className="mt-1 text-sm text-red-600">{hasError.message}</div>}
          </Fragment>
        );
      })}
    </div>
  );
};

export default CriteriaOptions;
