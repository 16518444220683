import { isBefore } from 'date-fns';
import { MarketClearSchedules } from '@/enterprise/data/useClearSchedules';

export type CalendarClearTimeMap = Map<string, Date | undefined>;

const buildClearScheduleMap = (clearSchedules?: MarketClearSchedules): CalendarClearTimeMap | undefined => {
  const clearTimes = clearSchedules?.clearSchedules?.map((cs) => new Date(cs.clearTime));

  if (!clearTimes || clearTimes.length === 0) {
    return undefined;
  }

  const calendarClearTimeMap: CalendarClearTimeMap = new Map();

  // Loop from today to end of clearTimes array
  for (
    let date = new Date(); // Starting from today in local timezone
    isBefore(date, new Date(clearTimes[clearTimes.length - 1])); // While iterator date is before last clear converted to local timezone
    date.setDate(date.getDate() + 1) // Increment the iterator date by 1 day
  ) {
    const matchingClearTime = clearTimes.find(
      (clearTime) => new Date(clearTime).toDateString() === date.toDateString()
    );
    calendarClearTimeMap.set(date.toDateString(), matchingClearTime);
  }

  return calendarClearTimeMap;
};

export default buildClearScheduleMap;
