import { format } from 'date-fns';
import { TFunction } from 'i18next';
import { OfferSchedule, RecurringSchedule } from '@/enterprise/data/useOfferSchedules';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { Cadence, DaysOfTheWeek, OfferModalForm, WeeklyCadenceValue } from './types';

export const getNormalizedRate = (rateInput: string) => Math.round(parseFloat(rateInput) * 100) / 100;

export const validateRateInputForTakerMarket = ({
  takerMarket,
  rateInput,
  rateType,
  t,
}: {
  takerMarket: TakerMarket;
  rateInput: string;
  rateType: 'apr' | 'disc';
  t: TFunction;
}): {
  valid: boolean;
  errorMessage?: string;
} => {
  // Benchmark markets do not accept a rate
  if (takerMarket.marketPricingType === 'BENCHMARK') {
    return {
      valid: true,
    };
  }

  const rate = getNormalizedRate(rateInput);

  // Not a valid rate
  if (rateInput === '' || isNaN(rate)) {
    return {
      valid: false,
      errorMessage: t('core.required'),
    };
  }

  // Static markets only accept rates at or above the static market rate, and we only support APR at this time.
  if (takerMarket.marketPricingType === 'STATIC') {
    const isRateValid = rateType === 'apr' && rate >= (takerMarket.staticMarketRate ?? 0);

    return {
      valid: isRateValid,
      errorMessage: isRateValid ? undefined : t('offerSubmitDialog.fixedRate.rateInputError'),
    };
  }

  // Last is checks for price discovery markets

  if (rateType === 'disc') {
    const low = 0;
    const high = takerMarket.marketMaxDiscount ?? 30;

    const isRateValid = rate >= low && rate <= high;

    return {
      valid: isRateValid,
      errorMessage: isRateValid
        ? undefined
        : t('offerSubmitDialog.errors.discRateBetween', {
            low,
            high,
          }),
    };
  }

  if (rateType === 'apr') {
    const low = 0;
    const high = takerMarket.marketMaxApr ?? 99;

    const isRateValid = rate >= low && rate <= high;

    return {
      valid: isRateValid,
      errorMessage: isRateValid
        ? undefined
        : t('offerSubmitDialog.errors.aprRateBetween', {
            low,
            high,
          }),
    };
  }

  return {
    valid: false,
    errorMessage: t('core.required'),
  };
};

export const getDefaultRateType = (takerMarket: TakerMarket) => {
  if (takerMarket.marketPricingType === 'STATIC') {
    return 'apr';
  }

  return !takerMarket.offerConfig.id || takerMarket.offerConfig.isDiscountBidding === null
    ? 'disc' // default to discount for net new offers
    : takerMarket.offerConfig.isDiscountBidding
    ? 'disc'
    : 'apr';
};

/**
 * Checks if the given duration values equates to the same as a regular ongoing offer,
 * aka there should not be an offer schedule entity at all.
 */
export const checkIfDurationIsATypicalOngoingOffer = ({
  startDate,
  endDate,
  cadence,
}: {
  startDate: OfferModalForm['startDate'];
  endDate: OfferModalForm['endDate'];
  cadence: OfferModalForm['cadence'];
}) => {
  return startDate === undefined && endDate === undefined && cadence.type === 'daily';
};

export const buildCustomScheduleSentence = ({
  startDate,
  endDate,
  cadence,
  t,
}: {
  startDate: OfferModalForm['startDate']; // YYYY-MM-DD
  endDate: OfferModalForm['endDate']; // YYYY-MM-DD
  cadence: OfferModalForm['cadence'];
  t: TFunction;
}) => {
  if (checkIfDurationIsATypicalOngoingOffer({ startDate, endDate, cadence })) {
    return t('scheduling.ongoing');
  }

  const startDateFormatted = startDate
    ? format(new Date(`${startDate}T00:00:00`), 'yyyy-MM-dd').replace(/-/g, '‑')
    : undefined;
  const endDateFormatted = endDate
    ? format(new Date(`${endDate}T00:00:00`), 'yyyy-MM-dd').replace(/-/g, '‑')
    : undefined;
  const daysOfWeekCommaSeparated = cadence.value
    ?.sort((a: WeeklyCadenceValue, b: WeeklyCadenceValue) => {
      return DaysOfTheWeek.indexOf(a) - DaysOfTheWeek.indexOf(b);
    })
    .map((day: WeeklyCadenceValue) => t(`scheduling.daysOfWeek.${day}`))
    .join(', ');

  if (cadence.type === 'daily') {
    // Participates Daily starting 2025-03-25 through 2025-04-05
    if (startDate && endDate) {
      return t('scheduling.dailyWithStartDateAndEndDate', {
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      });
    }

    // Participates Daily starting 2025-03-25
    if (startDate && !endDate) {
      return t('scheduling.dailyWithStartDate', {
        startDate: startDateFormatted,
      });
    }

    // Participates Daily ending 2025-04-05
    if (!startDate && endDate) {
      return t('scheduling.dailyWithEndDate', {
        endDate: endDateFormatted,
      });
    }
  }

  if (cadence.type === 'weekly' && cadence.value && cadence.value.length > 0) {
    // Participates every Monday, Wednesday, Friday starting 2025-03-25 through 2025-04-05
    if (startDate && endDate) {
      return t('scheduling.daysOfWeekWithStartDateAndEndDate', {
        daysOfWeekCommaSeparated,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      });
    }

    // Participates every Monday, Wednesday, Friday starting 2025-03-25
    if (startDate && !endDate) {
      return t('scheduling.daysOfWeekWithStartDate', {
        daysOfWeekCommaSeparated,
        startDate: startDateFormatted,
      });
    }

    // Participates every Monday, Wednesday, Friday ending 2025-04-05
    if (!startDate && endDate) {
      return t('scheduling.daysOfWeekWithEndDate', {
        daysOfWeekCommaSeparated,
        endDate: endDateFormatted,
      });
    }

    // Participates every Monday, Wednesday, Friday
    if (!startDate && !endDate) {
      return t('scheduling.daysOfWeek', {
        daysOfWeekCommaSeparated,
      });
    }
  }

  // If for some reason we don't catch any other condition, fall back to generic custom language
  return t('scheduling.custom');
};

export const buildCadenceFromRecurringSchedule = (schedule: OfferSchedule['schedule']): Cadence | null => {
  const scheduleCadence = (schedule as RecurringSchedule).daysOfWeek;

  if (scheduleCadence) {
    return DaysOfTheWeek.every((day) => scheduleCadence.includes(day))
      ? { type: 'daily' }
      : { type: 'weekly', value: scheduleCadence };
  } else {
    return null;
  }
};
