/**
 * feature flags namespace "enterprise-ui_"
 */
export const Features = {
  'enterprise-ui_enableRecurringRules': 'enterprise-ui_enableRecurringRules',
  'enterprise-ui_enableRecurringRulesForAccounts': 'enterprise-ui_enableRecurringRulesForAccounts',
  'enterprise-ui_fedRateBanner': 'enterprise-ui_fedRateBanner',
  'enterprise-ui_enablePendingAndFutureAwardsEnhancement': 'enterprise-ui_enablePendingAndFutureAwardsEnhancement',
  'enterprise-ui_enableSeaFindYourCustomer': 'enterprise-ui_enableSeaFindYourCustomer',
  'enterprise-ui_enableSmbExperience': 'enterprise-ui_enableSmbExperience',
  'enterprise-ui_enableSalesforceInformationRequests': 'enterprise-ui_enableSalesforceInformationRequests',
  'enterprise-ui_enableOfferScheduling': 'enterprise-ui_enableOfferScheduling',
} as const;

/**
 * treatment definitions by env
 * utilized for determining how to serve treatment if split fails to load
 */
const features =
  import.meta.env.VITE_ENV === 'prod'
    ? {
        [Features['enterprise-ui_enableRecurringRules']]: 'off',
        [Features['enterprise-ui_enableRecurringRulesForAccounts']]: 'off',
        [Features['enterprise-ui_fedRateBanner']]: 'off',
        [Features['enterprise-ui_enablePendingAndFutureAwardsEnhancement']]: 'off',
        [Features['enterprise-ui_enableSeaFindYourCustomer']]: 'off',
        [Features['enterprise-ui_enableSmbExperience']]: 'off',
        [Features['enterprise-ui_enableSalesforceInformationRequests']]: 'off',
        [Features['enterprise-ui_enableOfferScheduling']]: 'off',
      }
    : {
        [Features['enterprise-ui_enableRecurringRules']]: 'on',
        [Features['enterprise-ui_enableRecurringRulesForAccounts']]: 'on',
        [Features['enterprise-ui_fedRateBanner']]: 'on',
        [Features['enterprise-ui_enablePendingAndFutureAwardsEnhancement']]: 'on',
        [Features['enterprise-ui_enableSeaFindYourCustomer']]: 'on',
        [Features['enterprise-ui_enableSmbExperience']]: 'on',
        [Features['enterprise-ui_enableSalesforceInformationRequests']]: 'on',
        [Features['enterprise-ui_enableOfferScheduling']]: 'on',
      };

export default features;
