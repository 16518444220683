import { Suspense } from 'react';
import { Controller } from 'react-hook-form';
import { Chip, cn, Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@c2fo/liquidity';
import Skeleton from '@/enterprise/components/Skeleton';
import useClearSchedules from '@/enterprise/data/useClearSchedules';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { CadenceType, DaysOfTheWeek, WeeklyCadenceValue } from '../types';
import useOfferModalFormContext from '../useOfferModalFormContext';

interface CadenceOptions {
  label: string;
  type: CadenceType;
}

const cadenceOptions: CadenceOptions[] = [
  { label: 'Daily', type: 'daily' },
  { label: 'Weekly', type: 'weekly' },
];

const WeekdaySelection = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { control } = useOfferModalFormContext();
  const { data } = useClearSchedules({ takerMarkets: [takerMarket] });

  const finalClears = data
    .flatMap((i) => i.clearSchedules ?? [])
    // SRM and product have indicated that in this case, we only care about final clears
    .filter((cs) => cs.isFinalClear === true)
    // At time of launch, all schedules will operate in US-Central time
    .map((cs) => {
      const clearTimeInUsCentral = new Date(
        new Date(cs.clearTime).toLocaleString('en-US', { timeZone: 'America/Chicago' })
      );
      return {
        ...cs,
        clearTimeInUsCentral,
        clearTimeDayOfWeekInUsCentral: clearTimeInUsCentral.toLocaleString('en-US', { weekday: 'long' }),
      };
    });

  const uniqueClearDays = [...new Set(finalClears.map((clear) => clear.clearTimeDayOfWeekInUsCentral))].sort((a, b) => {
    return DaysOfTheWeek.indexOf(a as WeeklyCadenceValue) - DaysOfTheWeek.indexOf(b as WeeklyCadenceValue);
  });

  return (
    <>
      <Controller
        control={control}
        name="cadence.value"
        render={({ field }) => (
          <div>
            <Label>On</Label>
            <div className="flex flex-wrap gap-2 pt-1">
              {DaysOfTheWeek.map((day) => (
                <Chip
                  key={day}
                  label={day}
                  type="info"
                  isRound
                  size="lg"
                  variant={field.value?.find((val) => val === day) ? 'filled' : 'outlined'}
                  disabled={!uniqueClearDays.includes(day)}
                  onClick={() => {
                    field.onChange(
                      field.value?.find((val) => val === day)
                        ? field.value.filter((val) => val !== day)
                        : [...(field.value ? field.value : []), day]
                    );
                  }}
                />
              ))}
            </div>
          </div>
        )}
        // TODO: Translate
        rules={{ required: 'Select a day or reset the repeating schedule to daily' }}
      />

      <p className="text-sm text-text-secondary">
        {/* TODO: Translate */}
        {uniqueClearDays.length > 0 ? (
          <>This market is projected to process every {uniqueClearDays.join(', ')}.</>
        ) : (
          <>This market currently has no projected processing days to select.</>
        )}
      </p>
    </>
  );
};

const WeekdaySelectionSkeleton = () => {
  return (
    <div className="space-y-3">
      <Skeleton className="h-6 w-12 rounded-3xl" />
      <div className="flex flex-wrap gap-2">
        <Skeleton className="h-12 w-24 rounded-3xl" />
        <Skeleton className="h-12 w-24 rounded-3xl" />
        <Skeleton className="h-12 w-24 rounded-3xl" />
        <Skeleton className="h-12 w-24 rounded-3xl" />
        <Skeleton className="h-12 w-24 rounded-3xl" />
      </div>
      <Skeleton className="h-6 w-[75%] rounded-3xl" />
    </div>
  );
};

const CadenceInput = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useOfferModalFormContext();
  const cadenceType = watch('cadence.type');

  return (
    <div className="space-y-4">
      {/* TODO: Translate */}
      <div className="space-y-1">
        <Label id="cadence-label">Repeat</Label>
        <Select
          onValueChange={(value) => {
            setValue('cadence.type', value as CadenceType);
            setValue('cadence.value', value === 'daily' ? DaysOfTheWeek : []);
          }}
          value={cadenceType}
        >
          <SelectTrigger aria-label="cadence" className="w-64" aria-labelledby="cadence-label">
            <SelectValue defaultValue="daily" />
          </SelectTrigger>
          <SelectContent>
            {cadenceOptions.map(({ label, type }) => (
              <SelectItem key={type} value={type}>
                {label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {cadenceType === 'weekly' && (
        <div className="space-y-1">
          <div
            className={cn('space-y-4 rounded-lg border border-blue-100 bg-blue-100 p-4 sm:p-6', {
              'border-red-600': errors.cadence?.value,
            })}
          >
            <Suspense fallback={<WeekdaySelectionSkeleton />}>
              <WeekdaySelection takerMarket={takerMarket} />
            </Suspense>
          </div>
          {errors.cadence?.value && <p className="text-sm text-red-600">{errors.cadence.value.message}</p>}
        </div>
      )}
    </div>
  );
};

export default CadenceInput;
