export type Steps = 'category' | 'criteria' | 'division' | 'summary';

export interface RecurringRulesFormState {
  availableSteps: Steps[];
  currentStep: Steps;
  display: 'form' | 'success';
  isEditMode: boolean;
}

export type RecurringRulesFormAction =
  | {
      type: 'setDisplay';
      payload: { display: RecurringRulesFormState['display'] };
    }
  | {
      type: 'stepBack';
    }
  | {
      type: 'stepForward';
    }
  | {
      type: 'stepTo';
      payload: { step: Steps };
    }
  | {
      type: 'createAnother';
    }
  | {
      type: 'setEditMode';
      payload: { isEditMode: RecurringRulesFormState['isEditMode'] };
    };

export const getInitialState = ({
  isForSingleDivision,
  mode,
}: {
  isForSingleDivision?: boolean;
  mode?: 'edit' | 'create';
} = {}): RecurringRulesFormState => {
  const availableSteps: Steps[] = isForSingleDivision
    ? ['category', 'criteria', 'summary']
    : ['category', 'criteria', 'division', 'summary'];

  return {
    availableSteps,
    currentStep: 'category',
    display: 'form',
    isEditMode: mode === 'edit',
  };
};

export const recurringRulesFormReducer = (state: RecurringRulesFormState, action: RecurringRulesFormAction) => {
  const { type } = action;

  switch (type) {
    case 'setDisplay': {
      return {
        ...state,
        display: action.payload.display,
      };
    }

    case 'stepBack': {
      const currentStep = state.currentStep;
      const currentStepIndex = state.availableSteps.indexOf(currentStep);
      const prevStep: RecurringRulesFormState['currentStep'] =
        currentStepIndex === 0 ? currentStep : state.availableSteps[currentStepIndex - 1];

      return {
        ...state,
        currentStep: prevStep,
      };
    }

    case 'stepForward': {
      const currentStep = state.currentStep;
      const currentStepIndex = state.availableSteps.indexOf(currentStep);
      const nextStep: RecurringRulesFormState['currentStep'] =
        currentStepIndex === state.availableSteps.length - 1 ? currentStep : state.availableSteps[currentStepIndex + 1];

      return {
        ...state,
        currentStep: nextStep,
      };
    }

    case 'stepTo': {
      return {
        ...state,
        currentStep: action.payload.step,
      };
    }

    case 'createAnother': {
      const updatedState = getInitialState();

      return {
        ...state,
        ...updatedState,
      };
    }

    case 'setEditMode': {
      return {
        ...state,
        isEditMode: action.payload.isEditMode,
      };
    }

    default:
      return state;
  }
};
