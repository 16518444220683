import { useCallback } from 'react';
import { GetMeQuery } from '@/generated/gql/graphql';
import useUserDetails from '@/shared/data/useUserDetails';
import useFeature from '../lib/features';

/**
 * Returns true if the user is qualified to notify SRM. checks if supplier is Sun or Sweet and the split flag is enabled.
 * This hook is behind a feature flag to enable Salesforce information requests.
 *
 * @returns boolean
 */
const useIsAccountQualifiedToNotifySrm = () => {
  const { data: segment } = useUserDetails(useCallback((user: GetMeQuery) => user.me?.account?.segment, []));

  // Turn ON salesforce information requests for Sun and Sweet suppliers if the split flag is turned ON.
  // Right now this hook is used only to enable Salesforce information requests for Sun and Sweet suppliers.
  // So it is better adding split check here for now instead of making this condition in all information request related components.
  const [enableSalesforceInformationRequests] = useFeature('enterprise-ui_enableSalesforceInformationRequests');

  const isSunOrSweetSupplier = segment ? ['Sun', 'Sweet'].includes(segment) : false;

  return enableSalesforceInformationRequests ? isSunOrSweetSupplier : false;
};

export default useIsAccountQualifiedToNotifySrm;
