export type CadenceType = 'daily' | 'weekly';

export type WeeklyCadenceValue = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

export const DaysOfTheWeek: WeeklyCadenceValue[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

export interface Cadence {
  type: CadenceType;
  value?: WeeklyCadenceValue[];
}

export interface OfferModalForm {
  rateStr: string;
  rateTypeStr: 'apr' | 'disc';
  participationType: 'ongoing' | 'scheduled';
  isAgreementsChecked: boolean;
  cadence: Cadence;
  startDate?: string;
  endDate?: string;
}
