import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, cn } from '@c2fo/liquidity';
import { AlarmClockIcon, CalendarIcon, MoneyBillWaveIcon, SquareQuoteIcon } from '@c2fo/liquidity/icons';
import { RecurringRuleCategory } from '@/enterprise/data/useRecurringRules';
import defaultRuleValues from '@/enterprise/features/recurringRules/utils/defaultRuleValues';
import useRecurringRuleFormContext from '@/enterprise/features/recurringRules/utils/useRecurringRuleFormContext';
import { RecurringRuleFormError } from '@/enterprise/features/recurringRules/utils/useValidateRuleForm';

interface RuleCategoryOption {
  description: string;
  icon: React.ElementType;
  label: string;
  value: RecurringRuleCategory;
}

const ruleCategoryOptions: RuleCategoryOption[] = [
  {
    description: 'recurringRules.dpe.category.description',
    icon: CalendarIcon,
    label: 'core.daysPaidEarly',
    value: 'dpe',
  },
  {
    description: 'recurringRules.invoiceAmount.category.description',
    icon: MoneyBillWaveIcon,
    label: 'core.invoiceAmount',
    value: 'amount',
  },
  {
    description: 'recurringRules.dueDate.category.description',
    icon: AlarmClockIcon,
    label: 'recurringRules.dueDate.category.label',
    value: 'dueDate',
  },
  {
    description: 'recurringRules.invoiceId.category.description',
    icon: SquareQuoteIcon,
    label: 'recurringRules.invoiceId.category.label',
    value: 'invoiceId',
  },
];

const CategorySelect = ({
  errors,
  setErrors,
}: {
  errors: RecurringRuleFormError[];
  setErrors: Dispatch<SetStateAction<RecurringRuleFormError[]>>;
}) => {
  const { t } = useTranslation();
  const { setValue, watch } = useRecurringRuleFormContext();
  const invoiceRules = watch('invoiceRules');
  const selectedCategories = invoiceRules.map(({ category }) => category);

  const onCategorySelect = (value: RecurringRuleCategory) => {
    if (errors.length > 0) {
      setErrors([]);
    }

    const categoryExists = selectedCategories.includes(value);

    const updatedCategories = categoryExists
      ? invoiceRules.filter(({ category }) => category !== value)
      : [...invoiceRules, { category: value, criteria: [defaultRuleValues[value]] }];

    setValue('invoiceRules', updatedCategories);
  };

  const hasError = errors.find((error) => error.type === 'categories');

  return (
    <>
      <div className="mb-6 text-sm">{t('recurringRules.createARuleDescription')}</div>
      {hasError && hasError.message && <p className="mb-1 text-sm text-red-600">{hasError.message}</p>}
      <div className="flex flex-col space-y-3">
        {ruleCategoryOptions.map((option) => {
          const Icon = option.icon;
          const isSelected = selectedCategories.includes(option.value);

          return (
            <button
              key={option.value}
              aria-label={t(option.label)}
              className={cn(
                'flex items-center gap-4 rounded border border-transparent bg-canvas p-4 shadow hover:border-primary-500',
                {
                  'border-primary-500': isSelected,
                  'border-red-600 hover:border-red-600': !!hasError,
                }
              )}
              onClick={() => onCategorySelect(option.value)}
              type="button"
            >
              <div>
                <Checkbox color="primary" checked={isSelected} readOnly />
              </div>
              <div className="flex items-center gap-4">
                <div className="text-left">
                  <div className="font-medium">{t(option.label)}</div>
                  <div className="text-sm text-text-secondary">{t(option.description)}</div>
                </div>
              </div>
              <div className="ml-auto">
                <Icon className="h-6 w-6 shrink-0 fill-gray-600" />
              </div>
            </button>
          );
        })}
      </div>
    </>
  );
};

export default CategorySelect;
