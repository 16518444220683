import { useSuspenseQuery } from '@tanstack/react-query';
import qs from 'qs';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';
import { TakerMarket } from './useTakerMarkets';

interface ClearSchedule {
  clearTime: string;
  payDate: string;
  isFinalClear: boolean;
}

export interface MarketClearSchedules {
  marketUuid: string;
  supplierDivisionUuid: string;
  makerOrganizationUuid: string;
  clearSchedules?: ClearSchedule[];
}

interface ClearSchedulesQueryOptions {
  takerMarkets?: TakerMarket[];
  startDate?: string;
  endDate?: string;
}

const fetchClearSchedules = async (options?: ClearSchedulesQueryOptions): Promise<MarketClearSchedules[]> => {
  const makerOrganizationUuidsFilter = options?.takerMarkets?.map((tm) => tm.makerOrganizationUuid);
  const supplierMarketsFilter = options?.takerMarkets?.map((tm) => ({
    marketUuid: tm.marketUuid,
    supplierDivisionUuid: tm.takerDivisionUuid,
  }));

  const response = await apiClient
    .get(`api/supplier-experience/supplier-markets/clear-schedules`, {
      searchParams: qs.stringify(
        {
          makerOrganizationUuids: makerOrganizationUuidsFilter,
          supplierMarkets: supplierMarketsFilter,
        },
        { arrayFormat: 'indices' }
      ),
    })
    .json<paths['/supplier-markets/clear-schedules']['get']['responses']['200']['content']['application/json']>();

  const marketClearSchedules = response.data.supplierMarketClearSchedulesGroups
    .map((smcsg) => smcsg.supplierMarketClearSchedules)
    .flatMap((smcs) => smcs);

  return marketClearSchedules;
};

const useClearSchedules = (options?: ClearSchedulesQueryOptions) => {
  return useSuspenseQuery({
    queryKey: ['clear-schedules', options?.takerMarkets?.map((tm) => tm.marketUuid)],
    queryFn: () => (!!options?.takerMarkets ? fetchClearSchedules(options) : []),
  });
};

export default useClearSchedules;
