import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY_RECURRING_RULES } from '@/enterprise/data/useRecurringRules';
import {
  useRefetchStatsQueries,
  useServerSideEventListeners,
} from '@/lib/serverSentEvents/useServerSideEventListeners';

export const useRefetchAndSubscribeForRules = () => {
  const queryClient = useQueryClient();
  const { refetchOneTakerMarket } = useRefetchStatsQueries();
  const { subscribeToMarketStats } = useServerSideEventListeners();

  const refetchSEASupplierMarketsRules = useCallback(
    async (takerMarkets: { marketUuid: string; takerUuid: string }[]) => {
      if (takerMarkets.length === 0) {
        return;
      }

      try {
        if (takerMarkets && takerMarkets.length > 0) {
          queryClient.refetchQueries({
            queryKey: [QUERY_KEY_RECURRING_RULES],
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [queryClient]
  );

  const refetchAndSubscribeRules = useCallback(
    ({
      takerMarkets,
    }: {
      takerMarkets: {
        marketUuid: string;
        takerId: number;
        takerUuid: string;
      }[];
    }) => {
      refetchSEASupplierMarketsRules(takerMarkets);

      takerMarkets.forEach(({ marketUuid, takerId }) => {
        refetchOneTakerMarket({ marketUuid, takerId });
        queryClient.refetchQueries({ queryKey: ['eligible-invoice-stats', marketUuid, takerId] });
        queryClient.refetchQueries({ queryKey: ['eligible-invoice-stats-filtered', marketUuid, takerId] });
      });

      takerMarkets.forEach(({ marketUuid, takerId }) => {
        subscribeToMarketStats({
          marketUuid,
          takerId,
        });
      });
    },
    [queryClient, refetchOneTakerMarket, subscribeToMarketStats, refetchSEASupplierMarketsRules]
  );

  return { refetchAndSubscribeRules };
};

export default useRefetchAndSubscribeForRules;
