import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { useReporting } from '@/reporting';
import QueryBoundaries from '@/shared/components/QueryBoundaries';

const PageTrackerComponent = () => {
  const location = useLocation();
  const { trackMultiExperienceEvent } = useReporting();

  // sent when the app loads
  useEffectOnce(() => {
    trackMultiExperienceEvent('app::initialized');
  });

  // send when the page changes
  useEffect(() => {
    trackMultiExperienceEvent('app::page-change');
  }, [location, trackMultiExperienceEvent]);

  return null;
};

const PageTracker = () => (
  <QueryBoundaries>
    <PageTrackerComponent />
  </QueryBoundaries>
);

export default PageTracker;
