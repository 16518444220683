import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { CaretDownFilledIcon } from '@c2fo/liquidity/icons';
import { useReporting } from '@/reporting';
import { MultiExperienceTrackEventNames } from '@/reporting/trackEventNames';
import QueryBoundaries from '@/shared/components/QueryBoundaries';
import useUserDetails from '@/shared/data/useUserDetails';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';
import logout from '@/utils/logout';
import { useAgreementsUrl } from '@/utils/useAgreementsUrl';
import useNewExperienceEnabled from '@/utils/useNewExperienceEnabled';

export const useUserName = () => {
  const { data } = useUserDetails();
  return `${data?.me?.personalInfo?.firstName} ${data?.me?.personalInfo?.lastName}`;
};

interface UserMenuItem {
  name: string;
  label: string;
  href?: string;
  onClick?: () => void;
  track: MultiExperienceTrackEventNames;
  openInNewWindow?: boolean;
}

const UserMenuComponent = ({ itemsOnly = false, onClose }: { itemsOnly?: boolean; onClose?: () => void }) => {
  const { t } = useTranslation();
  const name = useUserName();
  const { trackMultiExperienceEvent } = useReporting();
  const { privacyLink } = useAgreementsUrl();
  const newExperienceEnabled = useNewExperienceEnabled();

  const items: UserMenuItem[] = [
    {
      name: 'privacy',
      label: t('core.privacyPolicy'),
      href: privacyLink,
      track: 'user-menu::privacy-policy::clicked',
      openInNewWindow: true,
    },
    {
      name: 'logout',
      label: t('core.logout'),
      onClick: () => logout(),
      track: 'user-menu::logout::clicked',
      openInNewWindow: false,
    },
  ];

  // only show account settings link if new experience is not enabled
  if (!newExperienceEnabled) {
    items.unshift({
      name: 'settings',
      label: t('core.accountSettings'),
      href: asEnterpriseLink('account/settings'),
      track: 'user-menu::account-settings::clicked',
      openInNewWindow: false,
    });
  }

  return itemsOnly ? (
    <>
      {items
        ?.filter((item) => item.name !== 'logout')
        .map((item) => (
          <div key={item.name} className="relative flex w-full border-b last:border-b-0">
            {item.openInNewWindow ? (
              <a
                className="flex grow justify-between gap-2 border-l-4 border-l-transparent px-4 py-3 font-normal text-text-primary transition-all duration-200 hover:bg-lightBlue-50"
                href={item.href!}
                onClick={() => {
                  trackMultiExperienceEvent(item.track);
                  if (onClose) {
                    onClose();
                  }
                }}
                rel="noreferrer"
                target="_blank"
                type="button"
              >
                {item.label}
              </a>
            ) : (
              <Link
                className="flex grow justify-between gap-2 border-l-4 border-l-transparent px-4 py-3 font-normal text-text-primary transition-all duration-200 hover:bg-lightBlue-50"
                onClick={() => {
                  trackMultiExperienceEvent(item.track);
                  if (onClose) {
                    onClose();
                  }
                }}
                to={item.href!}
              >
                {item.label}
              </Link>
            )}
          </div>
        ))}
    </>
  ) : (
    <DropdownMenu>
      <DropdownMenuTrigger aria-label={t('core.userMenu')}>
        <div className="flex h-9 items-center space-x-1 whitespace-normal">
          <span>{name}</span>
          <CaretDownFilledIcon className="hidden h-5 w-5 sm:block" fill={colors.secondary[500]} aria-hidden="true" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {items.map((item) => {
          return item?.href ? (
            <DropdownMenuItem key={item.name} asChild onClick={() => trackMultiExperienceEvent(item.track)}>
              {item.openInNewWindow ? (
                <a href={item.href} rel="noreferrer" target="_blank">
                  {item.label}
                </a>
              ) : (
                <Link to={item.href}>{item.label}</Link>
              )}
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem
              key={item.name}
              onClick={async () => {
                // Logging out will delete user auth token, so ensure we have fired track event before logging out
                item.name === 'logout'
                  ? await trackMultiExperienceEvent(item.track)
                  : trackMultiExperienceEvent(item.track);
                item.onClick && item.onClick();
              }}
            >
              {item.label}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const UserMenu = ({ itemsOnly = false, onClose }: { itemsOnly?: boolean; onClose?: () => void }) => (
  <QueryBoundaries>
    <UserMenuComponent itemsOnly={itemsOnly} onClose={onClose} />
  </QueryBoundaries>
);

export default UserMenu;
