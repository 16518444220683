import { useTranslation } from 'react-i18next';
import { Button, ButtonLink, Modal, ModalActions, ModalContent, ModalTitleInfo } from '@c2fo/liquidity';
import { asMultiExperienceLink } from '@/utils/experienceLinkHelper';
import conversationSvg from '../assets/conversation.svg?url';

interface SuccessModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title?: string;
  content?: string;
}

export const SuccessModal = ({ isOpen, setIsOpen, title, content }: SuccessModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} size="sm">
      <ModalTitleInfo>{title}</ModalTitleInfo>
      <ModalContent center>
        <img src={conversationSvg} alt="" className="h-52" />
        <p className="font-medium">{content}</p>
      </ModalContent>
      <ModalActions className="flex flex-col items-center bg-secondary-500 sm:flex-col">
        <Button className="w-full max-w-96" variant="cta" onClick={() => setIsOpen(false)}>
          {t('findYourCustomers.addMoreCustomers')}
        </Button>
        <ButtonLink
          className="w-full max-w-96 text-white hover:bg-transparent"
          variant="ancillary"
          to={asMultiExperienceLink()}
        >
          {t('core.returnToHome')}
        </ButtonLink>
      </ModalActions>
    </Modal>
  );
};
