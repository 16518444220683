import { useTranslation } from 'react-i18next';
import { Toggle, Tooltip } from '@c2fo/liquidity';

const OfferScheduleToggle = ({ isEnabled, variant }: { isEnabled: boolean; variant: 'dark' | 'light' }) => {
  const { t } = useTranslation();

  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <span className="inline-block">
          <Toggle accessibilityLabel={t('core.toggleParticipation')} checked={isEnabled} scheduled size="lg" />
        </span>
      </Tooltip.Trigger>
      <Tooltip.Content arrow side="bottom" variant={variant} className="max-w-60 whitespace-pre-wrap text-center">
        {t('scheduling.lockedParticipation')}{' '}
        <span className="italic">{t('scheduling.schedulesOperateUsCentral')}</span>
      </Tooltip.Content>
    </Tooltip>
  );
};

export default OfferScheduleToggle;
