import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { add } from 'date-fns';
import { Button, IconButton, Label } from '@c2fo/liquidity';
import { TrashCanIcon } from '@c2fo/liquidity/icons';
import DatePicker from '@/enterprise/components/DatePicker';
import { dateAsIsoString } from '@/utils/dateAsIsoString';
import useOfferModalFormContext from '../useOfferModalFormContext';

const StartOrEndDateInput = ({ type }: { type: 'start' | 'end' }) => {
  const { t } = useTranslation();
  const { watch, setValue } = useOfferModalFormContext();
  const [startDateFormValue, endDateFormValue] = watch(['startDate', 'endDate']);

  const setAppropriateValue = (date: Date | undefined) => {
    // Convert date to ISO string and extract just the date portion (YYYY-MM-DD), avoid timezone shenanigans
    const dateStr = date ? dateAsIsoString(date) : undefined;

    if (type === 'start') {
      setValue('startDate', dateStr);
    } else {
      setValue('endDate', dateStr);
    }

    if (!date) {
      setShowDatePicker(false);
    }
  };

  const displayDate = type === 'start' ? startDateFormValue : endDateFormValue;
  const [showDatePicker, setShowDatePicker] = useState(!!displayDate);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  useEffect(() => {
    if (!displayDate) {
      setShowDatePicker(false);
    }
  }, [displayDate]);

  // TODO: Translate
  const text = {
    start: {
      title: 'Start Date',
      buttonText: 'Set Start Date',
      label: 'Interested in setting a time for your participation to turn on?',
    },
    end: {
      title: 'End Date',
      buttonText: 'Set End Date',
      label: 'Interested in setting a time for your participation to turn off?',
    },
  };

  return !showDatePicker ? (
    <div className="flex items-center gap-1">
      <span className="text-base">{text[type].label}</span>
      <Button size="sm" variant="link" onClick={() => setShowDatePicker(true)}>
        {text[type].buttonText}
      </Button>
    </div>
  ) : (
    <div className="flex flex-col gap-1">
      {/* TODO: htmlFor */}
      <Label>{text[type].title}</Label>
      <div className="flex items-center justify-start">
        <DatePicker
          disabledOptions={
            // If user is selecting an end date and a start date exists, disable dates before the start date
            type === 'end' && !!startDateFormValue
              ? { before: add(new Date(startDateFormValue), { days: 1 }) }
              : type === 'start'
              ? { before: new Date(), ...(!!endDateFormValue && { after: new Date(endDateFormValue) }) }
              : undefined
          }
          open={datePickerOpen}
          onOpenChange={() => {
            setDatePickerOpen(!datePickerOpen);
          }}
          onSelect={(date) => {
            setDatePickerOpen(false);

            if (date) {
              setAppropriateValue(date);
            }
          }}
          selected={displayDate ? dateAsIsoString(new Date(displayDate)) : null}
          triggerClassName="pr-2 w-64"
          trigger={
            <div
              aria-label={t('core.selectDate')}
              className="flex h-12 w-full items-center justify-between rounded-md border bg-white px-4"
              role="button"
            >
              {displayDate ? displayDate : t('core.selectDate')}
            </div>
          }
        />
        <IconButton
          className="bg-transparent"
          icon={TrashCanIcon}
          inverse
          name="Remove selected date"
          onClick={() => {
            setAppropriateValue(undefined);
          }}
          size="sm"
          variant="destructive"
        />
      </div>
    </div>
  );
};

export default StartOrEndDateInput;
