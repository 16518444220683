import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, TextInput } from '@c2fo/liquidity';
import { PercentIcon } from '@c2fo/liquidity/icons';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { useOfferDiscountAPRDisplay } from '../../takerMarketTable/utils/useOfferDiscountAPRDisplay';
import useOfferModalFormContext from '../useOfferModalFormContext';
import { validateRateInputForTakerMarket } from '../utils';

export const StaticRateInput = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const { offerDiscountAPRDisplay } = useOfferDiscountAPRDisplay();
  const { control, register, formState, watch } = useOfferModalFormContext();

  const rateOptions = [{ value: 'apr', label: t('core.apr') }];

  return (
    <div className="grid grid-cols-2 gap-4 p-4">
      <div className="col-span-2">
        <p className="text-sm">
          {t('fixedRate.details.rateEntry', {
            rate: offerDiscountAPRDisplay(takerMarket.staticMarketRate ?? 0, t('core.aprAbbreviation')),
          })}
        </p>
      </div>
      <div className="space-y-2">
        <Label htmlFor="rateStr">{t('core.offerRate')}</Label>
        <TextInput
          type="number"
          className="w-full"
          hasError={!!formState.errors.rateStr}
          id="rateStr"
          endIcon={PercentIcon}
          {...register('rateStr', {
            required: true,
            validate: (rateInput) => {
              const { errorMessage } = validateRateInputForTakerMarket({
                rateInput,
                rateType: watch('rateTypeStr'),
                takerMarket,
                t,
              });

              return errorMessage;
            },
          })}
        />
        {formState.errors.rateStr?.message && (
          <p className="text-xs text-red-500">{t(formState.errors.rateStr.message)}</p>
        )}
      </div>
      <div className="space-y-2">
        <Label htmlFor="rateStr">{t('core.rateType')}</Label>
        <Controller
          control={control}
          name="rateTypeStr"
          render={({ field }) => (
            <Select onValueChange={field.onChange} value={field.value} disabled>
              <SelectTrigger aria-label={t('core.rateType')} hasError={!!formState.errors.rateTypeStr}>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {rateOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    <p>{option.label}</p>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          rules={{ required: true }}
        />
      </div>
    </div>
  );
};
