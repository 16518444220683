import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cn, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { CaretDownFilledIcon, LanguageIcon } from '@c2fo/liquidity/icons';
import useUpdateUserLanguage from '@/enterprise/data/useUpdateUserLanguage';
import { SUPPORTED_LOCALES, getLanguage } from '@/i18n';
import { useReporting } from '@/reporting';

export default function LanguageChangeMenu({
  itemsOnly = false,
  onClose,
}: {
  itemsOnly?: boolean;
  onClose?: () => void;
}) {
  const { i18n, t } = useTranslation();
  const { trackMultiExperienceEvent } = useReporting();
  const { mutate } = useUpdateUserLanguage();
  const language = useMemo(() => getLanguage(i18n.language), [i18n.language]);
  const items = useMemo(() => {
    return SUPPORTED_LOCALES.map((locale) => ({
      name: locale.code,
      label: locale.language,
      onClick: () => {
        i18n.changeLanguage(locale.code);
        // do not want this to block updating language
        // if it fails, we fallback to default behavior for language
        mutate({ locale: locale.code });
      },
    }));
  }, [i18n, mutate]);

  return itemsOnly ? (
    <>
      {items?.map((item) => (
        <div key={item.name} className="relative flex w-full border-b last:border-b-0">
          <button
            className={cn(
              'flex flex-grow justify-between gap-2 border-l-4 border-l-transparent px-4 py-3 font-normal text-text-primary transition-all duration-200 hover:bg-lightBlue-50',
              { 'border-l-lightBlue-500 bg-lightBlue-50': language === item.label }
            )}
            onClick={() => {
              item.onClick?.();
              onClose?.();
              trackMultiExperienceEvent('language::changed', { language: item.name });
            }}
            type="button"
          >
            {item.label}
          </button>
        </div>
      ))}
    </>
  ) : (
    <DropdownMenu>
      <DropdownMenuTrigger aria-label={t('profile.subscription.languagePreference')}>
        <div className="flex h-9 items-center space-x-1">
          <LanguageIcon className="hidden h-9 w-auto lg:block" fill={colors.secondary[500]} />
          <CaretDownFilledIcon className="hidden h-5 w-5 sm:block" fill={colors.secondary[500]} aria-hidden="true" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {items.map((item) => (
          <DropdownMenuItem
            key={item.name}
            onClick={() => {
              item.onClick?.();
              trackMultiExperienceEvent('language::changed', { language: item.name });
            }}
            selected={language === item.label}
          >
            {item.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
