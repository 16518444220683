import { Outlet } from 'react-router-dom';
import CalculatingMarketSnackbar from '@/enterprise/components/CalculatingMarketSnackbar';
import FileDownloadModal from '@/enterprise/components/FileDownloadModal';
import { ServerSentEvents, ServerSentEventsInterval } from '@/lib/serverSentEvents';
import { useDataDogRumUserAccountIdInitialize } from '@/reporting/DataDog';
import PageTracker from '@/reporting/PageTracker';
import { InitQualtrics } from '@/reporting/Qualtrics';
import AppLayout from '@/shared/components/AppLayout';

const App = () => {
  useDataDogRumUserAccountIdInitialize();

  return (
    <>
      <PageTracker />
      <CalculatingMarketSnackbar />
      <ServerSentEvents />
      <ServerSentEventsInterval />
      <InitQualtrics />
      <FileDownloadModal />
      <AppLayout experience="enterprise">
        <Outlet />
      </AppLayout>
    </>
  );
};

export default App;
