import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import jwtDecode from 'jwt-decode';
import { useEffectOnce } from 'react-use';
import { useToken, TokenContent } from '@/utils/token';

const useImpersonationMonitor = () => {
  const { tokenContent } = useToken();
  const [previousTokenContent, setPreviousTokenContent] = useState<TokenContent | null | undefined>(null);

  useEffectOnce(() => {
    // Set the token content when the app loads
    setPreviousTokenContent(tokenContent);
  });

  const { data: differentUser } = useQuery({
    queryKey: ['impersonation-monitor'],
    queryFn: () => {
      const tokenFromStorage = localStorage.getItem('token');
      const currentTokenContent = tokenFromStorage ? (jwtDecode(tokenFromStorage) as TokenContent) : null;
      const previousTokenWasImpersonate = !!previousTokenContent?.payload.impersonationSubject;

      if (!previousTokenWasImpersonate) {
        return false;
      }

      return (
        currentTokenContent?.payload.impersonationSubject?.emailAddress !==
        previousTokenContent?.payload.impersonationSubject?.emailAddress
      );
    },
    refetchOnWindowFocus: true,
  });
  return differentUser ?? false;
};

export default useImpersonationMonitor;
