import { ReactNode } from 'react';
import { cn } from '@c2fo/liquidity';
import Footer from '@/shared/components/Footer';
import { ImpersonationBanner } from '@/shared/components/ImpersonationBanner';
import Navigation from '@/shared/components/Navigation/Navigation';
import ReadOnlyBanner from '@/shared/components/ReadOnlyBanner';
import { isImpersonationSession } from '@/utils/token';
import { useReadOnlyUser } from '@/utils/useRestrictions';

export type ExperienceType = 'enterprise' | 'smb';

const AppLayout = ({ children, experience }: { children: ReactNode; experience: ExperienceType }) => {
  const readOnlyUser = useReadOnlyUser();
  const isImpersonation = isImpersonationSession();

  return (
    <>
      {isImpersonation && <ImpersonationBanner />}
      {readOnlyUser && <ReadOnlyBanner isImpersonation={isImpersonation} />}
      <div
        className={cn('flex min-h-screen flex-col', {
          'mt-10': isImpersonation || readOnlyUser,
          'mt-20': isImpersonation && readOnlyUser,
        })}
      >
        <Navigation experience={experience} />
        <div className="grow pt-16">
          <div className="mx-auto max-w-screen-2xl px-4 py-10 lg:px-6">{children}</div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AppLayout;
