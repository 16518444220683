import { IconButton } from '@c2fo/liquidity';
import { PencilIcon } from '@c2fo/liquidity/icons';
import { RecurringRule } from '@/enterprise/data/useRecurringRules';
import useRuleDescription from '@/enterprise/features/recurringRules/utils/useRuleDescription';

interface RulePreviewProps {
  currency: string;
  rule: Partial<RecurringRule>;
  onEdit?: () => void;
}

const RulePreview = ({ currency, onEdit, rule }: RulePreviewProps) => {
  const { labelText, daysPaidEarly, dueDate, invoiceAmount, excludedInvoiceIds } = useRuleDescription({
    currency,
    rule,
  });

  return rule ? (
    <div className="flex items-center justify-between gap-6 rounded-md bg-canvas p-4 text-sm">
      <div className="space-y-2">
        <div>{labelText}</div>
        <ul className="ml-4 list-disc space-y-0.5 font-bold">
          {daysPaidEarly.long && <li>{daysPaidEarly.long}</li>}
          {dueDate.long && <li>{dueDate.long}</li>}
          {invoiceAmount.long && <li>{invoiceAmount.long}</li>}
          {excludedInvoiceIds.long && <li>{excludedInvoiceIds.long}</li>}
        </ul>
      </div>
      {onEdit && (
        <div>
          <IconButton icon={PencilIcon} name="Edit Rule Criteria" onClick={onEdit} size="sm" variant="ancillary" />
        </div>
      )}
    </div>
  ) : null;
};

export default RulePreview;
