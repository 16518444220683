import { useSuspenseQueries } from '@tanstack/react-query';
import useEnableRecurringRules from '@/enterprise/features/recurringRules/utils/useEnableRecurringRules';
import useFeature from '@/lib/features';
import { fetchOfferSchedules, OfferSchedule, QUERY_KEY_OFFER_SCHEDULES } from './useOfferSchedules';
import {
  fetchSEAInvoiceRules,
  RecurringRule,
  QUERY_KEY_RECURRING_RULES,
  TakerMarketWithRecurringRule,
} from './useRecurringRules';
import useTakerMarkets, { TakerMarket } from './useTakerMarkets';

export interface TakerMarketWithAutomation {
  takerMarket: TakerMarket;
  automation: {
    invoiceRule?: RecurringRule;
    offerSchedule?: OfferSchedule;
  };
}

const transformAutomationQueryData = (
  offerSchedulesQueryData: OfferSchedule[] | null,
  recurringRulesQueryData: TakerMarketWithRecurringRule[] | null,
  takerMarkets: TakerMarket[]
): TakerMarketWithAutomation[] => {
  const schedules = offerSchedulesQueryData ?? [];
  const invoiceRules = recurringRulesQueryData ?? [];

  const takerMarketsWithAutomations = takerMarkets.map((takerMarket) => {
    const offerSchedule = schedules.find(
      (s) => s.marketUuid === takerMarket.marketUuid && s.supplierDivisionUuid === takerMarket.takerDivisionUuid
    );

    const takerMarketWithRecurringRule = invoiceRules.find(
      (r) =>
        r.takerMarket.marketUuid === takerMarket.marketUuid &&
        r.takerMarket.takerDivisionUuid === takerMarket.takerDivisionUuid
    );

    const invoiceRule = takerMarketWithRecurringRule?.rule;

    return {
      takerMarket,
      automation: {
        invoiceRule,
        offerSchedule,
      },
    } satisfies TakerMarketWithAutomation;
  });

  return takerMarketsWithAutomations.filter((takerMarketWithAutomation) => {
    const { automation } = takerMarketWithAutomation;

    // Return true if automation has any defined properties
    return Object.values(automation).some((value) => value !== undefined);
  });
};

const useAutomations = (): TakerMarketWithAutomation[] => {
  const { data: takerMarkets = [] } = useTakerMarkets();
  const enableRecurringRules = useEnableRecurringRules();
  const [enableOfferScheduling] = useFeature('enterprise-ui_enableOfferScheduling');

  const enabled = enableRecurringRules && enableOfferScheduling && takerMarkets.length > 0;

  const [offerSchedulesQuery, recurringRulesQuery] = useSuspenseQueries({
    queries: [
      {
        queryKey: [QUERY_KEY_OFFER_SCHEDULES],
        queryFn: () => (enabled ? fetchOfferSchedules() : null),
      },
      {
        queryKey: [QUERY_KEY_RECURRING_RULES],
        queryFn: () => (enabled ? fetchSEAInvoiceRules(takerMarkets) : null),
      },
    ],
  });

  return offerSchedulesQuery.isSuccess && recurringRulesQuery.isSuccess
    ? transformAutomationQueryData(offerSchedulesQuery.data, recurringRulesQuery.data, takerMarkets)
    : [];
};

export default useAutomations;
