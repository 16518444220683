import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { ButtonLink } from '@c2fo/liquidity';
import brokenLinkIcon from '@/enterprise/assets/broken-link.svg?url';
import { ErrorPageTemplate } from '@/enterprise/components/ErrorPageTemplate';
import { useReporting } from '@/reporting';
import { asEnterpriseLink } from '@/utils/experienceLinkHelper';

type DraftOfferErrors = 'EXPIRED' | 'INVALID';

export const DraftOfferError = ({
  errorType,
  trackEventMessage,
}: {
  errorType?: DraftOfferErrors;
  trackEventMessage?: string;
}) => {
  const { t } = useTranslation();
  const { trackEnterpriseEvent } = useReporting();
  const params = useParams<{ draftOfferUuid?: string }>();
  const { draftOfferUuid } = params;

  useEffectOnce(() => {
    trackEnterpriseEvent('draft-offer::error', { draftOfferUuid, error: trackEventMessage ?? 'Unknown Error' });
  });

  const errorTypeContentMap: { [key in DraftOfferErrors]: { title?: string; body: string } } = {
    INVALID: {
      title: t('draftOffer.errors.invalid.title'),
      body: t('draftOffer.errors.invalid.body'),
    },
    EXPIRED: {
      title: t('draftOffer.errors.expired.title'),
      body: t('draftOffer.errors.expired.body'),
    },
  };

  const content = errorTypeContentMap[errorType ?? 'INVALID'];

  return (
    <ErrorPageTemplate
      title={content.title}
      cardSlot={
        <div className="flex flex-col">
          <div className="flex flex-col items-center gap-4 sm:flex-row">
            <img src={brokenLinkIcon} alt="draft-offer-error" className="h-20 w-20" />
            <p>{content.body}</p>
          </div>
          <div className="mt-6 flex justify-center sm:mt-4 sm:justify-end">
            <ButtonLink className="w-fit" to={asEnterpriseLink('markets')} variant="primary">
              {t('draftOffer.errors.action')}
            </ButtonLink>
          </div>
        </div>
      }
    />
  );
};
