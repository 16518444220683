import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionTrigger,
  AccordionContent,
  Alert,
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalTitleInverse,
  useSnackbar,
} from '@c2fo/liquidity';
import { OfferConfigDetails } from '@/enterprise/components/OfferConfigDetails';
import { OfferReviewDetails } from '@/enterprise/components/OfferReviewDetails';
import { useConfirmAgreementsWhenRequired } from '@/enterprise/data/useAgreements';
import useSubmitBenchmarkOffer from '@/enterprise/data/useSubmitBenchmarkOffer';
import useSubmitPriceDiscoveryOffer from '@/enterprise/data/useSubmitPriceDiscoveryOffer';
import useSubmitStaticOffer from '@/enterprise/data/useSubmitStaticOffer';
import { TakerMarket } from '@/enterprise/data/useTakerMarkets';
import { useReporting } from '@/reporting';
import useDataDog from '@/reporting/DataDog';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useLocaleFormat from '@/utils/useLocaleFormat';
import { Agreement } from '../agreements/Agreement';
import { getRateFromTakerMarket } from '../nameYourRate/utils';
import useVariableRateDetails from '../variableRate/useVariableRateDetails';
import { BenchmarkRateInput } from './inputs/BenchmarkRateInput';
import { PriceDiscoveryRateInput } from './inputs/PriceDiscoveryRateInput';
import { SchedulingInput } from './inputs/SchedulingInput';
import { StaticRateInput } from './inputs/StaticRateInput';
import { DaysOfTheWeek, OfferModalForm } from './types';
import { buildCustomScheduleSentence, getDefaultRateType, getNormalizedRate } from './utils';

interface OfferModalProps {
  takerMarket: TakerMarket;
  open: boolean;
  onClose: () => void;
  initialStep?: 'entry' | 'review';
}

type AccordionValue = 'Rate' | 'Schedule' | '';

// TODO: No available A/R

const OfferModal = ({ takerMarket, open, onClose, initialStep }: OfferModalProps) => {
  const { t } = useTranslation();
  const { asCurrency } = useLocaleFormat();
  const [formStep, setFormStep] = useState<'entry' | 'review'>(initialStep ?? 'entry');
  const { mutateAsync: submitPriceDiscoveryOffer } = useSubmitPriceDiscoveryOffer();
  const { mutateAsync: submitBenchmarkOffer } = useSubmitBenchmarkOffer();
  const { mutateAsync: submitStaticOffer } = useSubmitStaticOffer();
  const showSnackbar = useSnackbar();
  const { validateAndConfirmAgreementsConditionally } = useConfirmAgreementsWhenRequired([takerMarket]);
  const { getVariableRateDetails } = useVariableRateDetails();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { trackEnterpriseEvent } = useReporting();
  const { addError } = useDataDog();
  const [accordionValue, setAccordionValue] = useState<AccordionValue>('Rate');
  const getDefaultFormValues = useCallback(() => {
    const rateFromTakerMarket = getRateFromTakerMarket(takerMarket);
    return {
      rateStr: rateFromTakerMarket > 0 ? `${rateFromTakerMarket}` : '',
      rateTypeStr: getDefaultRateType(takerMarket),
      participationType: 'ongoing', // TODO: Once we have schedule data, set this appropriately
      isAgreementsChecked: false,
      // TODO: Once we have schedule data, set these if exist
      cadence: { type: 'daily', value: DaysOfTheWeek },
      startDate: undefined,
      endDate: undefined,
    } satisfies OfferModalForm;
  }, [takerMarket]);

  const form = useForm<OfferModalForm>({
    defaultValues: getDefaultFormValues(),
  });

  const { reset, handleSubmit, formState, watch, setValue, setError, clearErrors, trigger } = form;

  const resetForm = useCallback(() => {
    reset(getDefaultFormValues());
    setFormStep(initialStep ?? 'entry');
    setIsSubmitting(false);
  }, [getDefaultFormValues, initialStep, reset]);

  const [formRateStr, formRateTypeStr] = watch(['rateStr', 'rateTypeStr']);

  const rateDisplay = useMemo(() => {
    if (takerMarket.marketPricingType === 'BENCHMARK') {
      const { rateLabel } = getVariableRateDetails(takerMarket);
      return rateLabel;
    }

    if (isNaN(parseFloat(formRateStr))) {
      return t('taker.setARate');
    }

    if (formRateTypeStr !== 'apr' && formRateTypeStr !== 'disc') {
      return t('taker.setARate');
    }

    return getNormalizedRate(formRateStr) + '% ' + formRateTypeStr.toUpperCase();
  }, [formRateStr, formRateTypeStr, getVariableRateDetails, takerMarket, t]);

  const onNext: SubmitHandler<OfferModalForm> = async (data) => {
    if (formStep === 'entry') {
      return setFormStep('review');
    }

    setIsSubmitting(true);

    const onOfferSubmitError = (error: Error) => {
      showSnackbar({ message: t('offerSubmitDialog.offerSetError') });
      setIsSubmitting(false);
      addError(error, { location: 'OfferModal component -> onOfferSubmitError handler', takerMarket, formData: data });
    };

    const onOfferSubmitSuccess = () => {
      showSnackbar({ message: t('offerSubmitDialog.offerSet') });
      onClose();
      resetForm();
    };

    try {
      // This will check if agreements are required or not.
      // If required, it will ensure the user has checked the checkbox and make mutation calls.
      // If not required, it will exit early and allow this method to proceed.
      await validateAndConfirmAgreementsConditionally({ isAgreementsChecked: data.isAgreementsChecked });
    } catch {
      setIsSubmitting(false);
      setError('isAgreementsChecked', { message: 'Agreements must be accepted' });
      return;
    }

    if (takerMarket.marketPricingType === 'PRICE_DISCOVERY') {
      const normalizedRate = getNormalizedRate(formRateStr);
      const maxDiscount = formRateTypeStr === 'disc' ? normalizedRate : takerMarket.offerConfig.maxDiscount;
      const maxApr = formRateTypeStr === 'apr' ? normalizedRate : takerMarket.offerConfig.maxApr;

      const submitValues = {
        marketId: takerMarket.offerConfig.marketId,
        marketUuid: takerMarket.offerConfig.marketUuid,
        offerConfig: {
          uuid: takerMarket.offerConfig.uuid,
          isDiscountBidding: formRateTypeStr === 'disc',
          maxDiscount,
          maxApr,
          exclusionSettings: takerMarket.offerConfig.exclusionSettings,
          // Maintain existing expiration date if already set
          expireOn: takerMarket.disableAfterMarketClearsDate,
        },
        supplierDivisionUuid: takerMarket.takerDivisionUuid,
        takerId: takerMarket.offerConfig.divisionId,
      };

      return await submitPriceDiscoveryOffer(submitValues, {
        onSuccess: () => {
          trackEnterpriseEvent('offer::submitted', submitValues);
          onOfferSubmitSuccess();
        },
        onError: (error) => onOfferSubmitError(error),
      });
    }

    if (takerMarket.marketPricingType === 'BENCHMARK') {
      const submitValues = {
        marketUuid: takerMarket.offerConfig.marketUuid,
        supplierDivisionUuid: takerMarket.takerDivisionUuid,
        // Maintain existing expiration date if already set
        expireOn: takerMarket.disableAfterMarketClearsDate,
      };

      return await submitBenchmarkOffer(submitValues, {
        onSuccess: () => {
          trackEnterpriseEvent('offer::variable-rate::submitted', submitValues);
          onOfferSubmitSuccess();
        },
        onError: (error) => onOfferSubmitError(error),
      });
    }

    if (takerMarket.marketPricingType === 'STATIC') {
      const normalizedRate = getNormalizedRate(formRateStr);

      const submitValues = {
        rate: normalizedRate,
        marketUuid: takerMarket.marketUuid,
        supplierDivisionUuid: takerMarket.takerDivisionUuid,
        // Maintain existing expiration date if already set
        expireOn: takerMarket.disableAfterMarketClearsDate,
      };

      return await submitStaticOffer(submitValues, {
        onSuccess: () => {
          trackEnterpriseEvent('offer::fixed-rate::submitted', submitValues);
          onOfferSubmitSuccess();
        },
        onError: (error) => onOfferSubmitError(error),
      });
    }

    // If we have somehow made it to this point, something has gone wrong.
    onOfferSubmitError(new Error('Unknown market pricing type'));
  };

  const onBack = () => {
    clearErrors();
    setFormStep('entry');
  };

  const accordionTriggerClick = async (e: React.MouseEvent<HTMLButtonElement>, value: AccordionValue) => {
    e.preventDefault();
    e.stopPropagation();

    const isValid = await trigger();

    if (isValid) {
      setAccordionValue(value === accordionValue ? '' : value);
    } else {
      // When result of trigger is invalid, calling 'handleSubmit' (even thought we know it will fail) ensures that form errors are handled as expected.
      // 1. Form errors continue to re-eval on input change
      // 2. Focus state moves to the first invalid field
      // See issue thread for more details: https://github.com/react-hook-form/react-hook-form/issues/3425
      handleSubmit(onNext)();
    }
  };

  // Reset the form when the modal open state changes
  useEffect(() => {
    resetForm();
  }, [getDefaultFormValues, open, reset, resetForm]);

  useEffect(() => {
    clearErrors('rateStr');
  }, [clearErrors, formRateTypeStr]);

  const formHasErrors = Object.keys(formState.errors).length > 0;

  const entryStep = (
    <Accordion
      type="single"
      collapsible
      className="overflow-hidden rounded-md"
      defaultValue="Rate"
      value={accordionValue}
    >
      {/* Available AR */}
      <AccordionItem divided value="Available AR">
        <AccordionHeader className="flex-auto justify-between bg-canvas pr-14">
          <div>{t('taker.dictionary.availableAR.label')}</div>
          <div className="font-semibold">{asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency)}</div>
        </AccordionHeader>
      </AccordionItem>
      {/* Offer Rate */}
      <AccordionItem divided value="Rate">
        <AccordionHeader className="bg-canvas">
          <div className="flex flex-auto justify-between">
            {takerMarket.marketPricingType === 'PRICE_DISCOVERY' && <div>{t('core.rate')}</div>}
            {takerMarket.marketPricingType === 'BENCHMARK' && <div>{t('core.variableRate')}</div>}
            {takerMarket.marketPricingType === 'STATIC' && <div>{t('core.fixedRate')}</div>}
            <div className="font-semibold">{rateDisplay}</div>
          </div>
          <AccordionTrigger
            name="Rate Accordion Toggle"
            onClick={(e) => accordionTriggerClick(e, 'Rate')}
            disabled={formHasErrors}
          />
        </AccordionHeader>
        <AccordionContent className="bg-blueGray-50">
          {takerMarket.marketPricingType === 'PRICE_DISCOVERY' && <PriceDiscoveryRateInput takerMarket={takerMarket} />}
          {takerMarket.marketPricingType === 'BENCHMARK' && <BenchmarkRateInput takerMarket={takerMarket} />}
          {takerMarket.marketPricingType === 'STATIC' && <StaticRateInput takerMarket={takerMarket} />}
        </AccordionContent>
      </AccordionItem>
      {/* Offer Schedule */}
      <AccordionItem divided value="Schedule">
        <AccordionHeader className="bg-canvas">
          <div className="flex flex-auto justify-between">
            <div>Schedule</div>
            <div className="max-w-[75%] text-right font-semibold">
              {buildCustomScheduleSentence({
                startDate: watch('startDate'),
                endDate: watch('endDate'),
                cadence: watch('cadence'),
                t,
              })}
            </div>
          </div>
          <AccordionTrigger
            name="Schedule Accordion Toggle"
            onClick={(e) => accordionTriggerClick(e, 'Schedule')}
            disabled={formHasErrors}
          />
        </AccordionHeader>
        <AccordionContent className="bg-blueGray-50">
          <SchedulingInput takerMarket={takerMarket} />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );

  const reviewStep = (
    <div className="space-y-6">
      <OfferConfigDetails
        items={[
          {
            label: t('taker.dictionary.availableAR.label'),
            value: asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency),
          },
          {
            label: t('taker.dictionary.offer.label'),
            value: rateDisplay,
          },
          {
            // TODO: Translate
            label: 'Schedule',
            value: buildCustomScheduleSentence({
              startDate: watch('startDate'),
              endDate: watch('endDate'),
              cadence: watch('cadence'),
              t,
            }),
          },
        ]}
      />

      <div className="px-2">
        <OfferReviewDetails takerMarket={takerMarket} />
      </div>

      <div className="px-2">
        <Agreement
          takersMarkets={[takerMarket]}
          onChange={(v) => {
            setValue('isAgreementsChecked', v);
            clearErrors('isAgreementsChecked');
          }}
        />
        {!!formState.errors.isAgreementsChecked && (
          <Alert type="error" variant="filled" description={t('agreements.error.checkbox')} full />
        )}
      </div>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      // TODO: If we want controlled heights, TBD with design.
      // sm:[@media(min-height:300px)]:h-[95%] sm:[@media(min-height:700px)]:h-[85%] sm:[@media(min-height:900px)]:h-[75%]
      className="sm:w-[750px]"
    >
      <ModalTitleInverse>
        <span className="flex flex-col">
          <span>Set Offer</span>
          <span className="text-sm font-normal text-text-secondary">{getTakerMarketName(takerMarket)}</span>
        </span>
      </ModalTitleInverse>
      <ModalContent className="space-y-4">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onNext)}>
            {formStep === 'entry' && entryStep}
            {formStep === 'review' && reviewStep}
          </form>
        </FormProvider>
      </ModalContent>
      <ModalActions>
        {formStep === 'entry' && (
          <>
            <Button variant="secondary" onClick={() => onClose()}>
              {t('core.cancel')}
            </Button>
            <Button variant="primary" onClick={handleSubmit(onNext)} className="sm:min-w-[100px]">
              {t('core.next')}
            </Button>
          </>
        )}

        {formStep === 'review' && (
          <>
            <Button variant="secondary" onClick={onBack} disabled={isSubmitting}>
              {t('core.back')}
            </Button>
            <Button
              variant="cta"
              onClick={handleSubmit(onNext)}
              className="sm:min-w-[100px]"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {t('core.submit')}
            </Button>
          </>
        )}
      </ModalActions>
    </Modal>
  );
};

export default OfferModal;
